import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)
// function loadRoutes () {
// //   const routes = require.context('./modules', true, /[A-Za-z0-9-_,\s]+\.js$/i)
// //   let list = []
// //   routes.keys().forEach(key => {
// //     list = list.concat(routes(key).default)
// //   })
// //   return list
// }

const router = new VueRouter({
  routes: routes
})

export default router
