import { mapActions } from 'vuex'

const { body } = document

const WIDTH = process.env.NODE_ENV === 'development' ? 220 : 992 // 992 refer to Bootstrap's responsive design
// const WIDTH = 320 // 992 refer to Bootstrap's responsive design

export default {
  beforeMount () {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted () {
    this.$_resizeHandler()
  },
  methods: {
    ...mapActions({
      toggleDevice: 'app/toggleDevice'
    }),
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile () {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler () {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        this.toggleDevice(isMobile ? 'mobile' : 'desktop')
        // const vh = window.innerHeight * 0.01
        // document.documentElement.style.setProperty('--vh', `${vh}px`)
        // const int = setInterval(() => {
        //   const vh = window.innerHeight * 0.01
        //   console.log(vh)
        //   document.documentElement.style.setProperty('--vh', `${vh}px`)
        // }, 100)
        //
        // setTimeout(() => {
        //   clearInterval(int)
        // }, 2000)
      }
    }
  }
}
