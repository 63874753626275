import middleware from '@/app/services/MiddlewareService/middleware'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'company/settings',
    name: 'CompanySettings',
    redirect: { name: 'CompanySettingsDetails' },
    component: () => import(/* webpackChunkName: "company-settings" */ '@/layouts/CompanySettingsLayout.vue'),
    beforeEnter: middleware('needSelectedCorporateAccount'),
    props: true,
    children: [
      {
        path: 'details',
        name: 'CompanySettingsDetails',
        component: () => import(/* webpackChunkName: "company-settings" */ '@/views/CompanySettingsDetails.vue'),
        props: true,
        beforeEnter: middleware('needSelectedCorporateAccount'),
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'budgets',
        name: 'CompanySettingsBudgets',
        component: () => import(/* webpackChunkName: "company-settings" */ '@/views/CompanySettingsBudgets.vue'),
        props: true,
        beforeEnter: middleware('needSelectedCorporateAccount'),
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'mobile-app',
        name: 'CompanySettingsAppMenu',
        component: () => import(/* webpackChunkName: "company-settings" */ '@/views/CompanySettingsAppMenu.vue'),
        props: true,
        beforeEnter: middleware('mspOnly', 'needSelectedCorporateAccount'),
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'available-products',
        name: 'CompanySettingsProducts',
        component: () => import(/* webpackChunkName: "company-settings" */ '@/views/CompanySettingsProducts.vue'),
        props: true,
        beforeEnter: middleware('mspOnly', 'needSelectedCorporateAccount'),
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'branding',
        name: 'CompanyViewBranding',
        component: () => import(/* webpackChunkName: "company-settings" */ '@/views/CompanyViewBranding.vue'),
        props: true,
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        meta: {
          showSaveChangesButton: true
        }
      }
    ]
  },
  {
    path: 'company/settings/deactivation',
    name: 'CompanySettingsDeactivation',
    component: () => import(/* webpackChunkName: "company-settings" */ '@/views/CompanySettingsDeactivation.vue'),
    beforeEnter: middleware('needSelectedCorporateAccount'),
    props: true
  }
]
