import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getMessageChannelForUser: (options) => api(options).post(`${PORTAL_SERVICE_V1}/communication/messageChannel/getForUser`),
  updateMessageChannel: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/communication/messageChannel/update`, form),
  getDestinationUsers: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/communication/messageChannel/destinationUsers`, form),
  addChannel: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/communication/messageChannel/add`, form),
  deleteChannel: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/communication/messageChannel/delete`, form),
  sendMessage: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/communication/message/send`, form),
  getMessagingRecipients: (options) => api(options).get(`${PORTAL_SERVICE_V1}/user/getMessagingRecipients`)
}
