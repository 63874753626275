import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getByFilter: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/events/getByFilter`, filter),
  get: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/events/get`, filter),
  getPurchaseEvent: (ospId, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/events/purchaseEvent/get`, {
      ospId
    }),
  createCostEventForExternalPurchase: (data, options) =>
    api(options).post(
      `${PORTAL_SERVICE_V1}/payableEvent/costEventForExternalPurchase/create`,
      data
    ),
  refund: (data, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/events/refund`, data),
  correct: (data, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/events/correct`, data),
  getEmailReceipt: (data, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/events/emailReceipt/get`, data)
}
