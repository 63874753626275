import middleware from '@/app/services/MiddlewareService/middleware'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'manage/app-users',
    name: 'ManageAppUsers',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-app-users" */ '@/views/ManageAppUsers.vue')
  },
  {
    path: 'manage/app-users/add',
    name: 'AddAppUser',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "add-app-user" */ '@/views/AddAppUser.vue')
  },
  {
    path: 'manage/import-users',
    name: 'ImportUsers',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "import-users" */ '@/views/ImportUsers.vue')
  },
  {
    path: 'manage/app-users/:userOspId/expenses/:eventOspId',
    name: 'AppUserExpensesViewDetails',
    beforeEnter: middleware('needSelectedCorporateAccount', `hasRole:${Role.OSPADMIN},${Role.USER_MANAGER}`),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserExpensesViewDetails.vue'),
    props: true
  },
  {
    path: 'manage/app-users/:userOspId',
    redirect: { name: 'AppUserBudgetView' },
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/layouts/AppUserViewLayout.vue'),
    props: true,
    children: [
      {
        path: 'budgets',
        name: 'AppUserBudgetView',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserBudgetView.vue'),
        props: true
      },
      {
        path: 'expenses',
        name: 'AppUserExpensesViewAll',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserExpensesViewAll.vue'),
        props: true
      },
      {
        path: 'trip-configuration',
        name: 'AppUserTripView',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserTripView.vue'),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'trip-registrations',
        name: 'AppUserTripRegistrations',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserTripRegistrations.vue'),
        props: true
      },
      {
        path: 'groups-roles',
        name: 'AppUserGroupRolesView',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserGroupRolesView.vue'),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'detail',
        name: 'AppUserDetailView',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserDetailView.vue'),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'identification-fields',
        name: 'AppUserIdentificationFieldsView',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserIdentificationFieldsView.vue'),
        props: true
      },
      {
        path: 'roles',
        name: 'AppUserViewRoles',
        component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/AdminUserViewRoles.vue'),
        props: true
      },
      {
        path: 'parking-history',
        name: 'AppUserParkingHistory',
        beforeEnter: middleware('needSelectedCorporateAccount', `hasRole:${Role.OSPADMIN}`),
        component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserParkingHistory.vue'),
        props: true
      }
    ]
  },
  {
    path: 'manage/app-users/:userOspId/budgets/add',
    name: 'AppUserBudgetAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserBudgetAdd.vue'),
    props: true
  },
  {
    path: 'manage/app-users/:userOspId/budgets/amount/add',
    name: 'AppUserBudgetAmountAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserBudgetAmountAdd.vue'),
    props: true
  },
  {
    path: 'manage/app-users/:userOspId/budgets/:budgetOspId',
    name: 'AppUserBudgetEdit',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserBudgetEdit.vue'),
    props: true
  },
  {
    path: 'manage/app-users/:userOspId/expenses/add',
    name: 'AppUserExpensesAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserExpensesAdd.vue'),
    props: true
  },
  {
    path: 'manage/app-users/:userOspId/expenses/:recurrenceOspId',
    name: 'AppUserExpensesEdit',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "app-user-view" */ '@/views/AppUserExpensesEdit.vue'),
    props: true
  }
]
