import { cloneDeep } from 'lodash'

class Model {
  #original = {}

  constructor (object = {}) {
    this.#original = cloneDeep(object)
    this.initialize(object)
  }

  initialize (object) {
    Object.assign(this, { ...object })
  }

  getOriginal () {
    return this.#original
  }

  clone () {
    return new this.constructor(this.#original)
  }
}

export default Model
