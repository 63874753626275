import middleware from '@/app/services/MiddlewareService/middleware'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'backoffice/orders',
    name: 'BackofficeOrders',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-orders" */ '@/layouts/BackofficeOrdersLayout.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    redirect: { name: 'BackofficeOrdersGeneral' },
    children: [
      {
        path: 'general',
        name: 'BackofficeOrdersGeneral',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-orders" */ '@/views/Backoffice/Orders/GeneralOrders.vue'
          )
      },
      {
        path: 'plugin-service',
        name: 'BackofficePluginService',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-orders" */ '@/views/Backoffice/Orders/PluginService.vue'
          )
      },
      {
        path: 'handled',
        name: 'BackofficeHandledOrders',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-orders" */ '@/views/Backoffice/Orders/HandledOrders.vue'
          )
      },
      {
        path: 'handledPlugins',
        name: 'BackofficeHandledPlugins',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-orders" */ '@/views/Backoffice/Orders/HandledPlugins.vue'
          )
      }
    ]
  },
  {
    path: 'backoffice/uploads',
    name: 'BackofficeUploads',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-uploads" */ '@/layouts/BackofficeUploadsLayout.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    redirect: { name: 'BackofficeUploadsVouchers' },
    children: [
      {
        path: 'vouchers',
        name: 'BackofficeUploadsVouchers',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/Vouchers.vue'
          )
      },
      {
        path: 'surcharges',
        name: 'BackofficeUploadsSurcharges',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/Surcharges.vue'
          )
      },
      {
        path: 'cambio',
        name: 'BackofficeUploadsCambio',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/Cambio.vue'
          )
      },
      {
        path: 'line',
        name: 'BackofficeUploadsLine',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/Line.vue'
          )
      },
      {
        path: 'KBC',
        name: 'BackofficeUploadsKBC',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/KBC.vue'
          )
      },
      {
        path: 'NMBS',
        name: 'BackofficeUploadsNMBS',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/NMBS.vue'
          )
      },
      {
        path: 'swapfiets',
        name: 'BackofficeUploadsSwapfiets',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/Swapfiets.vue'
          )
      },
      {
        path: 'BlueBikeEvents',
        name: 'BackofficeUploadsBlueBikeEvents',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/BlueBikeEvents.vue'
          )
      },
      {
        path: 'BeMobile',
        name: 'BackofficeUploadsBeMobile',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/BeMobile.vue'
          )
      },
      {
        path: 'Charging',
        name: 'BackofficeUploadsCharging',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-uploads" */ '@/views/Backoffice/Uploads/Charging.vue'
          )
      }
    ]
  },
  {
    path: 'backoffice/translations',
    name: 'Translation',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-translations" */ '@/views/TranslationsViewAll.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/translations/:type',
    name: 'AddOrEditTranslaiton',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-translations" */ '@/views/AddOrEditTranslation.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/user-fee',
    name: 'UserFeeViewAll',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-translations" */ '@/views/Backoffice/UserFee/UserFeeViewAll.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/user-fee/:type',
    name: 'AddOrEditUserFee',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-translations" */ '@/views/Backoffice/UserFee/AddOrEditUserFee.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/reconciliation',
    name: 'BackofficeReconciliation',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-reconciliation" */ '@/views/BackofficeReconciliation.vue'
      )
  },
  {
    path: 'backoffice/templates',
    name: 'BackofficeTemplatesViewAll',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-templates" */ '@/views/Backoffice/Templates/TemplatesViewAll.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/templates/add',
    name: 'BackofficeTemplatesAdd',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-templates" */ '@/views/Backoffice/Templates/TemplatesAddEdit.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/templates/edit',
    name: 'BackofficeTemplatesEdit',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-templates" */ '@/views/Backoffice/Templates/TemplatesAddEdit.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'backoffice/resources-management',
    name: 'BackofficeResourceManagementLayout',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    redirect: { name: 'BackofficeViewServices' },
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/layouts/BackofficeResourceManagementLayout.vue'
      ),
    children: [
      {
        path: 'backoffice/resource-management/services',
        name: 'BackofficeViewServices',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/ServiceResources.vue'
          )
      },
      {
        path: 'backoffice/resource-management/transport',
        name: 'BackofficeViewTransport',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/TransportStopResources.vue'
          )
      },
      {
        path: 'backoffice/resource-management/parking',
        name: 'BackofficeViewParking',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/ParkingLotResources.vue'
          )
      },
      {
        path: 'backoffice/resource-management/bike',
        name: 'BackofficeViewBike',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/BikeShareResources.vue'
          )
      },
      {
        path: 'backoffice/resource-management/car',
        name: 'BackofficeViewCar',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CarShareResources.vue'
          )
      },
      {
        path: 'backoffice/resource-management/rental',
        name: 'BackofficeViewRental',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/RentalCarResources.vue'
          )
      },
      {
        path: 'backoffice/resource-management/resource-groups',
        name: 'BackofficeViewGroups',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/ResourceGroups.vue'
          )
      },
      {
        path: 'backoffice/resource-management/labels',
        name: 'BackofficeViewLabels',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/LabelResource.vue'
          )
      },
      {
        path: 'backoffice/resource-management/gas-station',
        name: 'BackofficeViewGasStation',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        component: () =>
          import(
            /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/GasStationResources.vue'
          )
      }
    ]
  },
  {
    path: 'backoffice/resources-management/services/:createOrEdit',
    name: 'BackofficeAddOrEditService',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/Service.vue'
      )
  },
  {
    path: 'backoffice/resources-management/transport/:createOrEdit',
    name: 'BackofficeAddOrEditTransport',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/TransportStop.vue'
      )
  },
  {
    path: 'backoffice/resources-management/parking/:createOrEdit',
    name: 'BackofficeAddOrEditParking',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/ParkingLot.vue'
      )
  },
  {
    path: 'backoffice/resources-management/bike/:createOrEdit',
    name: 'BackofficeAddOrEditBike',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/BikeShare.vue'
      )
  },
  {
    path: 'backoffice/resources-management/car/:createOrEdit',
    name: 'BackofficeAddOrEditCar',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/CarShare.vue'
      )
  },
  {
    path: 'backoffice/resources-management/rental/:createOrEdit',
    name: 'BackofficeAddOrEditRental',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/RentalCar.vue'
      )
  },
  {
    path: 'backoffice/resources-management/label/:createOrEdit',
    name: 'BackofficeAddOrEditLabel',
    props: true,
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/Labels.vue'
      )
  },
  {
    path: 'backoffice/resource-management/resource-groups/:createOrEdit',
    name: 'BackofficeAddOrEditGroups',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/Groups.vue'
      )
  },
  {
    path: 'backoffice/resource-management/gas-station/:createOrEdit',
    name: 'BackofficeAddOrEditGasStation',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-resources-management" */ '@/views/Backoffice/ResourcesManagement/CreateOrEdit/GasStation.vue'
      )
  },
  {
    path: 'backoffice/products',
    name: 'ProductViewAll',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductViewAll.vue'
      )
  },
  {
    path: 'backoffice/products/add',
    name: 'ProductAdd',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    component: () =>
      import(
        /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductAdd.vue'
      )
  },
  {
    path: 'backoffice/products/:productOspId',
    name: 'ProductView',
    component: () =>
      import(
        /* webpackChunkName: "backoffice-products" */ '@/layouts/ProductViewLayout.vue'
      ),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    redirect: { name: 'ProductViewDetails' },
    children: [
      {
        path: 'details',
        name: 'ProductViewDetails',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        props: true,
        meta: {
          showSaveButton: true
        },
        component: () =>
          import(
            /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductViewDetails.vue'
          )
      },
      {
        path: 'composition',
        name: 'ProductViewComposition',
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductViewComposition.vue'
          )
      }
    ]
  },
  {
    path: 'backoffice/products/:productOspId/composition/add-resourcegroup',
    name: 'ProductViewAddResourceGroup',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductViewAddResourceGroup.js'
      )
  },
  {
    path: 'backoffice/products/:productOspId/composition/add-service-resource',
    name: 'ProductViewAddServiceResource',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductViewAddServiceResource.vue'
      )
  },
  {
    path:
      'backoffice/products/:productOspId/composition/add-rental-car-resource',
    name: 'ProductViewAddRentalCarResource',
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "backoffice-products" */ '@/views/Backoffice/Products/ProductViewAddRentalCarResource.js'
      )
  }
]
