import middleware from '@/app/services/MiddlewareService/middleware'

export default [
  {
    path: 'corporate-accounts',
    name: 'CorporateAccountsViewAll',
    component: () => import(/* webpackChunkName: "company-accounts" */ '@/views/CorporateAccounts/ViewAll.vue'),
    beforeEnter: middleware('mspOnly')
  },
  {
    path: 'corporate-accounts/add',
    name: 'CorporateAccountsAdd',
    component: () => import(/* webpackChunkName: "company-accounts" */ '@/views/CorporateAccounts/Add.vue'),
    beforeEnter: middleware('mspOnly')
  }
]
