import Paginator from '../classes/Paginator'

class Repository {
  #model

  constructor (model) {
    this.setModel(model)
  }

  wrapResponse (wrapper = null) {
    return response => {
      const result = wrapper ? response[wrapper] : response
      if (Array.isArray(result)) {
        return this.wrapCollection(result)
      } else {
        return this.wrapItem(result)
      }
    }
  }

  wrapResponseWithPaginator (wrapper) {
    return arg => {
      const response = arg
      response[wrapper] = this.wrapCollection(response[wrapper])
      return new Paginator(response)
    }
  }

  wrapItem (item) {
    return new this.#model(item)
  }

  wrapCollection (collection) {
    return collection.map(item => this.wrapItem(item))
  }

  addWrapper (options = {}, wrapper = null) {
    options.wrapResponse = this.wrapResponse(wrapper)
    return options
  }

  addWrapperWithPaginator (options, wrapper = 'content') {
    options.wrapResponse = this.wrapResponseWithPaginator(wrapper)
    return options
  }

  setModel (model) {
    this.#model = model
  }
}

export default Repository
