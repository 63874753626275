import api from '@/api'

export default {
  namespaced: true,
  state: {
    tokenTypes: [],
    fieldTypes: []
  },
  getters: {
    tokenTypes: state => state.tokenTypes,
    fieldTypes: state => state.fieldTypes
  },
  actions: {
    fetchTokenTypes: ({ commit }) => {
      return api.userIdentificationToken.getTokenTypes({
        onSuccess: response => {
          commit('SET_TOKEN_TYPES', response)
        }
      })
    },
    fetchFieldTypes: ({ commit }) => {
      return api.userIdentificationToken.getFieldTypes({
        onSuccess: response => {
          commit('SET_FIELD_TYPES', response)
        }
      })
    }
  },
  mutations: {
    SET_TOKEN_TYPES: (state, data) => {
      state.tokenTypes = data
    },
    SET_FIELD_TYPES: (state, data) => {
      state.fieldTypes = data
    }
  }
}
