<template>
  <el-container class="mobile-unavailable-layout">
    <div class="card">
      <img class="logo" src="../assets/logo.svg" alt=""/>
      <div>
        <h3 class="title">{{$t('mobile_unavailable_page.title')}}</h3>
        <p>{{$t('mobile_unavailable_page.explanation')}}</p>
      </div>
      <img class="" src="../assets/mobile-unavailable-image.svg" alt=""/>
      <div>
        <p>{{$t('mobile_unavailable_page.download_links_explanation')}}</p>
        <div class="download-links">
          <a :href="$t('mobile_unavailable_page.ios_download_links')">
            <img src="../assets/download-app-store.svg" alt=""/>
          </a>
          <a :href="$t('mobile_unavailable_page.android_download_links')">
            <img src="../assets/download-google-play.svg" alt=""/>
          </a>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
export default {
  name: 'MobileUnavailableLayout'
}
</script>

<style scoped>

</style>
