import Repository from './Repository'
import api from '@/api'
import Budget from '../models/Budget'

class BudgetRepository extends Repository {
  constructor (model = Budget) {
    super(model)
  }

  getByOwner (filter, options) {
    const optionsData = this.addWrapper(options)
    return api.budget.getByOwner(filter, optionsData)
  }

  create (form, options) {
    return api.budget.createBudget({
      type: form.type,
      data: form
    }, options)
  }

  update (form, options) {
    return api.budget.updateBudget(form, options)
  }

  getPrivateBudget (id, options) {
    return api.budget.getPrivateBudget({ parentOspId: id }, options)
  }

  getTransactions (form, options) {
    return api.budget.getTransactionsByFilter(form, options)
  }
}

export default BudgetRepository
