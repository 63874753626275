import Auth from '../services/AuthService/Auth'
import store from '@/store'

export default async function () {
  if (Auth.token()) {
    try {
      await store.dispatch('user/refreshUser')
      await store.dispatch('auth/checkLanguage')
      try {
        await Promise.all([
          store.dispatch('app/getHelp'),
          store.dispatch('branding/getBranding'),
          store.dispatch('branding/getBrandLogo'),
          store.dispatch('userGroup/findAll', Auth.corporateAccountId())
        ])
      } catch (e) {
        console.log(e)
      }
      await store.dispatch('corporateAccount/getData')
    } catch (e) {
      console.log(e)
    }
  }
  return true
}
