import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getHelp: cb => api({ onSuccess: cb }).post(`${PORTAL_SERVICE_V1}/help/get`),
  imageIcon: (serviceResourceOspId, options) => api({
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: 'image/png'
    },
    ...options
  }).get(`${PORTAL_SERVICE_V1}/image/icon/`, { serviceResourceOspId })
}
