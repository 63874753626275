<template>
  <el-container class="main-layout app-wrapper">
    <component :is="sidebarComponent"/>
    <el-container class="main-layout--container">
      <el-main>
        <app-main/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppMain from './components/AppMain'

export default {
  name: 'MainLayout',
  components: {
    AppMain
  },
  computed: {
    sidebarComponent () {
      if (this.$auth.user().isMsp) {
        return () => import('./components/SidebarMsp')
      } else {
        return () => import('./components/Sidebar')
      }
    }
  },
  mounted () {
    // console.log('MainLayout mounted')
  }
}
</script>
