import store from '@/store'
import Auth from '../AuthService/Auth'

class Authorization {
  constructor () {
    this.token = Auth.token()
  }

  getToken () {
    return this.token
  }

  isRefreshing () {
    return store.getters['auth/isRefreshing']
  }

  awaitForRefresh () {
    let start = 0
    const delay = 100
    const end = 20 * 1000
    return new Promise((resolve, reject) => {
      const timer = setInterval(() => {
        start += delay
        if (!this.isRefreshing()) {
          clearInterval(timer)
          resolve(true)
        } else if (start > end) {
          clearInterval(timer)
          reject(new Error('The time of the waiting for refresh token is expired!'))
        }
      }, delay)
    })
  }

  getHeaders () {
    if (Auth.token()) {
      return {
        Authorization: `Bearer ${Auth.token()}`
      }
    }
    return {}
  }

  reset () {
    return store.dispatch('auth/signOut')
  }

  refreshToken () {
    // console.log('Authorization.refreshToken')
    return store.dispatch('auth/refreshToken')
  }
}

export default Authorization
