<template>
  <el-alert
    v-if="showBanner"
    id="old-platform-banner"
    :style="{
      backgroundColor: getColor(backgroundColor, true)
    }"
    :class="{ fixed }"
  >
    <span
      :style="{ color: getColor(textColor, false) }"
      v-html="$t('platform.migration.banner')"
    ></span>
  </el-alert>
</template>

<script>
const oldPlatformBaseUrl = 'prd.ospmobility.com'
const colorRegExp = /^[0-9a-fA-F]{6}$|^[0-9a-fA-F]{8}$/

export default {
  name: 'OldPlatformBanner',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      backgroundColor: process.env.VUE_APP_PORTAL_BANNER_COLOR,
      textColor: process.env.VUE_APP_PORTAL_BANNER_FG_COLOR
    }
  },
  computed: {
    showBanner () {
      const [, baseUrl] = process.env.VUE_APP_BASE_API.split('//')
      return baseUrl === oldPlatformBaseUrl
    }
  },
  methods: {
    getColor (value, isBg) {
      const defaultValues = ['#FFFFFF', '#FF0000']
      if (value && colorRegExp.test(value)) {
        return `#${value}`
      }
      return defaultValues[+isBg]
    }
  }
}
</script>

<style lang="scss" scoped>
#old-platform-banner {
  border-radius: 0;
}
.fixed {
  position: fixed;
  top: 0;
  right: 0;
}
</style>
