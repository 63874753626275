import { api, PORTAL_SERVICE_V1, PORTAL_SERVICE_V2 } from '../../app/services/ApiService'

export default {
  getBudgetTypes: (assigneeOspId, options) => api(options).post(`${PORTAL_SERVICE_V1}/budget/types`, { assigneeOspId }),

  getByOwner: (filter, options) =>
    api({
      ...options,
      ignoreErrors: ['NOT_FOUND']
    }).post(`${PORTAL_SERVICE_V1}/budget/getByOwner`, filter),

  createBudget: ({ type, data }, options) => api(options).post(`${PORTAL_SERVICE_V1}/budget/${type}/create`, data),

  updateBudget: ({ ospId, data }, options) => {
    return api(options).post(`${PORTAL_SERVICE_V2}/budget/update`, { ospId, ...data })
  },

  deactivate: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/budget/deactivate`, { ospId }),

  activate: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/budget/activate`, { ospId }),

  /**
   * Redeems a promocode to top up a company budget.
   *
   * @param promoCode
   * @param budgetOspId
   * @param options
   * @returns {*}
   */
  redeem: ({ promoCode, budgetOspId = undefined }, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/budget/redeem`, {
      promoCode,
      budgetOspId
    }),

  getTransactionsByFilter: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/budget/transaction/getByFilter`, filter),

  addTransaction: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/budget/transaction/addForUser`, data),

  addTransactionForCorporateAccount: (data, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/budget/transaction/addForCorporateAccount`, data),

  getTransactions: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/budget/transaction/getByFilter`, filter),

  getPrivateBudget: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/budget/private/balance`, form),

  downloadAllXlsx: (filter, options) =>
    api({
      ...options,
      responseType: 'arraybuffer',
      dataOnly: false,
      headers: {
        Accept: '*/*'
      }
    }).post(`${PORTAL_SERVICE_V1}/budget/transaction/download/xlsx`, filter)
}
