<template>
  <div class="page-layout" :class="{'with-fixed-footer': fixedFooter}">
    <div class="page-layout__header">
      <slot name="header">
        <div v-if="hasBackButton" class="page-layout__header-back">
          <i class="ri-arrow-left-line" @click="goBack"></i>
        </div>
        <div class="page-layout__header-left">
          <slot name="title"><h2 class="text-title" v-if="title">{{title}}</h2></slot>
          <slot name="sub-title"><p class="text-subtitle" v-if="subTitle">{{subTitle}}</p></slot>
        </div>
        <div class="page-layout__header-right">
          <slot name="header-actions"></slot>
        </div>
      </slot>
    </div>
    <div class="page-layout__body">
      <slot/>
    </div>
    <div v-if="$slots.footer" class="page-layout__footer" :class="{'fixed-footer': fixedFooter}">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    fixedFooter: {
      type: Boolean,
      default: false
    },
    hasBackButton: {
      type: Boolean,
      default: false
    },
    emitBack: {
      type: Boolean,
      default: false
    },
    backRoute: {
      type: [Object, String],
      default: null
    }
  },
  methods: {
    goBack () {
      if (this.emitBack) {
        this.$emit('back')
      } else if (this.backRoute && this.$router.resolve(this.backRoute)) {
        this.$router.push(this.backRoute)
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>

</style>
