import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLanguage, setLanguage, isHavingLanguage } from './app/helpers/auth'

import en from 'element-ui/lib/locale/lang/en'
import nl from 'element-ui/lib/locale/lang/nl'
import fr from 'element-ui/lib/locale/lang/fr'

Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

function loadLocaleMessages () {
  const bundle = require('@/i18n/bundle.json')
  return {
    en: {
      ...en,
      ...bundle.en
    },
    nl: {
      ...nl,
      ...bundle.nl
    },
    fr: {
      ...fr,
      ...bundle.fr
    }
  }
}

function getBrowserLanguage () {
  return navigator && navigator.language.substring(0, 2)
}

function getDefaultLanguage () {
  const browserLanguage = getBrowserLanguage()
  if (!browserLanguage) {
    return getLanguage()
  }
  const locales = Object.keys(loadLocaleMessages())
  if (locales.find(lang => lang === browserLanguage) && !isHavingLanguage()) {
    setLanguage(browserLanguage)
  }
  return getLanguage()
}

export default new VueI18n({
  locale: getDefaultLanguage(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
