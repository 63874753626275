import { get } from 'lodash'
import Model from './Model'
import PartnerRepository from '@/app/repositories/PartnerRepository'

class Partner extends Model {
  #repository = null

  constructor (object = {}) {
    super(object)
    this.#repository = new PartnerRepository()
  }

  update (options = {}, applyChanges = true) {
    const form = {
      applyChangesToCorporateAccounts: applyChanges,
      ospId: {
        ospId: this.partner.ospId
      },
      partnerData: this.partner.data,
      selectedBudgetTypes: this.selectedBudgetTypes,
      selectedProducts: this.selectedProducts,
      selectedServiceResources: this.selectedServiceResources
    }
    return this.#repository.update(form, options)
  }

  get isArtemis () {
    return get(this, ['partner', 'data', 'partnerType'], null) === 'ARTEMIS'
  }

  get selectedProductsList () {
    return this.selectedProducts.map(v => v.ospId)
  }

  get selectedServiceResourcesList () {
    return this.selectedServiceResources.map(v => v.ospId)
  }

  set selectedProductsList (val) {
    this.selectedProducts = val.map(v => {
      return {
        ospId: v
      }
    })
  }

  set selectedServiceResourcesList (val) {
    this.selectedServiceResources = val.map(v => {
      return {
        ospId: v
      }
    })
  }
}

export default Partner
