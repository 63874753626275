import { keys, omit } from 'lodash'
import TravelCompensationRepository from '@/app/repositories/TravelCompensationRepository'

const COMPANY_CAR = 'company'
const PRIVATE_CAR = 'private'

class TravelCompensation {
  #original = {}
  #repository = {}
  allowDistancesConfiguredByUser = false
  businessTripCompensationPerKmForCompanyCar = null
  businessTripCompensationPerKmForPrivateCar = null
  businessTripEnabledForBike = false
  businessTripEnabledForCompanyCar = false
  businessTripEnabledForPrivateCar = false
  carCommuteCompensationType = 'PER_KILOMETER'
  commuteBikeCompensationPerKm = null
  commuteCarCompensationPerKm = null
  commuteEnabledForBike = false
  commuteEnabledForCar = false
  commuteEnabledForWalking = false
  homeWorkEnabled = false

  constructor (object = {}) {
    this.#original = object
    Object.assign(this, { ...object })
    this.#repository = new TravelCompensationRepository()
  }

  get typeOfCar () {
    if (this.businessTripEnabledForPrivateCar) {
      return PRIVATE_CAR
    }
    if (this.businessTripEnabledForCompanyCar) {
      return COMPANY_CAR
    }
    return null
  }

  set typeOfCar (value) {
    this.businessTripEnabledForCompanyCar = value === COMPANY_CAR ? !this.businessTripEnabledForCompanyCar : false

    this.businessTripEnabledForPrivateCar = value === PRIVATE_CAR ? !this.businessTripEnabledForPrivateCar : false
  }

  get businessTripCompensation () {
    if (this.businessTripEnabledForPrivateCar) {
      return this.businessTripCompensationPerKmForPrivateCar
    }
    if (this.businessTripEnabledForCompanyCar) {
      return this.businessTripCompensationPerKmForCompanyCar
    }
    return null
  }

  set businessTripCompensation (v) {
    if (this.businessTripEnabledForPrivateCar) {
      this.businessTripCompensationPerKmForPrivateCar = v
    } else if (this.businessTripEnabledForCompanyCar) {
      this.businessTripCompensationPerKmForCompanyCar = v
    }
  }

  get businessTripCompensationEnabled () {
    return this.businessTripEnabledForCompanyCar || this.businessTripEnabledForPrivateCar
  }

  get dirty () {
    return (
      keys(this.#original).find(key => {
        return this.#original[key] !== this[key]
      }) !== undefined
    )
  }

  static getDefault () {
    return {
      allowDistancesConfiguredByUser: true,
      businessTripCompensationPerKmForCompanyCar: null,
      businessTripCompensationPerKmForPrivateCar: null,
      businessTripEnabledForBike: false,
      businessTripEnabledForCompanyCar: false,
      businessTripEnabledForPrivateCar: false,
      carCommuteCompensationType: 'PER_KILOMETER',
      commuteBikeCompensationPerKm: null,
      commuteCarCompensationPerKm: null,
      commuteEnabledForBike: false,
      commuteEnabledForCar: false,
      commuteEnabledForWalking: false,
      homeWorkEnabled: false
    }
  }

  getOriginal () {
    return this.#original
  }

  getSaveForm () {
    const form = {}
    keys(this.#original).forEach(key => {
      form[key] = this[key]
    })
    const formWithoutCompensations = omit(form, [
      'businessTripCompensationPerKmForCompanyCar',
      'businessTripCompensationPerKmForPrivateCar',
      'commuteBikeCompensationPerKm',
      'commuteCarCompensationPerKm'
    ])

    return formWithoutCompensations
  }

  save (options = {}) {
    const form = this.getSaveForm()
    const optionsData = {
      ...options,
      onSuccess: response => {
        this.#original = form
        return options.onSuccess && options.onSuccess(response)
      }
    }
    return this.#repository.update(form, optionsData)
  }
}

export default TravelCompensation
