import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input/dist/vue-currency-input.esm'

Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: { prefix: '€ ' }, // only override the default currency 'EUR' with 'USD'
    locale: 'en-US',
    allowNegative: false
  }
})
