import { render, staticRenderFns } from "./OldPlatformBanner.vue?vue&type=template&id=321b5562&scoped=true"
import script from "./OldPlatformBanner.vue?vue&type=script&lang=js"
export * from "./OldPlatformBanner.vue?vue&type=script&lang=js"
import style0 from "./OldPlatformBanner.vue?vue&type=style&index=0&id=321b5562&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321b5562",
  null
  
)

export default component.exports