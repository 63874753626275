import Repository from './Repository'
import api from '@/api'
import BudgetType from '../models/BudgetType'

class BudgetTypeRepository extends Repository {
  constructor () {
    super(BudgetType)
  }

  getAll (filter, options) {
    const optionsData = this.addWrapper(options)
    return api.budget.getBudgetTypes(filter, optionsData)
  }
}

export default BudgetTypeRepository
