import Vue from 'vue'
import { uniqueId } from 'lodash'
import { currency, DATE_FORMAT, sleep, trans } from '../app/helpers'
import { DateTime } from 'luxon'

Vue.prototype.$trans = trans

Vue.prototype.$date = DateTime

Vue.prototype.$sleep = sleep

Vue.prototype.$dateFormat = (date, format = DATE_FORMAT) => DateTime.fromISO(date).toFormat(format)

Vue.prototype.$key = (prefix = '') => uniqueId(prefix)

Vue.prototype.$currency = currency

Vue.prototype.$fixTranslations = (translations) => translations.map(t => {
  if (t.languageCode === 'en') {
    return {
      ...t,
      languageCode: 'GB'
    }
  } else if (t.languageCode === 'nl') {
    return {
      ...t,
      languageCode: 'BE'
    }
  }
  return t
})
