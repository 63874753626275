import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {

  // FORM for /findByKeyLike
  // Get a list of translations where the label key matches the given parameter using a "containsIgnoreCase" selector
  // {
  //   "key": "string",
  //   "pagination": {
  //     "page": 0,
  //     "size": 0,
  //     "sort": {
  //       "sortOrders": [
  //         {
  //           "direction": "ASCENDING",
  //           "property": "string"
  //         }
  //       ]
  //     }
  //   }
  // }
  getByKey: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/translation/findByKeyLike`, form),

  // FORM for /findByTranslationLike
  // Get a list of translations that have a translation that matches the given parameter using a "containsIgnoreCase" selector
  // {
  //   "pagination": {
  //     "page": 0,
  //     "size": 0,
  //     "sort": {
  //       "sortOrders": [
  //         {
  //           "direction": "ASCENDING",
  //           "property": "string"
  //         }
  //       ]
  //     }
  //   },
  //   "translation": "string"
  // }
  getByTranslation: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/translation/findByTranslationLike`, form),

  // form for /get
  // Get translation entry by key.
  // {
  //   "key": "string"
  // }
  get: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/translation/get`, form),

  // FORM for /create
  // Create new message label
  // {
  //   "key": "string",
  //   "label": {
  //     "defaultValue": "string",
  //     "defaultValueHtml": "string",
  //     "labelOspId": "string",
  //     "labelTranslations": [
  //       {
  //         "html": "string",
  //         "languageCode": "aa",
  //         "text": "string"
  //       }
  //     ]
  //   }
  // }
  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/translation/create`, form),

  // FORM for /delete
  // {
  //   "key": "string"
  // }
  delete: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/translation/delete`, form),

  // FORM for /update
  // Modify existing error translation label.
  // {
  //   "key": "string",
  //   "label": {
  //     "defaultValue": "string",
  //     "defaultValueHtml": "string",
  //     "labelOspId": "string",
  //     "labelTranslations": [
  //       {
  //         "html": "string",
  //         "languageCode": "aa",
  //         "text": "string"
  //       }
  //     ]
  //   }
  // }
  update: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/translation/update`, form)

}
