
<template>
  <el-form-item
    :label="label && (label + (required ? '*' : ''))"
    :prop="prop"
    :disabled="disabled"
    :class="{
    'has-error': error || validationsError,
    'has-message': validationsError || error || success,
    'disabled': disabled
    }"
  >
    <el-input
      ref="input"
      :key="type"
      v-model="model"
      :type="type"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      auto-complete="off"
      :style="{'max-width': maxWidth ? `${maxWidth}px` : null}"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
      :autosize="autosize"
      :mask="mask"
      :readonly="readonly"
      :rows="rows"
      :resize="resize"
      @keyup.enter.native.prevent="$emit('keyupEnter')"
      :class="{'el-input__with-counter': showWordLimit}"
    >
      <template #prepend v-if="$slots.prependInput">
        <slot name="prependInput"></slot>
      </template>
      <template #append v-if="$slots.afterInput">
        <slot name="afterInput"></slot>
      </template>
    </el-input>
    <p
      v-if="validationsError"
      class="error"
    >{{$t(`validations.${validationsError}.${label ? 'with_label' : 'without_label'}`, {...this.validationsErrorParams, label})}}</p>
    <p v-else-if="error" class="error">{{error}}</p>
    <p v-else-if="success" class="success">{{success}}</p>
    <p v-if="info" class="info">{{info}}</p>
  </el-form-item>
</template>

<script>
import { keys } from 'lodash'

export default {
  name: 'BaseInput',
  props: {
    value: {
      default: null
    },
    autoComplete: {
      type: String,
      default: 'on'
    },
    error: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: null
    },
    prop: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [String, null],
      default: null
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: null
    },
    resize: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    validationsError () {
      return (
        this.validations &&
        this.validations.$dirty &&
        this.validations.$params &&
        keys(this.validations.$params).find((v) => !this.validations[v])
      )
    },
    validationsErrorParams () {
      // return ''
      if (this.validationsError) {
        return this.validations.$params[this.validationsError]
      }
      return {}
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  }
}
</script>
