<template>
  <div class="pagination">
    <el-button circle icon="ri-arrow-left-s-line" @click="previous"/>
    <span>
    {{currentPage}} / {{totalPages}}
    </span>
    <el-button circle icon="ri-arrow-right-s-line" @click="next"/>
  </div>
</template>

<script>

export default {
  name: 'BasePagination',
  props: {
    value: {
      type: Number,
      default: 1
    },
    pages: {
      type: Number,
      default: 1
    },
    pagination: {
      type: Object,
      default: null
    }
  },
  data () {
    return {}
  },
  watch: {
    totalPages (totalPages) {
      if (this.currentPage > totalPages) {
        if (this.pagination && this.pagination.goToPage !== undefined) {
          return this.pagination.goToPage(totalPages)
        } else {
          return this.$emit('input', totalPages - 1)
        }
      }
    }
  },
  computed: {
    totalPages () {
      if (this.pagination && this.pagination.totalPages) {
        return this.pagination.totalPages
      } else {
        return this.pages
      }
    },
    currentPage () {
      if (this.pagination && this.pagination.currentPage) {
        return this.pagination.currentPage
      } else {
        return this.value + 1
      }
    },
    nextPage () {
      if (this.pagination && this.pagination.nextPagePagination !== undefined) {
        return this.pagination.nextPagePagination
      } else if ((this.value + 1) < this.pages) {
        return this.value + 1
      }
      return false
    },
    previousPage () {
      if (this.pagination && this.pagination.previousPage !== undefined) {
        return this.pagination.previousPage
      } else if (this.value > 0) {
        return (this.value - 1)
      }
      return false
    }
  },
  methods: {
    next () {
      if (!this.nextPage) {
        return false
      }
      if (!this.pagination || !this.pagination.next()) {
        this.$emit('input', this.nextPage)
      }
    },
    previous () {
      // console.log('previous')
      if (this.previousPage === false) {
        return false
      }
      if (!this.pagination || !this.pagination.previous()) {
        this.$emit('input', this.previousPage)
      }
    }
  }
}
</script>
