import api from '@/api'
import axios from 'axios'

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install (Vue, options) {
    Vue.prototype.$api = api
    Vue.prototype.$cancelToken = () => {
      return axios.CancelToken.source()
    }
  }
}
