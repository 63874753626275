import middleware from '../../app/services/MiddlewareService/middleware'
import Login from '../../views/Login'

export default [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
    beforeEnter: middleware('guest')
  },
  {
    path: '/2-factor-auth',
    name: 'TwoFactorLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/TwoFactorLogin.vue'),
    beforeEnter: middleware('guest')
  },
  {
    path: '/reset-login',
    name: 'ResetLogin',
    component: () => import(/* webpackChunkName: "reset-login" */ '@/views/ResetLogin.vue'),
    beforeEnter: middleware('guest')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'),
    beforeEnter: middleware('guest')
  }
]
