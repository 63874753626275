<template>
  <div
    class="base-icon"
    :class="{
      'base-icon--colorize': !keepColors ,
      'base-icon--small': size === 'small',
      'base-icon--large': size === 'large'
    }"
    v-bind:style="style"
    v-html="html"
  ></div>
</template>

<script>

export default {
  name: 'BaseIcon',
  props: {
    iconBase64: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    keepColors: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    html () {
      if (this.icon) {
        try {
          return require(`@/assets/icons/${this.icon}.svg`)
        } catch (e) {
          return ''
        }
      } else {
        return ''
      }
    },
    style () {
      if (this.iconBase64) {
        return {
          'background-size': 'contain',
          'background-image': `url(${this.iconBase64})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center'
        }
      } else {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.base-icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.base--small {
  height: 1.6rem;
  width: 1.6rem;
}

.base--large {
  height: 3.2rem;
  width: 3.2rem;
}

.base--colorize svg * {
  fill: currentColor;
}
</style>
