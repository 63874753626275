import { api, PORTAL_SERVICE_V1, PORTAL_SERVICE_V2 } from '../../app/services/ApiService'
import Auth from '../../app/services/AuthService/Auth'

export default {
  getBranding: (options) => api(options).post(`${PORTAL_SERVICE_V1}/branding/portal/branding/get`, { ospId: Auth.corporateAccountId() }),

  getBrandLogo: (options) => api({
    ignoreErrors: ['*'],
    headers: { Accept: 'image/png' },
    responseType: 'arraybuffer',
    ...options
  }).get(`${PORTAL_SERVICE_V1}/branding/portal/logo/get`),
  getBrandImage: ({ imageType, targetOspId }, options) => api({
    ignoreErrors: ['*'],
    headers: { Accept: 'image/*' },
    responseType: 'blob',
    ...options
  }).post(`${PORTAL_SERVICE_V1}/branding/image/get`, { imageType, targetOspId }),

  getPartnerBranding: (ospId, options) => api(options).get(`${PORTAL_SERVICE_V2}/branding/configuration/${ospId}`),
  getDefaultBrandingColors: (ospId, options) => api(options).get(`${PORTAL_SERVICE_V2}/branding/configuration/default/${ospId}`),
  addOrUpdate: (form, options) => api(options).put(`${PORTAL_SERVICE_V2}/branding/configuration`, form),
  deleteColors: (ospId, options) => api(options).delete(`${PORTAL_SERVICE_V2}/branding/configuration/${ospId}`),

  getPartnerImageTypes: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/branding/image/typesPartner`, { ospId }),
  getCorporateAccountImageTypes: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/branding/image/typesCorporateAccount`, { ospId }),

  uploadImage: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/branding/image/upload`, form),
  deleteImage: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/branding/image/delete`, form)
}
