import { isObject, last } from 'lodash'
import store from '@/store'
import i18n from '../../i18n'
import Auth from '@/app/services/AuthService/Auth'

export const NOTIFICATION_CONFIG = {
  showClose: true,
  duration: 10 * 1000
}
export const DATE_TIME_ISO_MESSAGE = 'yyyy-MM-dd\'T\'HH:mm:ss'
export const DATE_TIME_ISO = 'yyyy-LL-dd\'T\'HH:mm:ssZZZ'
export const DATE_FORMAT = 'dd/MM/yyyy'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm'

export const trans = (object) => {
  if (!isObject(object) || !object) {
    return object
  }
  let translated = ''
  if ('labelTranslations' in object) {
    const labelTranslation = object.labelTranslations.find(t => t.languageCode === store.getters['i18n/langDefault'])
    translated = labelTranslation ? labelTranslation.html || labelTranslation.text : ''
  }
  return translated || object.defaultValueHtml || object.defaultValue || ''
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const confirmDefaultOptions = {
  confirmButtonText: i18n.t('general.confirm'),
  cancelButtonText: i18n.t('general.cancel'),
  dangerouslyUseHTMLString: true,
  roundButton: true,
  confirmButtonClass: 'base-button',
  cancelButtonClass: 'grayed-link'
}

export const checkCompanyPaymentType = (...types) => {
  if (types && types[0] && Array.isArray(types[0])) {
    types = types[0]
  }
  types = types.map(v => v.toLowerCase())
  const corporatePaymentType = store.getters['corporateAccount/selectedCorporatePaymentType'] || store.getters['corporateAccount/corporatePaymentType']
  return corporatePaymentType && types.includes(corporatePaymentType.toLowerCase())
}

export const allowViewCompanyBudget = () => {
  const userPartner = store.getters['partner/userPartner']
  return Auth.isOspAdmin() || checkCompanyPaymentType('PREPAID') || (userPartner && userPartner.isArtemis)
}

export const currency = (amount, sign = '€') => {
  const response = sign + ' ' + Number(amount).toLocaleString('ru-RU', { minimumFractionDigits: 2 })
  return response.trim()
}

export const defaultCompanyUserGroup = (companyExtId) => {
  return `ALL_${companyExtId}_USERS`
}

export const uploadFile = (httpResponse, fileName, extension = null) => {
  extension = !extension
    ? last(httpResponse.headers['content-type'].split('/')).split(';')[0]
    : extension
  const textBlob = new Blob(
    [
      httpResponse.data
    ],
    { type: httpResponse.headers['content-type'] }
  )
  const fileURL = window.URL.createObjectURL(textBlob)
  const fileLink = document.createElement('a')

  fileLink.setAttribute('href', fileURL)
  fileLink.setAttribute('target', '_blank')
  fileLink.setAttribute('download', `${fileName}.${extension}`)
  fileLink.style.display = 'none'
  document.body.appendChild(fileLink)
  fileLink.click()
  document.body.removeChild(fileLink)
}

export const processUploadResponse = response => {
  const defaultFileName = 'download'
  if (response.headers['content-disposition']) {
    const fullFileName = response.headers['content-disposition'].split('=')[1]
    const fileName = fullFileName.slice(0, fullFileName.lastIndexOf('.'))
    const extension = fullFileName.slice(fullFileName.lastIndexOf('.') + 1)
    uploadFile(response, fileName, extension)
  } else {
    uploadFile(response, defaultFileName)
  }
}

export const PROVIDERS = {
  NMBS: 'NmbsSncb',
  DELIJN: 'DeLijn',
  MIVB: 'MivbStib'
}

export const DATE_PICKER_TYPES = {
  DEFAULT: 'date',
  MONTH: 'month',
  YEAR: 'year'
}

export const dateDisplayInDatePicker = (datePickerType) => {
  const options = { placeholder: undefined, format: undefined, info: undefined }

  if (datePickerType === DATE_PICKER_TYPES.MONTH) {
    options.placeholder = i18n.t('general.label.pick_a_month')
    options.format = 'MM/yyyy'
    options.info = i18n.t('user_management.view_app_users_page.add_expense.start_month')
  } else if (datePickerType === DATE_PICKER_TYPES.YEAR) {
    options.placeholder = i18n.t('general.label.pick_a_year')
    options.format = 'yyyy'
    options.info = i18n.t('user_management.view_app_users_page.add_expense.start_year')
  }

  return options
}
