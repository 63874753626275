import api from '@/api'

export default {
  namespaced: true,
  state: {
    translations: {}
  },
  getters: {
    getTotalPages: (state) => state.translations.totalPages,
    getTranslations: (state) => state.translations.content
  },
  actions: {
    get ({ commit }, { form, options }) {
      return api.translations.get(form, {
        ...options,
        onSuccess: (res) => {
          commit('SET_TRANSLATIONS', res)
        }
      })
    },
    getByKey ({ commit }, { form, options }) {
      return api.translations.getByKey(form, {
        ...options,
        onSuccess: (res) => {
          commit('SET_TRANSLATIONS', res)
        }
      })
    },
    getByTranslation ({ commit }, { form, options }) {
      return api.translations.getByTranslation(form, {
        ...options,
        onSuccess: (res) => {
          commit('SET_TRANSLATIONS', res)
        }
      })
    },
    createNewTranslation ({ commit }, { form, options }) {
      return api.translations.create(form, options)
    },
    delete ({ commit }, { form, options }) {
      return api.translations.delete(form, options)
    },
    update ({ commit }, { form, options }) {
      return api.translations.update(form, options)
    },
    clearTranslationState ({ commit }) {
      commit('CLEAR_STATE')
    }
  },
  mutations: {
    SET_TRANSLATIONS: (state, data) => {
      state.translations = data
    },
    CLEAR_STATE: (state) => {
      state.translations = {}
    }
  }
}
