<template>
<!--  <el-scrollbar-->
<!--    wrap-class="el-select-dropdown__wrap"-->
<!--    view-class="el-select-dropdown__list"-->
<!--    ref="scrollbar"-->
<!--    v-show="list.length > 0 && !loading">-->

  <div class="el-transfer base-transfer"
       :class="{
       'base-transfer--flex': isFlex,
       'base-transfer--full-width': fullWidth,
       'base-transfer--with-pagination': withPagination
       }"
       :style="{
         'height': height ? `${height}px` : '',
         'max-height': height ? `${height}px` : ''
       }"
  >
    <base-transfer-panel
      v-bind="$props"
      ref="leftPanel"
      :data="sourceData"
      :top-title="topTitles[0]"
      :title="titles[0] || t('el.transfer.titles.0')"
      :default-checked="leftDefaultChecked"
      :placeholder="filterPlaceholder || $t('general.search')"
      :pagination="leftPagination"
      :panel-loading="leftPanelLoading"
      @checked-change="onSourceCheckedChange">
      <slot name="left-footer"></slot>
    </base-transfer-panel>
    <div class="el-transfer__buttons">
      <el-button
        type="primary"
        circle
        :class="['el-transfer__button', hasButtonTexts ? 'is-with-texts' : '']"
        @click.native="transfer('left')"
        :disabled="rightChecked.length === 0">
        <i class="el-icon-arrow-left"></i>
        <span v-if="buttonTexts[0] !== undefined">{{ buttonTexts[0] }}</span>
      </el-button>
      <el-button
        type="primary"
        circle
        :class="['el-transfer__button', hasButtonTexts ? 'is-with-texts' : '']"
        @click.native="transfer('right')"
        :disabled="leftChecked.length === 0">
        <span v-if="buttonTexts[1] !== undefined">{{ buttonTexts[1] }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-button>
    </div>
    <base-transfer-panel
      v-bind="$props"
      ref="rightPanel"
      :data="targetData"
      :top-title="topTitles[1]"
      :title="titles[1] || t('el.transfer.titles.1')"
      :default-checked="rightDefaultChecked"
      :placeholder="filterPlaceholder || $t('general.search')"
      :pagination="rightPagination"
      :panel-loading="rightPanelLoading"
      @checked-change="onTargetCheckedChange">
      <slot name="right-footer"></slot>
    </base-transfer-panel>
  </div>
</template>

<script>
import ElTransfer from 'element-ui/packages/transfer/src/main'

export default {
  name: 'BaseTransfer',
  mixins: [ElTransfer],
  props: {
    topTitles: {
      type: Array,
      default: () => [null, null]
    },
    isFlex: {
      type: Boolean,
      default: true
    },
    withPagination: {
      type: Boolean,
      default: false
    },
    emitTransfer: {
      type: Boolean,
      default: false
    },
    leftPagination: {
      type: Object,
      default: null
    },
    rightPagination: {
      type: Object,
      default: null
    },
    height: {
      type: [Number, String],
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    leftPanelLoading: {
      type: Boolean,
      default: false
    },
    rightPanelLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    transfer (direction) {
      if (this.emitTransfer) {
        return this.$emit('transfer', direction)
      }
      if (direction === 'left') {
        this.addToLeft()
      } else if (direction === 'right') {
        this.addToRight()
      }
    }
  }
}

</script>
