import {
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken
  , verifyLogin
} from '../../app/helpers/auth'
import api from '@/api'
import router from '@/router'
import postLogin from '../../app/helpers/postLogin'
import Api from '../../app/services/ApiService/Api'
import Vue from 'vue'
import { sleep, NOTIFICATION_CONFIG } from '../../app/helpers'
import { forEach } from 'lodash'
import Auth from '../../app/services/AuthService/Auth'

import { Notification } from 'element-ui'
import i18n from '@/i18n'

const getDefaultState = () => {
  return {
    token: getToken(),
    refreshToken: getRefreshToken(),
    refreshingTokenStatus: 0
  }
}
export default {
  namespaced: true,
  state: {
    ...getDefaultState(),
    loginForm: {},
    qrCode2fa: null
  },
  getters: {
    token: state => state.token,
    refreshToken: state => state.refreshToken,
    userRole: state => state.userRole,
    loginForm: state => state.loginForm,
    isRefreshing: state => !!state.refreshingTokenStatus,
    qrCode2fa: state => state.qrCode2fa
  },
  actions: {
    async login ({ commit, dispatch, rootGetters }, { username, passdigest, code2fa = undefined }) {
      const response = await api.auth.login({ username, passdigest, code2fa })
      if (response.requires2fa && !response.token) {
        commit('SET_LOGIN_FORM', { username, passdigest })
        commit('SET_QR', response.qrCode2fa)
        return router.push({ name: 'TwoFactorLogin' })
      } else {
        if (!verifyLogin(response.token)) {
          Notification({
            ...NOTIFICATION_CONFIG,
            type: 'error',
            message: i18n.t('auth.sign_in_page.login_failed')
          })
          return false
        }
        commit('SET_LOGIN_FORM', {})
        // console.log('setTokens', response)
        await dispatch('setTokens', response)
        await postLogin()
        const nextRoute = rootGetters['app/intendedRoute']
        try {
          await router.push(nextRoute || '/')
        } catch (e) {
          console.log(e)
          await router.push('/')
        }
        return true
      }
    },
    async checkLanguage ({ dispatch, rootGetters }) {
      const langDefault = rootGetters['i18n/langDefault']
      const userLang = !!Auth.token() && Auth.user().language
      // console.log('langDefault', langDefault)
      // console.log('userLang', userLang)
      // console.log('userLang && userLang !== langDefault', userLang && userLang !== langDefault)
      if (userLang && userLang !== langDefault) {
        return await dispatch('user/changeLanguage', langDefault, { root: true })
      }
      return true
    },
    async signOut ({ commit, dispatch }) {
      removeToken()
      removeRefreshToken()
      commit('RESET_STATE')
      await sleep(50)
      dispatch('corporateAccount/resetStore', undefined, { root: true })
      return router.push({ name: 'Login' })
    },
    refreshToken ({ dispatch, commit, state }) {
      if (!state.refreshToken) {
        return false
      }
      commit('TOGGLE_REFRESHING_STATUS', 1)
      return api.auth.refreshToken(
        state.refreshToken,
        {
          onSuccess: async response => {
            return response ? dispatch('setTokens', response) : router.push({ name: 'Login' })
          },
          onFinally: () => {
            commit('TOGGLE_REFRESHING_STATUS', 0)
          },
          onError: async errorResponse => {
            await dispatch('signOut')
            await dispatch('app/setIntendedRoute', Vue.$route, { root: true })
            Api.pushMessage(errorResponse.getErrorLabel(), 'error')
          }
        }
      )
    },
    setTokens ({ commit, dispatch }, { token = '', refreshToken = '' }) {
      setToken(token)
      commit('SET_TOKEN', token)
      setRefreshToken(refreshToken)
      commit('SET_REFRESH_TOKEN', refreshToken)
      dispatch('user/refreshUser', null, { root: true })
      return true
    }
  },
  mutations: {
    RESET_STATE: (state) => {
      const defaultState = getDefaultState()
      forEach(defaultState, (value, key) => {
        Vue.set(state, key, value)
      })
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESH_TOKEN: (state, token) => {
      state.refreshToken = token
    },
    SET_LOGIN_FORM: (state, form) => {
      state.loginForm = form
    },
    SET_QR: (state, qrCode2fa) => {
      state.qrCode2fa = qrCode2fa
    },
    TOGGLE_REFRESHING_STATUS: (state, status = 0) => {
      state.refreshingTokenStatus = status
    }
  }
}
