import middleware from '@/app/services/MiddlewareService/middleware'

export default [
  {
    path: 'manage/admins',
    name: 'ManageAdmins',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/ManageAdmins.vue')
  },
  {
    path: 'manage/admins/contact/add',
    name: 'ContactUserAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/ContactUserAdd.vue')
  },
  {
    path: 'manage/admins/hr/add',
    name: 'HRContactUserAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/ContactUserAdd.vue')
  },
  {
    path: 'manage/admins/mobility/add',
    name: 'MobilityContactUserAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/ContactUserAdd.vue')
  },
  {
    path: 'manage/admins/contact/:userOspId',
    name: 'ContactUserView',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/ContactUserView.vue'),
    props: true
  },
  {
    path: 'manage/admins/add',
    name: 'AdminUserAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/AdminUserAdd.vue')
  },
  {
    path: 'manage/admins/:userOspId',
    redirect: { name: 'AdminUserView' },
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-admins" */ '@/layouts/AdminUserViewLayout.vue'),
    props: true,
    children: [
      {
        path: 'details',
        name: 'AdminUserViewDetails',
        component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/AdminUserViewDetails.vue'),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'roles',
        name: 'AdminUserViewRoles',
        component: () => import(/* webpackChunkName: "manage-admins" */ '@/views/AdminUserViewRoles.vue'),
        props: true
      }
    ]
  }
]
