import { api, PORTAL_SERVICE_V1, PORTAL_SERVICE_V2 } from '../../app/services/ApiService'

export default {
  findAll: (options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/find/all`),

  getComposition: (ospId, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/composition/get`, { ospId }),

  getCompositionDetails: (filter, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/composition/details/get`, filter),

  addComposition: (form, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/composition/add`, form),

  updateComposition: (form, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/composition/update`, form),

  deleteComposition: (form, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/composition/delete`, form),

  findAllServiceResources: (options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/resource/serviceResource/find/all`),

  findAllRentalCarResources: (options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/resource/rentalcar/find/all`),

  findAllResourcegroups: (filter, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/resourcegroup/findAll`, filter),

  findAllByAssignee: (assigneeOspId, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/find/allByAssignee`, { assigneeOspId }),

  findProductByUserGroup: (assigneeOspId, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/permission/usage/findByUserGroup`, { assigneeOspId }),

  findProductByUserGroupAdvanced: (ospId, options) => api(options)
    .get(`${PORTAL_SERVICE_V2}/product/permission/usage/findByUserGroup`, { ospId }),

  findByCorporateAccount: (assigneeOspId, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/permission/view/findByCorporateAccount`, { assigneeOspId }),

  add: (form, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/authorization/product/usage/grant`, form),

  revoke: (form, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/authorization/product/usage/revoke`, form),

  findByResource: (resourceOspId, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/product/findByResource`, resourceOspId),
  /*
  findByFilter:
  form: {
    active: Boolean type or null
  }
  */
  findByFilter: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/product/findByFilter`, form),
  /*
  deactivate:
  form: {
  "ospId": "string" (productOspId)
   }
  */
  deactivate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/product/deactivate`, form),
  /*
  active:
  form: {
  "ospId": "string" (productOspId)
   }
  */
  activate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/product/activate`, form),
  /*
  create:
  form: {
    "defaultLabel": "string",
    "extId": "string",
    "translations": [
        {
          "html": "string",
          "languageCode": "aa",
          "text": "string"
        }
      ]
    }
  */
  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/product/create`, form),
  update: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/product/update`, form)
}
