import Model from './Model'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'
import store from '@/store'
import { get } from 'lodash'
import BudgetTypeRepository from '@/app/repositories/BudgetTypeRepository'
import TravelCompensationRepository from '@/app/repositories/TravelCompensationRepository'

class CorporateAccount extends Model {
  #repository = null
  budgetTypes = []
  travelCompensation = {}

  constructor (object = {}) {
    super(object)
    this.#repository = new CorporateAccountRepository()
  }

  setAsSelected () {
    return store.dispatch('corporateAccount/selectCorporateAccountOspId', this.ospId)
  }

  initialize (object) {
    Object.assign(this, { ...object })
    this.fetchBudgetTypes()
    this.fetchTravelCompensation()
  }

  fetchBudgetTypes () {
    const budgetTypeRepository = new BudgetTypeRepository()
    return budgetTypeRepository.getAll(this.ospId, {
      onSuccess: r => {
        this.budgetTypes = r
      }
    })
  }

  fetchTravelCompensation () {
    const travelCompensationRepository = new TravelCompensationRepository()
    return travelCompensationRepository.getByCorporateAccountOspId(this.ospId, {
      onSuccess: r => {
        this.travelCompensation = r
      }
    })
  }

  get isPrepaid () {
    return get(this, ['corporateAccount', 'data', 'paymentType'], '') === 'PREPAID'
  }

  get isPostpaidWithSecurityDeposit () {
    return get(this, ['corporateAccount', 'data', 'paymentType'], '') === 'POSTPAID_WITH_SECURITY_DEPOSIT'
  }

  get showBudgetDetails () {
    return this.isPrepaid || this.isPostpaidWithSecurityDeposit
  }

  get ospId () {
    return get(this, ['corporateAccount', 'ospId'])
  }

  get extId () {
    return get(this, ['corporateAccount', 'data', 'extId'])
  }

  get name () {
    return get(this, ['corporateAccount', 'data', 'name'])
  }

  get partnerOspId () {
    return get(this, ['corporateAccount', 'data', 'partner', 'ospId'])
  }
}

export default CorporateAccount
