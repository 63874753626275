import { trans } from '../helpers'
import { first, get } from 'lodash'

class BudgetType {
  constructor (object) {
    Object.assign(this, object)
  }

  get name () {
    return trans(this.budgetTypeName)
  }

  get info () {
    return trans(this.infoText)
  }

  get defaultAllowedFrequency () {
    return get(first(this.allowedFrequencies), 'value', null)
  }

  get defaultAllowedStrategy () {
    return first(get(this.allowedStrategiesForFrequency, this.defaultAllowedFrequency, []))
  }

  getDefaultAllowedStrategy (frequency) {
    return first(get(this.allowedStrategiesForFrequency, frequency, []))
  }

  get allowedVatStrategiesFlat () {
    return this.allowedVatStrategies.map(v => v.value)
  }
}

export default BudgetType
