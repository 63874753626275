import i18n from '@/i18n'
import { Settings } from 'luxon'
// import en from 'element-ui/lib/locale/lang/en'
// import nl from 'element-ui/lib/locale/lang/nl'
// import fr from 'element-ui/lib/locale/lang/fr'
// import locale from 'element-ui/lib/locale'
import { getLanguage, setLanguage } from '../../app/helpers/auth'

// const elLanguages = {
//   en,
//   nl,
//   fr
// }

const defaultLanguage = 'nl'

export default {
  namespaced: true,
  state: {
    langDefault: getLanguage(defaultLanguage),
    langFallback: defaultLanguage,
    langSupport: ['en', 'nl', 'fr'],
    languages: [
      {
        label: 'Nederlands',
        value: 'nl'
      },
      {
        label: 'English',
        value: 'en'
      },
      {
        label: 'Français',
        value: 'fr'
      }
    ],
    templateLanguages: [
      {
        label: 'Nederlands',
        value: 'nl'
      },
      {
        label: 'English',
        value: 'en'
      },
      {
        label: 'Français',
        value: 'fr'
      },
      {
        label: 'Deutsche',
        value: 'de'
      }
    ]
  },
  getters: {
    langDefault: state => {
      if (state.languages.map(l => l.value).indexOf(state.langDefault) === -1) {
        return state.langFallback
      }
      return state.langDefault
    },
    langSupport: state => state.languages.map(l => l.value),
    languages: state => state.languages,
    templateLanguages: state => state.templateLanguages
  },
  actions: {
    changeLang ({ commit, dispatch }, lang = 'nl') {
      commit('LANG_CHANGE', lang)
      return dispatch('auth/checkLanguage', lang, { root: true })
    }
  },
  mutations: {
    LANG_CHANGE (state, lang) {
      // console.log('LANG_CHANGE', elLanguages[lang])
      state.langDefault = lang
      i18n.locale = lang
      // locale.use(elLanguages[lang])
      Settings.defaultLocale = lang

      setLanguage(lang)
    }
  }
}
