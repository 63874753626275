import store from '@/store'

const en = require('./en.json')
const nl = require('./nl.json')
const fr = require('./fr.json')
const de = require('./de.json')

function findTranslation (translationsList, { id, alpha2 }) {
  try {
    return translationsList.find(v => v.id === id && v.alpha2 === alpha2).name
  } catch (e) {
    return ''
  }
}

export default class CountryTranslations {
  constructor ({ id, alpha2, alpha3 }) {
    this.id = id
    this.alpha2 = alpha2.toUpperCase()
    this.alpha3 = alpha3.toUpperCase()
    this.en = findTranslation(en, { id, alpha2 })
    this.nl = findTranslation(nl, { id, alpha2 })
    this.fr = findTranslation(fr, { id, alpha2 })
    this.de = findTranslation(de, { id, alpha2 })
  }

  get isSecondary () {
    return this.alpha2.toLowerCase() !== 'be' ? 1 : 0
  }

  get label () {
    return this[store.getters['i18n/langDefault'] || 'en']
  }

  get value () {
    return this.alpha2
  }

  hasLetters (search) {
    search = search.toLowerCase()
    return this.en.toLowerCase().indexOf(search) > -1 ||
      this.nl.toLowerCase().indexOf(search) > -1 ||
      this.fr.toLowerCase().indexOf(search) > -1 ||
      this.de.toLowerCase().indexOf(search) > -1 ||
      this.alpha2.toLowerCase().indexOf(search) > -1
  }
}
