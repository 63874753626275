import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {

  activate: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/activate`, form),
  delete: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/delete`, form),
  get: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/get`, form),
  create: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/create`, form),
  getByFilter: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/getByFilter`, form),
  update: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/update`, form),
  deactivate: ({ target, form }, options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/${target}/deactivate`, form),

  findAll: (options) => api(options).post(`${PORTAL_SERVICE_V1}/resource/rentalcar/find/all`)
}
