var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form-item',{class:{
    'has-error': _vm.error || _vm.validationsError,
    'has-message': _vm.validationsError || _vm.error || _vm.success,
    disabled: _vm.disabled
  },attrs:{"label":_vm.label && _vm.label + (_vm.required ? '*' : ''),"prop":_vm.prop,"label-position":"top"}},[_c('el-select',{class:{
      'select-with-prefix': !!_vm.$slots.prefix,
      'select-with-clear-button': _vm.clearable,
      'select-with-hidden-suffix': _vm.items.length <= 1
    },style:({ 'max-width': _vm.maxWidth ? `${_vm.maxWidth}px` : null }),attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"allow-create":_vm.allowCreate,"filterable":_vm.filterable,"filter-method":_vm.filterMethod,"remote":_vm.remote,"remote-method":_vm.remoteMethod,"loading":_vm.loading,"loading-text":_vm.loadingText,"default-first-option":_vm.defaultFirstOption,"popper-append-to-body":_vm.popperAppendToBody,"multiple":_vm.multiple,"collapse-tags":_vm.collapseTags},on:{"change":_vm.change},scopedSlots:_vm._u([(_vm.$slots.prefix && !_vm.clearable)?{key:"prefix",fn:function(){return [_vm._t("prefix")]},proxy:true}:(_vm.clearable && _vm.model)?{key:"prefix",fn:function(){return [_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){$event.stopPropagation();return _vm.clearHandler.apply(null, arguments)}}})]},proxy:true}:null],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._t("option",function(){return _vm._l((_vm.items),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}},[_vm._t("default",null,{"item":item})],2)})},{"items":_vm.items})],2),(_vm.validationsError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t(`validations.${_vm.validationsError}.${_vm.label ? 'with_label' : 'without_label'}`, { label: _vm.label }))+" ")]):(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.success)?_c('p',{staticClass:"success"},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e(),(_vm.info)?_c('p',{staticClass:"info"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.info)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }