import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getCommuteTripCompensation: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/compensation/get`, filter),
  updateCommuteTripCompensation: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/compensation/update`, data),

  getBusinessTripCompensation: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/businessTrip/compensation/get`, filter),
  updateBusinessTripCompensation: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/businessTrip/compensation/update`, data),

  getHomeworkCompensation: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/homework/compensation/get`, filter),
  updateHomeworkCompensation: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/homework/compensation/update`, data),

  getHomeworkConfiguration: (userOspId, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/homework/configuration/permission/get`, {
      userOspId
    }),
  updateHomeworkConfiguration: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/homework/configuration/permission/update`, data),

  getCommuteUserCompensation: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/user/compensation/get`, filter),
  updateCommuteUserCompensation: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/user/compensation/update`, data),

  getCommuteUserStatus: (userOspId, options) => api(options).get(`${PORTAL_SERVICE_V1}/commute/user/${userOspId}/status`),
  updateCommuteUserStatus: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/user/update`, data)
}
