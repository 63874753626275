import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getAll: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/getAll`, filter),
  getAllMrp: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/mrp/getAll`, filter),
  get: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/get`, filter),
  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/create`, form),
  update: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/update`, form),
  deactivate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/deactivate`, form),
  getRelated: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/related/get`, filter),
  addRelated: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/related/add`, form),
  removeRelated: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/partner/related/remove`, form)
}
