import api from '@/api'
import Repository from './Repository'
import TravelCompensation from '@/app/models/TravelCompensation'

class TravelCompensationRepository extends Repository {
  constructor () {
    super(TravelCompensation)
  }

  getByCorporateAccountOspId (corporateAccountOspId, options) {
    const optionsData = this.addWrapper(options)
    return api.travel.getTravelCompensation(corporateAccountOspId, optionsData)
  }

  update (model, options) {
    return api.travel.updateTravelCompensation(model, options)
  }
}

export default TravelCompensationRepository
