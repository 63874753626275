import { mapActions, mapGetters } from 'vuex'
import Auth from '../app/services/AuthService/Auth'

export default {
  name: 'LanguageSelector',
  data () {
    return {
      allLanguages: [
        {
          label: 'English',
          value: 'en'
        },
        {
          label: 'Nederlands',
          value: 'nl'
        },
        {
          label: 'Français',
          value: 'fr'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      language: 'i18n/langDefault'
    }),
    languages () {
      return this.allLanguages.filter(v => v.value !== this.language)
    }
  },
  methods: {
    ...mapActions({
      $_changeUserLanguage: 'user/changeLanguage',
      $_changeAppLanguage: 'i18n/changeLang'
    }),
    changeLanguage (arg) {
      if (Auth.token()) {
        return this.$_changeUserLanguage(arg)
      }
      return this.$_changeAppLanguage(arg)
    }
  }
}
