import { api, PORTAL_SERVICE_V1, PORTAL_SERVICE_V2 } from '../../app/services/ApiService'
import Auth from '../../app/services/AuthService/Auth'

export default {
  changeLanguage: (lang, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/changeLanguage/${lang}`),
  findByAny: (
    filters = {
      active: true,
      corporateAccountOspId: Auth.corporateAccountId(),
      pagination: {
        page: 1,
        size: 10
      }
    },
    options
  ) => api(options).post(`${PORTAL_SERVICE_V1}/user/findByAny`, filters),
  findWithNegativeBudget: (
    filters = {
      active: true,
      corporateAccountOspId: Auth.corporateAccountId()
    },
    options
  ) => api(options).post(`${PORTAL_SERVICE_V1}/user/findWithNegativeBudget`, filters),

  countWithNegativeBudget: (
    filters = {
      active: true,
      corporateAccountOspId: Auth.corporateAccountId()
    },
    options
  ) => api(options).post(`${PORTAL_SERVICE_V1}/user/countWithNegativeBudget`, filters),

  getByFilter: (filters, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/getByFilter`, filters),
  verifyLogin: (login, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/verifyLogin`, login),
  verifyContractId: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/verifyContractId`, data),
  generateLink: (ospId = Auth.corporateAccountId(), options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/user/selfservice/generateLink`, {
      ospId
    }),

  // Creates a mobile app user
  createMobileUser: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/mobile/create`, form),
  createAdminUser: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/manager/create`, form),
  createContactUser: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/contact/create`, form),

  getUser: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/get`, { ospId }),
  updateUser: ({ ospId, userData }, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/user/update`, {
      ospId,
      userData
    }),

  activate: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/activate`, { ospId }),
  deactivate: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/deactivate`, { ospId }),
  unblock: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/unblock`, { ospId }),
  sendPincode: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/sendPincode`, { ospId }),
  changePincode: (form, options) => api(options).post(`${PORTAL_SERVICE_V2}/user/changePincode`, form),
  anonymize: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/anonymize`, { ospId }),

  roleList: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/role/list`, { ospId }),
  roleAdd: ({ role, userOspId = { ospId: '' } }, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/user/role/add`, {
      role,
      userOspId
    }),
  roleRemove: ({ role, userOspId = { ospId: '' } }, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/user/role/remove`, {
      role,
      userOspId
    }),
  roleListAll: options => api(options).post(`${PORTAL_SERVICE_V1}/user/role/list/all`),

  contactTypeList: options => api(options).post(`${PORTAL_SERVICE_V1}/user/contactType/list`),

  uploadUsers: (data, options) =>
    api({
      ...options,
      responseType: 'text'
    }).post(`${PORTAL_SERVICE_V1}/user/upload/upload`, data),
  validateUsers: (uploadId, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/user/upload/validate`, {
      uploadId
    }),
  processUploading: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/upload/process`, data),
  reset2FactorAuth: (userOspId, options) => api(options).post(`${PORTAL_SERVICE_V1}/user/clear2fa`, { userOspId }),

  getBackofficeUsers: (corporateAccountOspId, options) =>
    api(options).get(`${PORTAL_SERVICE_V1}/user/backoffice/get/${corporateAccountOspId}`)
}
