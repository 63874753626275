import api from '@/api'
import Repository from './Repository'
import Partner from '@/app/models/Partner'

class PartnerRepository extends Repository {
  constructor () {
    super(Partner)
  }

  getAll ({ active = true }, options) {
    const optionsData = this.addWrapper(options)
    return api.partner.getAll({ active }, optionsData)
  }

  getAllMrp ({ active = true }, options) {
    const optionsData = this.addWrapper(options)
    return api.partner.getAllMrp({ active }, optionsData)
  }

  get (ospId, options) {
    const optionsData = this.addWrapper(options)
    return api.partner.get({ ospId }, optionsData)
  }

  update (form, options) {
    const optionsData = this.addWrapper(options)
    return api.partner.update(form, optionsData)
  }

  addSubpartner ({ partnerOspId, targetOspId }, options) {
    return api.partner.addRelated({
      partnerOspId,
      subPartnerOspId: targetOspId
    }, options)
  }

  addSuperpartner ({ partnerOspId, targetOspId }, options) {
    return api.partner.addRelated({
      partnerOspId: targetOspId,
      subPartnerOspId: partnerOspId
    }, options)
  }

  deactivatePartner (ospId, options) {
    return api.partner.deactivate(ospId, options)
  }
}

export default PartnerRepository
