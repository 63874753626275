import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getByFilter: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/findByFilter`, filter),

  types: options => api(options).post(`${PORTAL_SERVICE_V1}/reporting/types`),

  download: (reportOspId, fileType, options) =>
    api({
      ...options,
      responseType: 'arraybuffer',
      dataOnly: false,
      headers: {
        Accept: '*/*'
      }
    }).get(`${PORTAL_SERVICE_V1}/reporting/download/${fileType}${reportOspId}`),

  countUsers: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/countUsers`, filter),

  countBookings: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/countBookings`, filter),

  sumEvents: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/sumEvents`, filter),

  sumTransactions: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/sumTransactions`, filter),

  getListOfEmails: (filter, options) => {
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/recipient/get`, filter)
  },

  addEmailToList: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/recipient/add`, filter),

  removeEmailFromList: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/reporting/recipient/remove`, filter)
}
