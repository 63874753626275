import api from '@/api'

export default {
  namespaced: true,
  state: {
    mobilityModes: []
  },
  getters: {
    mobilityModes: state => state.mobilityModes
  },
  actions: {
    getMobilityModes ({ commit }) {
      return api.resource.getMobilityModes({
        onSuccess: res => {
          commit('SET_MOBILITY_MODES', res)
        }
      })
    }
  },
  mutations: {
    SET_MOBILITY_MODES: (state, data) => {
      state.mobilityModes = data
    }
  }
}
