import middleware from '../app/services/MiddlewareService/middleware'
import MainLayout from '../layouts/MainLayout'
import auth from './modules/auth'
import general from './modules/general'
import appUsers from './modules/appUsers'
import userGroups from './modules/userGroups'
import adminUsers from './modules/adminUsers'
import companySettings from '@/router/modules/companySettings'
import companyProducts from '@/router/modules/companyProducts'
import reporting from '@/router/modules/reporting'
import corporateAccounts from '@/router/modules/corporateAccounts'
import partners from '@/router/modules/partners'
import backoffice from '@/router/modules/backoffice'
import communication from '@/router/modules/communication'
import orderHandling from './modules/orderHandling'

export default [
  ...auth,
  {
    path: '/',
    name: 'Layout',
    component: MainLayout,
    redirect: '/dashboard',
    beforeEnter: middleware('auth'),
    children: [
      ...general,
      ...appUsers,
      ...userGroups,
      ...adminUsers,
      ...companySettings,
      ...companyProducts,
      ...reporting,
      ...corporateAccounts,
      ...partners,
      ...backoffice,
      ...communication,
      ...orderHandling
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]
