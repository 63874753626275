<template>
  <el-button
    @click="handleClick"
    :type="type"
    :round="round"
    :loading="loading"
    :disabled="disabled"
    :circle="circle"
    :size="size"
    :class="{
      'has-icon-left': prependIcon,
      'has-icon-right': appendIcon
    }"
  >
    <i v-if="prependIcon" :class="prependIcon" class="prepend-icon"></i>
    <slot>{{label}}</slot>
    <i v-if="appendIcon" :class="appendIcon" class="append-icon"></i>
  </el-button>
</template>

<script>

export default {
  name: 'Base',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    label: {
      type: String,
      default: null
    },
    round: {
      type: Boolean,
      default: true
    },
    loading: Boolean,
    disabled: Boolean,
    circle: Boolean,
    appendIcon: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    to: {
      type: [Object, String],
      default: null
    },
    size: String
  },
  data () {
    return {}
  },
  methods: {
    handleClick (evt) {
      if (this.to) {
        return this.$router.push(this.to)
      }
      this.$emit('click', evt)
    }
  }
}
</script>
