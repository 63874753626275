import api from '@/api'
import i18n from '@/i18n'
import Api from '@/app/services/ApiService/Api'
import Repository from './Repository'
import Report from '@/app/models/Report'
import ReportType from '@/app/models/ReportType'
import { uploadFile, sleep } from '@/app/helpers'

class ReportRepository extends Repository {
  constructor (model = Report) {
    super(model)
  }

  getByFilter (filter, options = {}) {
    const optionsData = this.addWrapperWithPaginator(options)
    return api.reporting.getByFilter(filter, optionsData)
  }

  download ({ ospId, outputFilename = 'report', fileType }, options = {}) {
    fileType = fileType ? `${fileType}/` : fileType
    let notificationSuccessInstance
    let notificationInfoInstance
    let notificationInfoTimer
    let sleepTime = 1000

    return api.reporting.download(ospId, fileType, {
      ...options,
      onRequest: () => {
        notificationSuccessInstance = Api.pushMessage(i18n.t('general.label.download_started'))
        notificationInfoTimer = setTimeout(() => {
          sleepTime = 0
          notificationInfoInstance = Api.pushMessage(
            i18n.t('general.label.long_downloading_notification'),
            'warning',
            60000
          )
        }, 10000)
      },
      onSuccess: response => {
        if (response.headers['content-disposition']) {
          const fullFileName = response.headers['content-disposition'].split('=')[1]
          const fileName = fullFileName.slice(0, fullFileName.lastIndexOf('.'))
          const extension = fullFileName.slice(fullFileName.lastIndexOf('.') + 1)
          uploadFile(response, fileName, extension)
          options.onSuccess && options.onSuccess(response)
        } else {
          uploadFile(response, outputFilename)
          options.onSuccess && options.onSuccess(response)
        }
        sleep(sleepTime).then(notificationSuccessInstance.close)
      },
      onError: () => {
        notificationSuccessInstance && notificationSuccessInstance.close()
      },
      onFinally: () => {
        clearTimeout(notificationInfoTimer)
        notificationInfoInstance && notificationInfoInstance.close()
        options.onDownloadEnd && options.onDownloadEnd()
      }
    })
  }

  static getTypes (options) {
    const instance = new ReportRepository(ReportType)
    return api.reporting.types(instance.addWrapper(options))
  }
}

export default ReportRepository
