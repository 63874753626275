import api from '@/api'
import Repository from './Repository'
import CorporateAccount from '@/app/models/CorporateAccount'
import { pick } from 'lodash'

class CorporateAccountRepository extends Repository {
  constructor () {
    super(CorporateAccount)
  }

  getByFilter (filter, options) {
    return api.corporateAccount.getByFilter(filter, options)
  }

  async getAllByFilter (filter, pickFields = null) {
    const filterData = (page = 0) => ({
      ...filter,
      pagination: {
        ...filter.pagination,
        page,
        size: 1000
      }
    })
    let content = []
    let loadNext = true
    let currentPage = 0
    let safeCounter = 0
    while (loadNext && safeCounter < 20) {
      try {
        const res = await api.corporateAccount.getByFilter(filterData(currentPage))
        if (!res || !res.content) {
          return content
        }
        const handledContent = pickFields ? res.content.map(o => pick(o, pickFields)) : res.content
        content = content.concat(handledContent)
        ++currentPage
        if (res.totalPages <= currentPage) {
          loadNext = false
        }
        safeCounter++
      } catch (e) {
        console.log(e)
        return content
      }
    }
    return content
  }

  getData (ospId, options) {
    const optionsData = this.addWrapper(options)
    return api.corporateAccount.getData(ospId, optionsData)
  }
}

export default CorporateAccountRepository
