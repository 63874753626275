import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getByFilter: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/getByFilter`, form),
  activate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/activate`, form),
  delete: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/delete`, form),
  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/create`, form),
  add: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/add`, form),
  remove: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/remove`, form),
  get: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/resourcegroup/get`, ospId)

}
