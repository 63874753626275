import Model from './Model'
import store from '@/store'
import { trans } from '@/app/helpers'

class ReportType extends Model {
  nameLabel = {}
  descriptionLabel = {}

  constructor (object = {}) {
    super(object)
    this.initialize(object)
  }

  get name () {
    return trans(this.nameLabel)
  }

  get description () {
    return trans(this.descriptionLabel)
  }

  static getTypeByReportType (reportType) {
    return store.getters['reporting/getTypeByReportType'](reportType)
  }
}

export default ReportType
