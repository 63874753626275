import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getMobilityModes: (options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/resource/mobilityModes/get`),

  getByFilter: (form, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/resource/getByFilter`, form),

  getByLabel: (ospId, options) => api(options)
    .post(`${PORTAL_SERVICE_V1}/resource/getByLabel`, ospId)
}
