import Model from './Model'
import { trans } from '@/app/helpers'
import { cloneDeep } from 'lodash'

class Product extends Model {
  active = true
  extId = null
  ospId = null
  productLabel = {
    defaultValue: null,
    defaultValueHtml: null,
    labelOspId: null,
    labelTranslations: []
  }

  constructor (object = {}) {
    super(object)
    this.initialize(object)
  }

  get haystack () {
    const haystack = [
      this.extId,
      this.productLabel.defaultValue,
      this.productLabel.defaultValueHtml
    ]
    this.productLabel.labelTranslations.map(({ html, text }) => {
      haystack.push(html)
      haystack.push(text)
    })
    return haystack.filter(v => v)
  }

  get label () {
    return trans(this.productLabel)
  }

  get textLabel () {
    return this.label.replace(/(<([^>]+)>)/gi, '')
  }

  test (needle) {
    if (!needle) {
      return true
    }
    for (const item of this.haystack) {
      if (item.toLowerCase().includes(needle.toLowerCase())) {
        return true
      }
    }
    return false
  }

  getUpdateForm () {
    return {
      ...this,
      productLabel: {
        ...cloneDeep(this.productLabel),
        labelOspId: undefined
      }
    }
  }
}

export default Product
