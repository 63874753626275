<template>
  <div class="base-color-picker">
    <div
      :class="[
      'el-color-picker',
      colorDisabled ? 'is-disabled' : '',
      colorSize ? `el-color-picker--${ colorSize }` : ''
    ]"
      v-clickoutside="hide">
      <div class="el-color-picker__mask" v-if="colorDisabled"></div>
      <div class="el-color-picker__trigger" @click="handleTrigger">
      <span class="el-color-picker__color" :class="{ 'is-alpha': showAlpha }">
        <span class="el-color-picker__color-inner"
              :style="{
            backgroundColor: displayedColor
          }"></span>
        <span class="el-color-picker__empty el-icon-close" v-if="!value && !showPanelColor"></span>
      </span>
        <span class="el-color-picker__icon el-icon-arrow-down" v-show="value || showPanelColor"></span>
      </div>
      <base-color-picker-dropdown
        ref="dropdown"
        :class="['el-color-picker__panel', popperClass || '']"
        v-model="showPicker"
        @pick="confirmValue"
        @clear="clearValue"
        @reset="resetValue"
        :color="color"
        :show-alpha="showAlpha"
        :predefine="predefine"
        :show-reset="showReset"
        :show-clear="showClear"
      >
      </base-color-picker-dropdown>
    </div>
    <span class="base-color-picker--title">
      <slot>
        {{ label }}
      </slot>
    </span>
  </div>
</template>

<script>

import ElColorPicker from 'element-ui/packages/color-picker/src/main.vue'

export default {
  name: 'BaseColorPicker',
  mixins: [ElColorPicker],
  props: {
    label: {
      type: String,
      default: ''
    },
    showReset: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    resetValue () {
      this.$emit('reset')
    }
  }
}
</script>
<style lang="scss" scoped>
.base-color-picker {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  .base-color-picker--title {
    margin-left: 10px;
  }
}
</style>
