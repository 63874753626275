<template>
  <page-layout
    id="dashboardPage"
    :title="$t('dashboard.title')"
    :sub-title="$t('dashboard.subtitle')"
  >
    <search-bar
      :corporateAccountId="
        $auth.user().isMsp ? $auth.corporateAccount().partner.ospId : $auth.corporateAccountId()
      "
    ></search-bar>

    <el-form
      v-if="$auth.user().isMsp"
      ref="form"
      label-position="top"
      :model="form"
      @submit.prevent.native="() => {}"
    >
      <base-select
        v-if="$auth.isOspAdmin()"
        v-model="partnerOspId"
        :label="$t('dashboard.show_data_for_partner')"
        :items="partnerList"
        :maxWidth="300"
        :filterable="true"
        :change="changePartner"
        @submit="$emit('submit')"
      />

      <div class="corporate-accounts-filter">
        <base-select
          :key="key"
          v-model="form.corporateAccount"
          :label="$t('dashboard.show_data_for_ca')"
          :items="corporateAccountsList"
          :placeholder="$t('dashboard.corporate_accounts.option.all_corporate_accounts')"
          :maxWidth="300"
          filterable
          :change="changeCorporate"
          :info="$t('dashboard.corporate_accounts.option.all_corporate_accounts_info')"
          @submit="$emit('submit')"
          remote
          clearable
          :remote-method="fetchCorporateAccounts"
        />
        <base-select
          v-if="$auth.isOspAdmin()"
          v-model="companyType"
          :label="$t('dashboard.company_type_filter.title')"
          :placeholder="$t('dashboard.company_type_filter.placeholder')"
          :items="companyTypes"
          :maxWidth="300"
          clearable
        />
      </div>
    </el-form>

    <general-data
      :key="'general-' + selectedCA"
      :sum-events="filteredSumEvents"
      :corporate-account-id="selectedCA"
      :period.sync="generalPeriod"
      :partner-osp-id="partnerOspId"
      :company-type="companyType"
    ></general-data>

    <el-row :gutter="24">
      <el-col :span="12">
        <users-expenses
          :key="'expenses-' + selectedCA"
          :corporateAccountId="selectedCA"
          :period.sync="generalPeriod"
          :sumEvents="filteredSumEvents"
        ></users-expenses>
      </el-col>
      <el-col v-if="$auth.user().isMsp && partnerOspId" :span="12">
        <corporate-accounts
          :partner-osp-id="partnerOspId"
          :company-type="companyType"
        ></corporate-accounts>
      </el-col>
    </el-row>

    <time-line
      :key="'line-' + selectedCA + companyType"
      :corporate-account-id="selectedCA"
      :period.sync="timelinePeriod"
      :company-type="companyType"
    ></time-line>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout'
import SearchBar from '@/components/Dashboard/SearchBar'
import GeneralData from '@/components/Dashboard/GeneralData'
import TimeLine from '@/components/Dashboard/TimeLine'
import UsersExpenses from '@/components/Dashboard/UsersExpenses'
import { mapActions, mapGetters } from 'vuex'
import CorporateAccounts from '@/components/Dashboard/CorporateAccounts'
import { sortBy, debounce } from 'lodash'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

export default {
  name: 'Dashboard',
  components: {
    PageLayout,
    SearchBar,
    GeneralData,
    TimeLine,
    CorporateAccounts,
    UsersExpenses
  },
  data () {
    return {
      generalPeriod: [this.$date.local().toFormat('yyyyMM'), this.$date.local().toFormat('yyyyMM')],
      timelinePeriod: [
        this.$date.local().minus({ month: 11 }).toFormat('yyyyMM'),
        this.$date.local().toFormat('yyyyMM')
      ],
      corporateAccounts: [],
      partnerOspId: '',
      key: '',
      form: {
        corporateAccount: ''
      },
      sumEvents: [],
      companyType: ''
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/corporateAccount',
      companyTypeList: 'corporateAccount/companyTypeList',
      allPartners: 'partner/allPartners'
    }),
    selectedCA () {
      return this.form.corporateAccount || this.partnerOspId
    },
    corporateAccountsList () {
      return this.corporateAccounts.map(ca => {
        return {
          label: ca.name,
          value: ca.ospId
        }
      })
    },
    partnerList () {
      return sortBy(
        this.allPartners.map(p => {
          return {
            label: p.name,
            value: p.ospId
          }
        }),
        v => v.label.toLowerCase()
      )
    },
    companyTypes () {
      return this.companyTypeList.map(c => ({
        label: this.$trans(c.label),
        value: c.companyType
      }))
    },
    filteredSumEvents () {
      if (this.companyType) {
        return this.sumEvents.filter(event => event.companyType === this.companyType)
      }
      return this.sumEvents
    }
  },
  watch: {
    generalPeriod: {
      deep: true,
      handler (v) {
        this.fetchSumEvents()
      }
    },
    'form.corporateAccount' (v) {
      this.corporateAccounts = []
    }
  },
  methods: {
    ...mapActions({
      fetchAllActivePartners: 'partner/fetchAllActivePartners',
      fetchCompanyTypeList: 'corporateAccount/getCompanyTypeList'
    }),

    async fetchCorporateAccounts (search) {
      if (search.length < 3) {
        return false
      }
      const content = await corporateAccountRepository.getAllByFilter(
        {
          nameOrExtIdLike: search,
          partnerOspId: {
            ospId: this.partnerOspId
          }
        },
        ['name', 'ospId']
      )
      this.corporateAccounts = sortBy(content, o => o.name.toLowerCase())
    },
    fetchSumEvents () {
      const form = {
        monthFrom: this.generalPeriod[0],
        monthTo: this.generalPeriod[1],
        parentOspId: this.selectedCA
      }
      this.$api.reporting.sumEvents(form, {
        onSuccess: response => {
          this.sumEvents = response
        }
      })
    },
    changeCorporate: debounce(function (ca) {
      this.form.corporateAccount = ca
      this.fetchSumEvents()
    }, 700),
    changePartner (partner) {
      this.key = this.$key()
      this.partnerOspId = partner
      this.form.corporateAccount = null
      this.corporateAccounts = []
      this.fetchSumEvents()
      this.key = this.$key()
    }
  },
  created () {
    this.partnerOspId = this.$auth.corporateAccount().partner.ospId
    this.form.corporateAccount = this.$auth.user().isMsp ? null : this.$auth.corporateAccountId()
  },
  mounted () {
    this.fetchAllActivePartners()
    this.fetchSumEvents()
    if (this.$auth.isOspAdmin() && !this.companyTypeList.length) {
      this.fetchCompanyTypeList()
    }
  }
}
</script>

<style lang="scss" scoped>
.corporate-accounts-filter {
  display: flex;
  gap: 24px;
  & > * {
    flex: 1 1 0;
    max-width: 300px;
  }
}
</style>
