<template>
  <base-section :withBorder="false" class="user-expenses">
    <el-form
      ref="form"
      :model="form"
      autocomplete="off"
      id="timeline"
      @submit.prevent.native="null"
    >
      <el-row type="flex" justify="space-between" align="center" class="user-expenses--header">
        <p class="title">{{ $t('dashboard.users_expenses.title') }}</p>
        <base-datepicker
          :key="key"
          v-model="daterange"
          type="monthrange"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          valueFormat="yyyyMM"
          format="MMMM yyyy"
          :maxWidth="300"
          :clearable="false"
          :picker-options="dateOptions"
          style="margin-right: 0"
        >
        </base-datepicker>
      </el-row>
      <base-section class="expenses-chart-section">
        <base-select
          v-model="form.type"
          ref="type"
          prop="type"
          :items="selectOptions"
          name="type"
        ></base-select>
        <v-chart
          :key="chartKey"
          class="expenses-chart"
          :class="'expenses-chart--' + this.chartDimensions.class"
          :autoresize="true"
          :options="charOptions"
        />
      </base-section>
    </el-form>

  </base-section>

</template>

<script>

import 'echarts/lib/chart/pie'
import 'echarts/lib/component/title'
import {
  groupBy,
  values,
  sumBy,
  has
} from 'lodash'

export default {
  name: 'UsersExpenses',
  props: ['period', 'corporateAccountId', 'sumEvents'],
  data () {
    return {
      chartKey: '',
      form: {
        type: 0
      },
      key: '',
      colors: [
        '#003C88',
        '#008443',
        '#641964',
        '#743410',
        '#BF41DE',
        '#DD1D21',
        '#EB8705'
      ]
    }
  },
  computed: {
    daterange: {
      get () {
        return this.period
      },
      set (v) {
        this.$emit('update:period', v)
      }
    },
    chartDimensions () {
      switch (true) {
        case this.charData.length > 15:
          return {
            class: 'extra-large'
          }
        case this.charData.length > 10:
          return {
            class: 'large'
          }
        case this.charData.length > 5:
          return {
            class: 'medium'
          }
        default:
          return {
            class: 'small'
          }
      }
    },
    charOptions () {
      return {
        title: {
          text: this.totalSum,
          itemGap: 4,
          textStyle: {
            fontWeight: 600,
            fontSize: 15,
            color: '#282C36'
          },
          left: 'center',
          top: 'center',
          subtext: 'incl. VAT',
          subtextStyle: {
            fontSize: 12,
            color: '#929EAC'
          }
          //  font-size: 12px;

          // color: #929EAC;
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `${params.data.valueLabel}<br>${params.name}`
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['56px', '68px'],
            startAngle: 0,
            // radius: this.chartDimensions.radius,
            data: this.charData,
            label: {
              padding: [13, 0, 0, 0],
              fontSize: 13,
              margin: 40,
              formatter: function (params) {
                return `{value|${params.data.valueLabel}}\n{name|${params.name}}`
              },
              rich: {
                name: {
                  fontSize: 12,
                  color: '#617484'
                },
                value: {
                  fontWeight: 600,
                  fontSize: 13
                }
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    totalSum () {
      const sum = sumBy(this.charData, 'value')
      return this.$currency(sum)
    },
    charData () {
      const data = groupBy(this.sumEvents, this.getGroupingProp())
      return values(data).map((items, idx) => {
        return {
          name: this.getLabel(items),
          value: sumBy(items, v => v.totalPriceVatIncl)
        }
      })
        .filter(v => v.value)
        .map((result, idx) => {
          if (has(this.colors, idx)) {
            result.itemStyle = {
              color: this.colors[idx]
            }
          }
          result.valueLabel = this.$currency(result.value)
          return result
        })
    },
    dateOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().endOf('day').toJSDate()
        }
      }
    },
    selectOptions () {
      return [
        {
          value: 0,
          label: this.$t('dashboard.users_expenses.selectOptions.per_mobility_mode')
        },
        {
          value: 1,
          label: this.$t('dashboard.users_expenses.selectOptions.per_mobility_resource_provider')
        }
      ]
    }
  },
  methods: {
    // fetchData () {
    //   if (this.form.type === 0) {
    //     this.fetchSumEvents()
    //   } else if (this.form.type === 1) {
    //     this.fetchSumTransactions()
    //   }
    // },
    getGroupingProp () {
      if (this.form.type === 0) {
        return 'mobilityMode'
      } else {
        return 'mrpExtId'
      }
    },
    getLabel (items) {
      if (this.form.type === 0) {
        return this.$trans(items[0].mobilityModeLabel)
      } else {
        return items[0].mrpName
      }
    },
    labelFormatter ({ name, data }) {
      return `${data.valueLabel} \n ${name}`
    },
    formatTooltip (v) {
      // let { value, name } = v[0]
      // name = this.$date.fromFormat(name, 'yyyyMM').toFormat('MMMM yyyy')
      // if (this.form.type === 0) {
      //   value = `${value} transactions`
      // } else if (this.form.type === 1) {
      //   value = `${value} active users`
      // } else if (this.form.type === 2) {
      //   value = this.$currency(value)
      // }
      // value += ` <br> <span style="color: rgba(255, 255, 255, 0.6)">${name}</span>`
      // return value
    }
  }
}
</script>
