import Vue from 'vue'

const install = function (Vue) {
  Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          if (typeof binding.value === 'function') {
            binding.value.bind(vnode.context)(event)
          } else {
            let expression = ''
            const beg = ['\'', '`', '"'].includes(binding.expression[0]) ? 1 : 0
            const count = ['\'', '`', '"'].includes(binding.expression[binding.expression.length - 1]) ? binding.expression.length - 1 : binding.expression.length
            expression = binding.expression.substring(beg, count)
            // console.log(expression)
            new Function(expression).bind(vnode.context)(event) // eslint-disable-line
          }
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  })
}

if (window.Vue) {
  Vue.use(install) // eslint-disable-line
}

export default install
