import UserRepository from '../repositories/UserRepository'
import { cloneDeep, trim } from 'lodash'
import router from '@/router'
import { DateTime } from 'luxon'
import { DATE_FORMAT, trans } from '@/app/helpers'
import api from '@/api'
import store from '@/store'
import Role from '@/app/services/RolesService/Role'

class User {
  #original = {}
  #repository = null

  ospId = ''
  firstname = ''
  lastname = ''
  email = ''
  login = ''
  startActivationTime = ''
  roles = []

  constructor (object = {}) {
    this.#original = cloneDeep(object)
    this.#repository = new UserRepository()
    Object.assign(this, { ...object })
  }

  get fullname () {
    return trim(this.firstname + ' ' + this.lastname) || this.email
  }

  get activeSince () {
    DateTime.fromISO(this.startActivationTime).toFormat(DATE_FORMAT)
  }

  get transferData () {
    return {
      key: this.ospId,
      label: this.fullname,
      disabled: false
    }
  }

  get contactTypesLabels () {
    return store.getters['user/findContactTypes'](this.contactTypes)
  }

  get rolesLabels () {
    return store.getters['user/findRoles'](this.roles)
  }

  get type () {
    const list = this.rolesLabels.concat(this.contactTypesLabels)
    return list.map(item => trans(item.label)).join(', ')
  }

  get isAdmin () {
    return this.roles.length && !this.hasRole(Role.OSPUSER) && !this.hasRole(Role.CONTACT_USER)
  }

  get isContact () {
    return this.roles.length && this.hasRole(Role.CONTACT_USER)
  }

  get isMobile () {
    return this.hasRole(Role.OSPUSER)
  }

  get selectListItem () {
    return {
      value: this.ospId,
      label: this.fullname
    }
  }

  hasRole (role) {
    return role && this.roles && this.roles.length && this.roles.includes(role)
  }

  viewDetails () {
    // console.log(this)
    if (this.isMobile) {
      return router.push({
        name: 'AppUserDetailView',
        params: { userOspId: this.ospId },
        query: {
          corporateAccountOspId: this.corporateAccountOspId
        }
      })
    } else if (this.isContact) {
      return router.push({
        name: 'ContactUserView',
        params: { userOspId: this.ospId },
        query: {
          corporateAccountOspId: this.corporateAccountOspId
        }
      })
    } else {
      return router.push({
        name: 'AdminUserViewDetails',
        params: { userOspId: this.ospId },
        query: {
          corporateAccountOspId: this.corporateAccountOspId
        }
      })
    }
  }

  update (options) {
    return api.user.updateUser(this, options)
  }
}

export default User
