import Auth from '../services/AuthService/Auth'

export default function hasRole (...roles) {
  // console.log('middleware hasRole')
  if (roles && roles[0] && Array.isArray(roles[0])) {
    roles = roles[0]
  }
  return (to, from, next) => {
    if (Auth.hasRole(roles)) {
      return next()
    }
    return next('404')
  }
}
