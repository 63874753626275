import api from '@/api'
import Auth from '@/app/services/AuthService/Auth'

export default {
  namespaced: true,
  state: {
    partners: []
  },
  getters: {
    partners: state => state.partners
  },
  actions: {
    getPartners: async ({ commit }) => {
      if (Auth.isOspAdmin()) { // backend responds with error if user not ospadmin
        await api.fuel.partner({
          onSuccess: response => {
            commit('SET_PARTNERS', response)
          }
        })
      }
    }
  },
  mutations: {
    SET_PARTNERS: (state, data) => {
      state.partners = data
    }
  }
}
