export default {
  name: 'CancelRequest',
  data () {
    return {
      $_CancelRequest_requestSource: null
    }
  },
  methods: {
    CancelRequest_token () {
      try {
        if (this.$_CancelRequest_requestSource) {
          this.$_CancelRequest_requestSource.cancel()
        }
      } catch (e) {
        console.error(e)
      }
      this.$_CancelRequest_requestSource = this.$cancelToken()
      return this.$_CancelRequest_requestSource
    }
  }
}
