<template>
  <el-form-item
    :label="label && label + (required ? '*' : '')"
    :prop="prop"
    label-position="top"
    :class="{
      'has-error': error || validationsError,
      'has-message': validationsError || error || success,
      disabled: disabled
    }"
  >
    <el-select
      v-model="model"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :allow-create="allowCreate"
      :filterable="filterable"
      :filter-method="filterMethod"
      :remote="remote"
      :remote-method="remoteMethod"
      :loading="loading"
      :loading-text="loadingText"
      :default-first-option="defaultFirstOption"
      :popper-append-to-body="popperAppendToBody"
      :style="{ 'max-width': maxWidth ? `${maxWidth}px` : null }"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :class="{
        'select-with-prefix': !!$slots.prefix,
        'select-with-clear-button': clearable,
        'select-with-hidden-suffix': items.length <= 1
      }"
      @change="change"
    >
      <template #prefix v-if="$slots.prefix && !clearable">
        <slot name="prefix"></slot>
      </template>
      <template #prefix v-else-if="clearable && model">
        <i class="el-icon-circle-close" @click.stop="clearHandler"></i>
      </template>

      <slot name="option" :items="items">
        <el-option v-for="item in items" :key="item.value" :label="item.label" :value="item.value">
          <slot :item="item"></slot>
        </el-option>
      </slot>
    </el-select>
    <p v-if="validationsError" class="error">
      {{
        $t(`validations.${validationsError}.${label ? 'with_label' : 'without_label'}`, { label })
      }}
    </p>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <p v-else-if="success" class="success">
      {{ success }}
    </p>
    <p v-if="info" class="info">
      <span v-html="info"></span>
    </p>
  </el-form-item>
</template>

<script>
import { keys } from 'lodash'
import i18n from '@/i18n'

export default {
  name: 'BaseSelect',
  props: {
    allowCreate: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    popperAppendToBody: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    maxWidth: {
      type: Number,
      default: null
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultFirstOption: {
      type: Boolean,
      default: false
    },
    remoteMethod: {
      type: Function,
      default: () => {}
    },
    filterMethod: {
      type: Function,
      default: null
    },
    loadingText: {
      type: String,
      default: i18n.t('general.label.loading')
    },
    multiple: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: function () {}
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    validationsError () {
      return (
        this.validations &&
        this.validations.$dirty &&
        this.validations.$params &&
        keys(this.validations.$params).find(v => !this.validations[v])
      )
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  },
  methods: {
    clearHandler () {
      this.model = ''
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables';

.el-select.select-with-clear-button {
  .el-input__prefix {
    left: unset !important;
    right: 35px;
    cursor: pointer;
    i {
      color: $gray-500 !important;
      line-height: 32px !important;
    }
  }
  .el-input__inner {
    padding-left: 15px !important;
    padding-right: 60px !important;
  }
  &.select-with-hidden-suffix {
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      right: 10px;
    }
  }
}
</style>
