<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <div
      class="el-color-dropdown"
      v-show="showPopper">
      <div class="el-color-dropdown__main-wrapper">
        <hue-slider ref="hue" :color="color" vertical style="float: right;"></hue-slider>
        <sv-panel ref="sl" :color="color"></sv-panel>
      </div>
      <alpha-slider v-if="showAlpha" ref="alpha" :color="color"></alpha-slider>
      <predefine v-if="predefine" :color="color" :colors="predefine"></predefine>
      <div class="el-color-dropdown__btns">
        <span class="el-color-dropdown__value">
          <el-input
            v-model="customInput"
            @keyup.native.enter="handleConfirm"
            @blur="handleConfirm"
            :validate-event="false"
            v-mask="colorMask"
            size="mini">
          </el-input>
        </span>
        <el-button
          v-if="showReset"
          size="mini"
          type="text"
          class="el-color-dropdown__link-btn"
          @click="$emit('reset')">
          {{ $t('general.label.reset') }}
        </el-button>
        <el-button
          v-else-if="showClear"
          size="mini"
          type="text"
          class="el-color-dropdown__link-btn"
          @click="$emit('clear')">
          {{ $t('general.label.clear') }}
        </el-button>
        <el-button
          plain
          size="mini"
          class="el-color-dropdown__btn"
          @click="confirmValue">
          {{ $t('general.confirm') }}
        </el-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Locale from 'element-ui/src/mixins/locale'
import PickerDropdown from 'element-ui/packages/color-picker/src/components/picker-dropdown.vue'

export default {
  name: 'BaseColorPickerDropdown',
  mixins: [PickerDropdown, Locale],
  props: {
    showReset: {
      type: Boolean,
      default: false
    },
    showClear: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      colorMask: {
        mask: ['#ZZZZZZ', '#ZZZZZZZZ'],
        tokens: {
          Z: { pattern: /[a-fA-F0-9]/, transform: v => v.toUpperCase() }
        }
      }
    }
  }
}
</script>
