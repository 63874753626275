import Api from './Api'

export const api = (options = {}) => new Api(options)

export const USER_SERVICE_V1 = 'user-service/v1'
export const PORTAL_SERVICE_V1 = 'portal-service/v1'
export const PORTAL_SERVICE_V2 = 'portal-service/v2'
export const PLUGIN_SERVICE_V1 = 'plugin-service/v1'
export const CAMBIO_CO2 = 'cambio/co2'
export const CAMBIO_INVOICE = 'cambio/invoice'
