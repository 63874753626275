import store from '@/store'
import { intersection, get } from 'lodash'
import User from './User'
import Role from '@/app/services/RolesService/Role'

export default class Auth {
  static token () {
    return store.getters['auth/token']
  }

  static id () {
    return store.getters['user/ospId']
  }

  static corporateAccountId () {
    return store.getters['user/corporateAccountOspId']
  }

  static corporateAccount () {
    return store.getters['corporateAccount/corporateAccount']
  }

  static partner () {
    return store.getters['partner/userPartner']
  }

  static userData () {
    return get(store.getters['user/user'], ['osp.user', 'data'], {})
  }

  static user () {
    return new User()
  }

  static hasRole (...roles) {
    if (roles && roles[0] && Array.isArray(roles[0])) {
      roles = roles[0]
    }
    !roles.includes(Role.OSPADMIN) && roles.push(Role.OSPADMIN)
    // console.log(roles)
    return Auth.roles() && Auth.roles().length && intersection(roles, Auth.roles()).length
  }

  static isOspAdmin () {
    return Auth.roles() && Auth.roles().length && Auth.roles().includes(Role.OSPADMIN)
  }

  static isMsp () {
    return Auth.user().isMsp || Auth.isOspAdmin()
  }

  static roles () {
    return Auth.user().roles || []
  }
}
