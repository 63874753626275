<template>
  <div
    class="base-branding-uploader"
  >
    <h5 class="text-secondary-title base-branding-uploader--title">{{title}}</h5>
    <p class="text-small text-muted base-branding-uploader--subtitle">{{subtitle}}</p>
    <el-upload
      action="#"
      list-type="picture-card"
      :auto-upload="false"
      :limit="1"
      :accept="accept"
      :on-change="handleOnChange"
      :file-list="fileList"
      :class="{
              'hide-uploader': !!model
           }"
    >
      <i slot="default" class="ri-add-line"></i>
      <div slot="file" slot-scope="{file}" class="el-upload-list__item--thumbnail-wrapper">
        <img
          class="el-upload-list__item-thumbnail"
          :src="file.url" alt=""
        >
        <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="$emit('download')"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
      </div>

      <div class="el-upload__tip" slot="tip">{{ $t('partners_management.view_partner.branding.images_section.image_type_explanation', { extension: imageExt.toUpperCase() }) }}</div>
    </el-upload>

    <el-dialog v-if="model && model.url" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'BaseBrandingImage',
  props: {
    value: {
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '*/*'
    }
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    fileList () {
      return this.model ? [this.model] : []
    },
    imageExt () {
      return this.accept.split('/')[1]
    }
  },
  methods: {
    handleRemove (file) {
      this.model = null
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = window.URL.createObjectURL(file.raw)
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    },
    handleOnChange (file, fileList) {
      if (file && file.raw) {
        this.model = file
      }
    }
  }
}
</script>
