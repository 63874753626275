import middleware from '@/app/services/MiddlewareService/middleware'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'reporting',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reporting" */ '@/views/Reports.vue'),
    beforeRouteEnter: middleware(`hasRole:${Role.REPORTING_MANAGER}`)
  }
]
