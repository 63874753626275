import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getCommuteDistance: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/distance/get`, filter),
  updateCommuteDistance: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/distance/update`, form),
  deleteCommuteDistance: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/commute/distance/delete`, form),
  // /v1/travel/compensation/get
  getTravelCompensation: (corporateAccountOspId, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/travel/compensation/get`, { corporateAccountOspId }),
  updateTravelCompensation: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/travel/compensation/update`, data),

  getBusinessTripConfig: (userOspId, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/businessTrip/configuration/permission/get`, { userOspId }),
  updateBusinessTripConfig: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/businessTrip/configuration/permission/update`, data),

  getTravelData: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/travel/getByFilter`, data),

  getTravelRegistrations: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/travel/registration/get`, filter)
}
