// import { trans } from '../../helpers'
import store from '@/store'
import BudgetRepository from '../repositories/BudgetRepository'
import { cloneDeep, omit } from 'lodash'
import { DateTime } from 'luxon'
import { DATE_FORMAT } from '@/app/helpers'

class Budget {
  type = ''
  budgetType = {}
  #original = {}
  #repository = {}
  transferStrategy = null // false = ADD, true = RESET
  equalToRecurring = true
  startAmount = 0
  frequencyAmount = 0
  frequency = null
  tvaIncluded = true
  vatIncluded = true
  limited = true
  frequencyMethod = 'MANUAL'
  frequencyAmountUpdateStrategy = 'NEXT_PERIOD'
  previousFrequencyAmount = 0
  endOfYearRecurrenceStrategy = undefined

  constructor (object = { data: {} }) {
    this.#original = cloneDeep(object)
    Object.assign(this, { ...object.data })
    this.ospId = object.ospId
    const budgetType = this.setBudgetType()

    setTimeout(() => {}, 50)

    if (this.type === 'CAFETERIA_BUDGET' && this.endOfYearRecurrenceStrategy === undefined) {
      this.endOfYearRecurrenceStrategy = 'CONTINUE'
    }

    if (object.data.vatIncluded === undefined && budgetType) {
      this.vatIncluded = budgetType.allowedVatStrategiesFlat.includes('VAT_INCLUDED')
    }

    if (object.data.frequency === undefined && budgetType) {
      this.frequency = budgetType.defaultAllowedFrequency || null
    }

    if (this.frequency && object.data.transferStrategy === undefined && budgetType) {
      this.transferStrategy = budgetType.getDefaultAllowedStrategy(this.frequency) || null
    }

    this.#repository = new BudgetRepository()
  }

  get name () {
    return this.budgetType.name
  }

  get isMobilityBudget () {
    return this.type === 'MOBILITY_BUDGET'
  }

  get currentAmountUntil () {
    if (this.frequency === 'YEAR') {
      return DateTime.local().plus({ year: 1 }).startOf('year').toFormat(DATE_FORMAT)
    } else if (this.frequency === 'MONTH') {
      return DateTime.local().plus({ month: 1 }).startOf('month').toFormat(DATE_FORMAT)
    }
    return ''
  }

  get allowUnlimited () {
    return ['BUSINESS_BUDGET', 'CAFETERIA_BUDGET', 'COMMUTING_BUDGET'].includes(this.type)
  }

  get unlimited () {
    return !this.limited
  }

  set unlimited (v) {
    this.limited = !v
  }

  setBudgetType () {
    this.budgetType = store.getters['corporateAccount/selectedCorporateAccount'].budgetTypes.find(v => v.budgetType === this.type)
    return this.budgetType
  }

  getUpdateForm () {
    return {
      ospId: this.ospId,
      data: this.getCreateForm()
    }
  }

  getCreateForm () {
    const isAutomaticFrequencyMethod = this.frequency !== null
    const excludedFields = ['budgetType', 'ospId']
    if (!isAutomaticFrequencyMethod) {
      excludedFields.push('frequencyAmountUpdateStrategy')
    }
    return {
      ...omit(this, excludedFields),
      transferStrategy: this.transferStrategy,
      frequencyMethod: isAutomaticFrequencyMethod ? 'AUTOMATIC' : 'MANUAL'
    }
  }

  save (options) {
    const repository = new BudgetRepository()
    if (!this.ospId) {
      return repository.create(this.getCreateForm(), options)
    } else {
      return repository.update(this.getUpdateForm(), options)
    }
  }
}

export default Budget
