<template>
  <div
    class="el-switch"
    :class="{ 'is-disabled': switchDisabled, 'is-checked': checked }"
    role="switch"
    :aria-checked="checked"
    :aria-disabled="switchDisabled"
    @click.prevent="switchValue"
  >
    <input
      :id="id"
      :name="name"
      type="checkbox"
      ref="input"
      class="el-switch__input"
      :true-value="activeValue"
      :false-value="inactiveValue"
      :disabled="switchDisabled"
      @change="handleChange"
      @keydown.enter="switchValue"
    />
    <span
      v-if="label"
      :class="['el-switch__label', 'el-switch__label--left', largeLabel ? 'large-label' : '']"
    >
      {{ label }}
    </span>
    <span class="el-switch__core" ref="core" :style="{ width: coreWidth + 'px' }"> </span>
  </div>
</template>

<script>
import ElSwitch from 'element-ui/packages/switch/src/component'

export default {
  name: 'BaseSwitch',
  mixins: [ElSwitch],
  props: {
    label: {
      type: String,
      default: ''
    },
    largeLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.el-switch {
  .el-switch__core {
    background: white;
    border-color: var(--brand-color);
  }
  .el-switch__core:after {
    background-color: var(--brand-color);
  }
  .el-switch__label.large-label {
    font-size: 1rem;
  }
}
</style>
