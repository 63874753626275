import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/helpers.js'
import './plugins/scrollto.js'
import './plugins/mask.js'
import './plugins/vue-currency-input.js'
import './plugins/clipboard'
import './plugins/echarts'
import i18n from './i18n'
import 'remixicon/fonts/remixicon.css'
import './styles/app.scss'
import role from './directives/role'
import clickOutside from './directives/clickOutside'
import api from './plugins/api'
import auth from './plugins/auth'
import init from './app/helpers/init'
import kebabCase from 'lodash/kebabCase'
import Vuelidate from 'vuelidate'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(api)
Vue.use(auth)
Vue.use(role)
Vue.use(clickOutside)

const requireComponent = require.context(
  // Относительный путь до каталога компонентов
  './components',
  // Обрабатывать или нет подкаталоги
  false,
  // Регулярное выражение для определения файлов базовых компонентов
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Получение конфигурации компонента
  const componentConfig = requireComponent(fileName)

  // Получение имени компонента в PascalCase
  const componentName =
    kebabCase(
      // Получаем имя файла независимо от глубины вложенности
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )

  // Глобальная регистрация компонента
  Vue.component(
    componentName,
    // Поиск опций компонента в `.default`, который будет существовать,
    // если компонент экспортирован с помощью `export default`,
    // иначе будет использован корневой уровень модуля.
    componentConfig.default || componentConfig
  )
})

init().then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
