<template>
  <el-form-item
    :label="label && (label + (required ? '*' : ''))"
    :prop="prop"
    :disabled="disabled"
    label-position="top"
    class="base-currency-input"
    :class="{
    'has-error': error || validationsError,
    'has-message': validationsError || error || success,
    'disabled': disabled
    }"
  >
    <slot></slot>
    <span
      class="el-input__wrapper"
      :class="{
      'el-input__wrapper--with-suffix': !!suffix,
      'el-input__wrapper--with-value': !!model,
      'el-input__wrapper--disabled': !!disabled
    }"
      :data-suffix="suffix"
    >
    <currency-input
       ref="input"
       v-model="model"
       class="el-input__inner"
      :allow-negative="allowNegative"
      :style="{'max-width': maxWidth ? `${maxWidth}px` : null}"
      :name="name"
      :disabled="disabled || readonly"
      :placeholder="placeholder"
      :currency="currency"
      :precision="precision"
      :readonly="readonly"
       auto-complete="off"
      @keyup.enter.native.prevent="$emit('keyupEnter')"
    ></currency-input>
      </span>
    <p v-if="validationsError" class="error">
      {{ $t(`validations.${validationsError}.${label ? 'with_label' : 'without_label'}`, { label }) }}
    </p>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <p v-else-if="success" class="success">
      {{ success }}
    </p>
    <p v-if="info" class="info">
      {{ info }}
      <span v-if="extraInfo" v-html="extraInfo"></span>
    </p>
  </el-form-item>
</template>

<script>
import { keys, get } from 'lodash'

export default {
  name: 'BaseCurrencyInput',
  props: {
    value: {
      default: null
    },
    autoComplete: {
      type: String,
      default: 'on'
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    currency: {
      type: Object,
      default: () => {
        return {
          suffix: null,
          prefix: '€ '
        }
      }
    },
    precision: {
      type: Number,
      default: 2
    },
    suffix: {
      type: String,
      default: ''
    },
    extraInfo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    validationsError () {
      const invalidKey = this.validations && this.validations.$dirty && this.validations.$params && keys(this.validations.$params).find(v => !this.validations[v])
      return invalidKey && get(this.validations.$params, [invalidKey, 'type'], invalidKey)
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  }
}
</script>
