<template>
  <el-form
    ref="form"
    :model="form"
    autocomplete="off"
    id="searchBar"
    label-position="top"
    @submit.prevent.native="() => null"
    v-click-outside="closeEvent"
  >
    <div>
      <base-input
        :key="searchKey"
        v-model="search"
        :placeholder="$t('dashboard.search_bar.placeholder')"
        ref="formSearch"
        prop="formSearch"
        name="formSearch"
        style="min-width: 100%"
        @keyupEnter="handleSubmit"
      >
        <template #prependInput><i class="ri-search-line"></i></template>
      </base-input>
      <el-popover
        placement="bottom-start"
        trigger="manual"
        v-model="visible"
      >
        <el-scrollbar
          wrap-class="el-select-dropdown__wrap"
          view-class="el-select-dropdown__list"
          ref="scrollbar"
          v-show="list.length > 0 && !loading"
        >
          <el-table
            id="searchResultsTable"
            ref="table"
            :data.sync="list"
            row-class-name="table-row clickable el-select-dropdown__item dashboard-user-selection"
            @row-click="viewDetails"
            :empty-text="emptyText"
            :show-header="false"
            style="width: 100%"
          >
            <el-table-column
              prop="icon"
              width="37"
            >
              <template slot-scope="{ row }">
                <i :class="row.isMobile ? 'ri-contacts-line' : 'ri-user-settings-line'"></i>
              </template>
            </el-table-column>
            <el-table-column
              class-name="text-bold"
              prop="fullname"
              min-width="120"
              show-overflow-tooltip
              sortable="custom"
            />
            <el-table-column
              prop="login"
              min-width="120"
              show-overflow-tooltip
              sortable="custom"
            />
            <el-table-column
              prop="email"
              min-width="150"
              show-overflow-tooltip
              sortable="custom"
            />
            <el-table-column
              prop="corporateAccountName"
              min-width="120"
              show-overflow-tooltip
              sortable="custom"
            />
            <el-table-column
              prop="msisdn"
              min-width="140"
              show-overflow-tooltip
              sortable="custom"
            />
            <el-table-column
              prop="icon"
              width="37"
            >
              <template slot-scope="{ row }">
                <i v-if="hasVoucherCodeMatch(row)" class="ri-ticket-line"></i>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
        <template v-if="(emptyText && loading) || list.length === 0">
          <slot name="empty" v-if="$slots.empty"></slot>
          <p class="el-select-dropdown__empty" v-else>
            {{ emptyText }}
          </p>
        </template>
      </el-popover>
    </div>
    <div>
      <base-button
        :label="$t('general.search')"
        :loading="loading"
        :disabled="!search || search.length < 3"
        type="primary"
        @click.stop="handleSubmit"
      ></base-button>
    </div>
    <div class="info" v-html="$t('dashboard.search_bar.info')"></div>
    <div>
      <el-form-item>
        <el-checkbox v-model="active">
          {{ $t('dashboard.search_bar.active_users_only') }}
        </el-checkbox>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import UserRepository from '@/app/repositories/UserRepository'
import CancelRequest from '@/mixins/CancelRequest'

const userRepository = new UserRepository()

export default {
  name: 'SearchBar',
  mixins: [CancelRequest],
  props: {
    corporateAccountId: {
      type: String
    }
  },
  data () {
    return {
      form: {},
      active: true,
      visible: false,
      search: '',
      searchKey: null,
      list: [],
      loading: false,
      submitProcessing: false
    }
  },
  computed: {
    selected () {
      return this.list.find(v => v.ospId === this.form.value)
    },
    emptyText () {
      return this.loading ? this.$t('general.label.loading') + '...' : this.$t('general.empty_table')
    }
  },
  methods: {
    handleSubmit () {
      this.visible = true
      this.remoteMethod(this.search)
    },
    remoteMethod (query = '') {
      const form = {
        active: this.active || undefined,
        pagination: {
          page: 0,
          size: 1000,
          sort: {
            sortOrders: [
              { direction: 'ASCENDING', property: 'firstname' },
              { direction: 'ASCENDING', property: 'lastname' },
              { direction: 'ASCENDING', property: 'email' }
            ]
          }
        },
        search: query || undefined
      }
      this.loading = true
      userRepository.findByAny(form, {
        cancelToken: this.CancelRequest_token(),
        onSuccess: ({ content }) => {
          this.list = content
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    hide () {
      this.visible = false
    },
    viewDetails (item) {
      return item.viewDetails()
    },
    closeEvent: function () {
      this.hide()
      this.search = ''
      this.searchKey = this.$key()
    },
    hasVoucherCodeMatch ({ searchMatchedOn }) {
      return Array.isArray(searchMatchedOn) && searchMatchedOn.some(el => el === 'VOUCHER_CODE')
    }
  },
  mounted () {
    // this.remoteMethod()
  }
}
</script>

<style lang="scss">
#searchResultsTable .cell i {
  display: flex;
}
</style>
