import Model from './Model'
import { DateTime } from 'luxon'
import { DATE_FORMAT } from '@/app/helpers'

class Reconciliation extends Model {
  constructor (object = {}) {
    super(object)
  }

  // bookingId: '7ZN8UP9YXT01',
  // mrpOspId: 'null',
  // expectedAmount: 0.00,
  // actualAmount: 9.80,
  // currency: 'EUR',
  // status: 'UNEXPECTED_PAYMENT',
  // manualReconciliation: 'false',
  // lastUpdatedOn: '2020-03-06T09:46:16+0000'

  get dateTime () {
    return DateTime.fromISO(this.lastUpdatedOn).toFormat(DATE_FORMAT + ' ' + 'HH\'h\'mm')
  }

  get lastUpdatedOnUnix () {
    return DateTime.fromISO(this.lastUpdatedOn).toMillis()
  }
}

export default Reconciliation
