import { countries } from '@/app/services/CountriesService/CountriesService'

export default {
  namespaced: true,
  state: {
    list: countries()
  },
  getters: {

  },
  actions: {

  },
  mutations: {

  }
}
