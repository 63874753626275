<template>
  <div
    class="information-plate"
    :class="{ 'information-plate--clickable': clickable }"
  >
    <div class="information-plate__inner">
      <div class="text-extra-large text-bold information-plate--value">{{ value }}</div>
      <div v-if="clickable" class="information-plate--icon"><i class="ri-arrow-right-s-line"></i></div>
      <div class="information-plate--label">{{ label }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GeneralDataItem',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  }
}
</script>
