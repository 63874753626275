import 'echarts/lib/chart/line'
import { mapGetters } from 'vuex'

export default {
  name: 'AppUserBudgetTimeline',
  props: ['userOspId'],
  data () {
    return {
      charData: {
        seriesData: [],
        categoriesData: []
      }
    }
  },
  computed: {
    ...mapGetters({
      brandColor: 'branding/brandColor'
    }),
    gridLeft () {
      return 64
    },
    charOptions () {
      return {
        grid: {
          left: this.gridLeft,
          top: 31,
          right: 28,
          bottom: 56
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#282C36ee',
          borderColor: '#fff',
          formatter: this.formatTooltip,
          axisPointer: {
            crossStyle: {
              color: '#297c29'
            },
            lineStyle: {
              color: this.brandColor + '88'
            }
          },
          textStyle: {
            fontSize: 13
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.charData.categoriesData,
          axisTick: {
            interval: 0,
            length: 6,
            lineStyle: {
              color: '#B6BEC9'
            }
          },
          axisLabel: {
            // interval: 0,
            margin: 16,
            fontSize: 13,
            color: '#617484',
            formatter: this.dateFormatter
          },
          // minorTick: {
          //   show: true,
          //   splitNumber: 17,
          //   length: 22.5
          // },
          axisLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          minInterval: 1,
          // maxInterval: 100,
          axisLine: {
            show: false
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: '#B6BEC9'
            }
          },
          axisLabel: {
            // interval: 0,
            margin: 20,
            fontSize: 13,
            color: '#617484',
            formatter: this.formatYLabel
          },
          splitLine: {
            lineStyle: {
              color: '#B6BEC9'
            }
          }
        },
        series: [{
          data: this.charData.seriesData,
          type: 'line',
          showSymbol: false,
          symbolSize: 5,
          areaStyle: {
            color: this.brandColor,
            opacity: 0.1
          },
          itemStyle: {
            color: this.brandColor
          },
          emphasis: {
            itemStyle: {
              borderColor: '#fff',
              color: this.brandColor,
              borderWidth: 2
            },
            label: {
              width: 50,
              height: 50
            }
          },
          // lineStyle: {
          //   color: '#0F89EA'
          // },
          smooth: true
        }]
      }
    }
  },
  methods: {
    dateFormatter (v) {
      return v ? this.$date.fromFormat(v, 'yyyyMM').toFormat('MM/yyyy') : v
    },
    formatYLabel (v) {
      if (this.form.type === 1) {
        return `€ ${v}`
      }
      return v
    },
    formatTooltip (v) {
      let { value, name } = v[0]
      name = name ? this.$date.fromFormat(name, 'yyyyMM').toFormat('MMMM yyyy') : ''
      if (this.form.type === 0) {
        value = this.$t('dashboard.timeline.tooltip.transactions', { value })
        // value = `${value} transactions`
      } else if (this.form.type === 1) {
        value = this.$currency(value)
      }
      value += ` <br> <span style="color: rgba(255, 255, 255, 0.6)">${name}</span>`
      return name ? value : undefined
    }
  }
}
