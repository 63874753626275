import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  get: (data, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/fuel/fuelstation/owner/get`, data, {
      transformRequest: [reqData => reqData]
    }),
  update: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/fuel/fuelstation/owner/update`, data),
  getByOwner: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/fuel/fuelstation/getByOwner`, data),
  partner: options => api(options).post(`${PORTAL_SERVICE_V1}/fuel/partner`)
}
