import api from '@/api'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    branding: {},
    brandColor: null,
    brandLogo: null
  },
  getters: {
    branding: state => state.branding,
    brandingColors: state => state.branding,
    brandColor: state => state.branding.brandColor || '#0F89EA',
    brandLogo: state => state.brandLogo
  },
  actions: {
    getBranding ({ commit }) {
      return api.branding.getBranding({
        onSuccess: ({ brandColor }) => {
          commit('SET_BRAND_COLOR', brandColor)
        }
      })
    },
    getBrandLogo ({ commit }) {
      return api.branding.getBrandLogo({
        onSuccess: response => {
          // console.log(response)
          commit('SET_BRAND_LOGO', Buffer.from(response, 'binary').toString('base64'))
        }
      })
    }
  },
  mutations: {
    SET_BRANDING: (state, branding) => {
      state.branding = branding
    },
    SET_BRAND_COLOR: (state, brandColor) => {
      Vue.set(state.branding, 'brandColor', brandColor)
    },
    SET_BRAND_LOGO: (state, brandLogo) => {
      state.brandLogo = 'data:image/png;base64,' + brandLogo
    }
  }
}
