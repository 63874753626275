import middleware from '@/app/services/MiddlewareService/middleware'

export default [
  {
    path: 'order-handling',
    name: 'OrderHandling',
    component: () => import(/* webpackChunkName: "order-handling" */ '@/layouts/OrderHandlingLayout.vue'),
    beforeEnter: middleware('needSelectedCorporateAccount'),
    props: true,
    redirect: { name: 'SeasonCardOrders' },
    children: [
      {
        path: 'season-card-orders',
        name: 'SeasonCardOrders',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () =>
          import(/* webpackChunkName: "order-handling" */ '@/views/OrderHandling/SeasonCardOrders.vue')
      },
      {
        path: 'handled-season-card-orders',
        name: 'HandledSeasonCardOrders',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () =>
          import(/* webpackChunkName: "order-handling" */ '@/views/OrderHandling/HandledSeasonCardOrders.vue')
      }
    ]
  }
]
