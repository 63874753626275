import Cookies from 'js-cookie'

// like this
const TOKEN_KEY = 'vue_portal_token'
const REFRESH_TOKEN_KEY = 'vue_portal_refresh_token'
const LANGUAGE_KEY = 'vue_portal_language'
const COOKIES_LIFETIME = 7 // days

function urlBase64Decode (str) {
  return Buffer.from(str, 'base64').toString('utf-8')
}

function parseToken (token) {
  try {
    const encoded = token.split('.')[1]
    return JSON.parse(urlBase64Decode(encoded))
  } catch (e) {
    console.log(e)
    return {}
  }
}

export function getToken () {
  return Cookies.get(TOKEN_KEY) || null
}

export function getUser () {
  const token = getToken()
  return token ? parseToken(token) : {}
}

export function verifyLogin (token) {
  const authData = parseToken(token)
  return !authData['osp.authorities'].includes('ospuser')
}

export function setToken (token) {
  return Cookies.set(TOKEN_KEY, token, { expires: COOKIES_LIFETIME })
}

export function getRefreshToken () {
  return Cookies.get(REFRESH_TOKEN_KEY) || null
}

export function setRefreshToken (token) {
  return Cookies.set(REFRESH_TOKEN_KEY, token, { expires: COOKIES_LIFETIME })
}

export function removeToken () {
  return Cookies.remove(TOKEN_KEY)
}

export function removeRefreshToken () {
  return Cookies.remove(REFRESH_TOKEN_KEY)
}

export function getLanguage (fallback = 'nl') {
  return Cookies.get(LANGUAGE_KEY) || fallback
}

export function setLanguage (arg) {
  Cookies.set(LANGUAGE_KEY, arg, { expires: 999 })
}

export function isHavingLanguage () {
  return !!Cookies.get(LANGUAGE_KEY)
}
