<template>
  <img :src="svg"/>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    svg () {
      if (this.icon) {
        try {
          // return `@/assets/icons/${this.icon}.svg`
          return require(`@/assets/icons/${this.icon}.svg`)
        } catch (e) {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
