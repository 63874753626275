import api from '@/api'
import Repository from './Repository'
import User from '../models/User'
import Auth from '../services/AuthService/Auth'

class UserRepository extends Repository {
  constructor () {
    super(User)
  }

  getByFilter (filter, options) {
    const optionsData = this.addWrapperWithPaginator(options, 'content')
    return api.user.getByFilter(filter, optionsData)
  }

  findByAny (filter, options) {
    const optionsData = this.addWrapperWithPaginator(options, 'content')
    return api.user.findByAny(filter, optionsData)
  }

  findWithNegativeBudget (filter, options) {
    return api.user.findWithNegativeBudget(filter, options)
  }

  countWithNegativeBudget (filter, options) {
    return api.user.countWithNegativeBudget(filter, options)
  }

  getUser (ospId, options) {
    const optionsData = this.addWrapper(options)
    return api.user.getUser(ospId, optionsData)
  }

  createManager (userData, options) {
    const form = {
      sendCredentials: true,
      userData: {
        active: true,
        corporateAccount: {
          ospId: Auth.corporateAccountId()
        },
        ...userData
      }
    }
    return api.user.createAdminUser(form, options)
  }

  createInvoicing (userData, options) {
    const form = {
      sendCredentials: true,
      userData: {
        active: true,
        corporateAccount: {
          ospId: Auth.corporateAccountId()
        },
        ...userData
      }
    }
    return api.user.createContactUser(form, options)
  }

  async addRoles ({ roles, userOspId }, options) {
    let response = {}
    userOspId = { ospId: userOspId }
    for (const role of roles) {
      const form = {
        role,
        userOspId
      }
      response = await api.user.roleAdd(form, options)
      options = {}
    }
    return response
  }

  async removeRoles ({ roles, userOspId }, options) {
    let response = {}
    userOspId = { ospId: userOspId }
    for (const role of roles) {
      const form = {
        role,
        userOspId
      }
      response = await api.user.roleRemove(form, options)
      options = {}
    }
    return response
  }

  async addGroups ({ userGroups, userOspId }, options = {}) {
    const promises = []
    userGroups.forEach(ospId => {
      promises.push(api.userGroup.addUsers({
        usergroup: {
          ospId
        },
        users: [
          {
            ospId: userOspId
          }
        ]
      }, options))
      options = {}
    })
    return Promise.all(promises)
  }

  removeGroups ({ userGroups, userOspId }, options = {}) {
    const promises = []
    userGroups.forEach(ospId => {
      promises.push(api.userGroup.removeUsers({
        usergroup: {
          ospId
        },
        users: [
          {
            ospId: userOspId
          }
        ]
      }, options))
      options = {}
    })
    return Promise.all(promises)
  }
}

export default UserRepository
