<template>
  <div
    class="base-section"
    :class="{
      'base-section--transparent': isTransparent,
      'base-section--center': centerAlign,
      'base-section--with-border': withBorder,
      'base-section--flex': isFlex,
      'base-section--no-padding': noPadding,
      'base-section--column': directionColumn,
    }"
  >
    <div class="base-section__title" v-if="$refs.title || title">
      <slot name="title">{{title}}</slot>
    </div>
    <div class="base-section__content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionBox',
  props: {
    title: {
      type: String,
      default: null
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    centerAlign: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: true
    },
    isFlex: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    directionColumn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
