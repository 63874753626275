import Vue from 'vue'
import { get, values } from 'lodash'
import UserGroupRepository from '../../app/repositories/UserGroupRepository'
import { defaultCompanyUserGroup } from '@/app/helpers'

const userGroupRepository = new UserGroupRepository()

export default {
  namespaced: true,
  state: {
    all: {}
  },
  getters: {
    all: state => values(state.all),
    allActive: state => values(state.all).filter(v => v.isActive),
    allInactive: state => values(state.all).filter(v => !v.isActive),
    findByOspId: state => ospId => get(state.all, ospId, undefined),
    userGroupName: state => ospId => get(state.all, [ospId, 'data', 'extId'], ''),
    defaultCompanyUserGroup: state => companyExtId => values(state.all).find(v => v.data.extId.indexOf(defaultCompanyUserGroup(companyExtId)) > -1)
  },
  actions: {
    findAll ({ commit }, corporateAccountId) {
      return userGroupRepository.getAll({
        corporateAccount: {
          ospId: corporateAccountId
        }
      }, {
        onSuccess: response => {
          commit('SET_ALL_USER_GROUPS', response)
        }
      })
    },
    getByOspId ({ commit }, ospId) {
      return userGroupRepository.findByOspId(ospId, {
        onSuccess: response => {
          commit('SET_USER_GROUP', response)
        }
      })
    }
  },
  mutations: {
    SET_ALL_USER_GROUPS: (state, data) => {
      state.all = {}
      data.forEach(v => {
        Vue.set(state.all, v.ospId, v)
      })
    },
    SET_USER_GROUP: (state, data) => {
      Vue.set(state.all, data.ospId, data)
    }
  }
}
