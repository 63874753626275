import middleware from '../../app/services/MiddlewareService/middleware'
import Dashboard from '../../views/Dashboard'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: 'company/details',
    name: 'CompanyDetails',
    component: () =>
      import(
        /* webpackChunkName: "company-details" */ '@/views/CompanySettingsDetails.vue'
      ),
    beforeEnter: middleware('needSelectedCorporateAccount')
  },
  {
    path: 'finance/invoices',
    name: 'Invoices',
    component: () =>
      import(/* webpackChunkName: "finance" */ '@/views/Invoices.vue'),
    beforeEnter: middleware([
      `hasRole:${Role.INVOICE_MANAGER},${Role.USER_MANAGER}`,
      'needSelectedCorporateAccountOrAdmin'
    ])
  },
  {
    path: 'finance/company-budget',
    name: 'CompanyBudget',
    component: () =>
      import(/* webpackChunkName: "finance" */ '@/views/CompanyBudget.vue'),
    beforeEnter: middleware(
      'needSelectedCorporateAccountOrAdmin',
      'canViewCompanyBudget'
    )
  },
  {
    path: 'finance/all-transactions',
    name: 'CompanyAllTransactions',
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/views/CompanyAllTransactions.vue'
      ),
    beforeEnter: middleware('needSelectedCorporateAccountOrAdmin')
  },
  {
    path: 'finance/transaction-details/:eventOspId',
    name: 'transactionDetails',
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/views/CompanyTransactionDetails.vue'
      ),
    beforeEnter: middleware('needSelectedCorporateAccountOrAdmin')
  },
  {
    path: 'finance/company-private-budget',
    name: 'CompanyPrivateBudget',
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/views/CompanyPrivateBudget.vue'
      ),
    beforeEnter: middleware(
      `hasRole:${Role.OSPADMIN}`,
      'needSelectedCorporateAccountOrAdmin'
    )
  },
  {
    path: 'change-password',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ '@/views/ChangePassword.vue'
      )
  },

  {
    path: 'help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/NotFound.vue')
  },
  {
    path: 'finance/self-billing-invoices',
    name: 'SelfBillingInvoices',
    component: () =>
      import(
        /* webpackChunkName: "finance" */ '@/views/SelfBillingInvoices.vue'
      ),
    beforeEnter: middleware([
      `hasRole:${Role.INVOICE_MANAGER}`,
      'needSelectedCorporateAccountOrAdmin'
    ])
  }
]
