import Auth from '../services/AuthService/Auth'

export default function mspOnly (to, from, next) {
  // console.log('mspOnly')
  // console.log(Auth.user())
  if (Auth.user().isMsp) {
    next()
  } else {
    next('/')
  }
}
