import Auth from '../services/AuthService/Auth'

export default function guest (to, from, next) {
  // console.log('middleware guest')
  if (!Auth.token()) {
    return next()
  } else {
    next('/dashboard')
  }
}
