import Vue from 'vue'
import api from '@/api'
import { orderBy, get, upperFirst } from 'lodash'

export default {
  namespaced: true,
  state: {
    filterFormState: {
      templateType: null,
      templateNameLike: null,
      ownerNameLike: null,
      language: null
    },
    categories: [],
    owners: [],
    externalTemplates: [],
    contentTypes: [],
    templateTypes: [],
    predefinedTemplateNames: {}
  },
  getters: {
    getFilterFormState: state => state.filterFormState,
    categories: state => state.categories,
    categoriesList: state => state.categories.map(cat => ({
      value: cat,
      label: upperFirst(cat.toLowerCase())
    })),
    externalTemplates: state => state.externalTemplates,
    contentTypes: state => state.contentTypes,
    templateTypes: state => state.templateTypes,
    predefinedTemplateNames: state => category => get(state.predefinedTemplateNames, category, []),
    owners: state => state.owners
  },
  actions: {
    fetchCategories ({ commit }) {
      return api.template.getCategories({
        onSuccess: res => {
          commit('SET_CATEGORIES', res.sort())
        }
      })
    },
    fetchOwners ({ commit }) {
      return api.template.getOwners({
        onSuccess: res => {
          commit('SET_OWNERS', res)
        }
      })
    },
    fetchExternalTemplates ({ commit }) {
      return api.template.getExternalTemplates({
        onSuccess: res => {
          commit('SET_EXTERNAL_TEMPLATES', res)
        }
      })
    },
    fetchContentTypes ({ commit }) {
      return api.template.getContentTypes({
        onSuccess: res => {
          commit('SET_CONTENT_TYPES', res)
        }
      })
    },
    fetchTemplateTypes ({ commit }) {
      return api.template.getTemplateTypes({
        onSuccess: res => {
          commit('SET_TEMPLATE_TYPES', res)
        }
      })
    },
    fetchPredefinedTemplateNames ({ commit }, category) {
      return api.template.getPredefinedTemplateNames(category, {
        onSuccess: data => {
          commit('SET_PREDEFINED_TEMPLATE_NAMES', { category, data })
        }
      })
    },
    setFilterFormState ({ commit }, data) {
      commit('SET_FILTER_FORM_STATE', data)
    }
  },
  mutations: {
    SET_CATEGORIES: (state, categories) => {
      state.categories = categories
    },
    SET_OWNERS: (state, owners) => {
      state.owners = orderBy(owners, 'ownerName')
    },
    SET_EXTERNAL_TEMPLATES: (state, templates) => {
      state.externalTemplates = templates
    },
    SET_CONTENT_TYPES: (state, types) => {
      state.contentTypes = types
    },
    SET_TEMPLATE_TYPES: (state, types) => {
      state.templateTypes = types
    },
    SET_PREDEFINED_TEMPLATE_NAMES: (state, { category, data }) => {
      Vue.set(state.predefinedTemplateNames, category, data)
    },
    SET_FILTER_FORM_STATE: (state, data) => {
      state.filterFormState = data
    }
  }
}
