import api from '@/api'
import { get, isEmpty } from 'lodash'
import Auth from '../../app/services/AuthService/Auth'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

const defaultStore = () => {
  return {
    userCorporateAccount: {
      allowedBudgetTypes: [],
      allowedProducts: [],
      allowedServiceResources: [],
      corporateAccount: {},
      selectedBudgetTypes: [],
      selectedProducts: [],
      selectedServiceResources: []
    },
    travelCompensation: {},
    selectedCorporateAccount: {},
    companyTypeList: []
  }
}

export default {
  namespaced: true,
  state: defaultStore(),
  getters: {
    name: state => get(state.userCorporateAccount, ['corporateAccount', 'data', 'name'], ''),
    selectedCorporateName: state => get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'name'], ''),
    allowedBudgetTypes: state => state.userCorporateAccount.allowedBudgetTypes,
    corporatePaymentType: state => get(state.userCorporateAccount, ['corporateAccount', 'data', 'paymentType'], ''),
    selectedCorporatePaymentType: state => get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'paymentType'], ''),
    travelCompensation: state => state.travelCompensation,
    corporateAccount: state => get(state.userCorporateAccount, ['corporateAccount', 'data'], {}),
    // TODO:debug this getters,they are return an undefined
    selectedCorporateAccountOspId: state => Auth.user().isMsp ? state.selectedCorporateAccount.ospId : state.userCorporateAccount.ospId,
    selectedCorporateAccount: state => Auth.user().isMsp && !isEmpty(state.selectedCorporateAccount) ? state.selectedCorporateAccount : state.userCorporateAccount,
    //* ** */
    userCorporateAccount: state => state.userCorporateAccount,
    selectedAccountCorporate: state => state.selectedCorporateAccount.corporateAccount,
    userPartnerOspId: state => get(state.userCorporateAccount, ['corporateAccount', 'data', 'partner', 'ospId'], ''),
    selectedPartnerOspId: state => get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'partner', 'ospId'], ''),
    address: state => {
      return {
        streetName: get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'street'], ''),
        streetNumber: get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'streetNumber'], ''),
        zipCode: get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'zipCode'], ''),
        city: get(state.selectedCorporateAccount, ['corporateAccount', 'data', 'city'], '')
      }
    },
    companyTypeList: state => state.companyTypeList
  },
  actions: {
    getData ({ commit, dispatch }) {
      return corporateAccountRepository.getData(Auth.corporateAccountId(), {
        onSuccess: res => {
          // console.log(res)
          commit('SET_CORPORATE_ACCOUNT', res)
          if (!Auth.user().isMsp) {
            commit('SET_SELECTED_CORPORATE_ACCOUNT', res)
          }
          return dispatch('partner/fetchUserPartner', res.partnerOspId, { root: true })
        }
      })
    },
    updateCorporateAccount ({ commit, dispatch }, form) {
      return api.corporateAccount.update(form, {
        onSuccess: () => {
          dispatch('getData')
        }
      })
    },
    selectCorporateAccountOspId ({ commit, dispatch }, ospId) {
      return corporateAccountRepository.getData(ospId, {
        onSuccess: r => {
          commit('SET_SELECTED_CORPORATE_ACCOUNT', r)
          return dispatch('userGroup/findAll', ospId, { root: true })
        }
      })
    },
    getCompanyTypeList ({ commit }) {
      return api.corporateAccount.getCompanyTypeList({
        onSuccess: res => {
          commit('SET_COMPANY_TYPE_LIST', res)
        }
      })
    },
    resetStore ({ commit }) {
      commit('RESET_STATE', defaultStore())
    }
  },
  mutations: {
    SET_CORPORATE_ACCOUNT: (state, data) => {
      state.userCorporateAccount = data
    },
    SET_TRAVEL_COMPENSATION: (state, data) => {
      state.travelCompensation = data
    },
    SET_SELECTED_CORPORATE_ACCOUNT: (state, data) => {
      state.selectedCorporateAccount = data
    },
    SET_COMPANY_TYPE_LIST: (state, data) => {
      state.companyTypeList = data
    },
    RESET_STATE: (state, data) => {
      Object.assign(state, data)
    }
  }
}
