import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getOpenOrders: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/seasonTicket/order/getOpen`, filter),
  confirmOrder: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/seasonTicket/order/confirm`, filter),
  cancelOrder: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/seasonTicket/order/cancel`, filter),
  getHandledOrders: (filter, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/seasonTicket/order/getHandled`, filter)
}
