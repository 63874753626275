import api from '@/api'
import Reconciliation from '@/app/models/Reconciliation'
export default {
  namespaced: true,
  state: {
    reconciliations: []
  },
  getters: {
    getReconciliations: state => state.reconciliations
  },
  actions: {
    getReconciliationsProblems ({ commit }, data) {
      return api.reconciliations.getReconciliations({
        onSuccess: res => {
          res.content = res.content.map(reconsiliation => new Reconciliation(reconsiliation))
          commit('SET_RECONCILIATIONS', res)
        }
      }, data)
    },
    manualOverride ({ commit }, form) {
      return api.reconciliations.manualOverride(form)
    },
    clearState ({ commit }) {
      commit('CLEAR_STATE')
    }
  },
  mutations: {
    SET_RECONCILIATIONS: (state, data) => {
      state.reconciliations = data
    },
    CLEAR_STATE: (state) => {
      state.reconciliations = []
    }
  }
}
