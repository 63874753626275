import { get } from 'lodash'
import Vue from 'vue'
import api from '@/api'

export default {
  namespaced: true,
  state: {
    serviceResourcesImages: {}
  },
  getters: {
    serviceResourcesImage: state => ospId => get(state.serviceResourcesImages, ospId)
  },
  actions: {
    getServiceResourceImage ({ commit }, serviceResourceOspId) {
      return api.app.imageIcon(serviceResourceOspId, {
        onSuccess: response => {
          const image = Buffer.from(response.data, 'binary').toString('base64')
          commit('SET_SERVICE_RESOURCE_IMAGE', { serviceResourceOspId, image })
        },
        onError: () => {
          commit('SET_SERVICE_RESOURCE_IMAGE', { serviceResourceOspId, image: null })
        }
      })
    }
  },
  mutations: {
    SET_SERVICE_RESOURCE_IMAGE: (state, { serviceResourceOspId, image }) => {
      Vue.set(state.serviceResourcesImages, serviceResourceOspId, 'data:image/png;base64,' + image)
    }
  }
}
