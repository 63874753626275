var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-section",class:{
    'base-section--transparent': _vm.isTransparent,
    'base-section--center': _vm.centerAlign,
    'base-section--with-border': _vm.withBorder,
    'base-section--flex': _vm.isFlex,
    'base-section--no-padding': _vm.noPadding,
    'base-section--column': _vm.directionColumn,
  }},[(_vm.$refs.title || _vm.title)?_c('div',{staticClass:"base-section__title"},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2):_vm._e(),_c('div',{staticClass:"base-section__content"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }