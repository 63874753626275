import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  types: options => api(options).get(`${PORTAL_SERVICE_V1}/recurringExternalPurchase/types`),
  get: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/recurringExternalPurchase/get`, form),
  create: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/recurringExternalPurchase/create`, data),
  update: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/recurringExternalPurchase/update`, data),
  delete: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/recurringExternalPurchase/delete`, data),
  findByUser: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/recurringExternalPurchase/findByUser`, { ospId })
}
