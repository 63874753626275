import CountryTranslations from '@/app/services/CountriesService/CountryTranslations'
import { sortBy } from 'lodash'

const enCountries = require('./en.json')

class CountriesService {
  constructor () {
    this.initialize()
  }

  initialize () {
    this.countries = enCountries.map((v) => {
      return new CountryTranslations(v)
    })
  }

  getList (query = null) {
    const countries = sortBy(this.countries, ['isSecondary', 'label'])
    return query ? countries.filter(v => v.hasLetters(query)) : countries
  }

  search (query) {
    return this.countries.filter(v => v.hasLetters(query))
  }
}

const instance = new CountriesService()

export const countries = () => instance
