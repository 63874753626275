import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {

  // Creates a user fee configuration record and returns the new user fee.
  // form for Create request
  // {
  //   "assigneeOspId": "string",
  //   "eventCountThresholdFrom": 0,
  //   "eventCountThresholdTo": 0,
  //   "feeAmount": 0,
  //   "vatPercentage": 0
  // }

  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/userFee/create`, form),

  // Delete the user fee record
  // form for Delete request
  // {
  //   "assigneeOspId": "string",
  //   "eventCountThresholdFrom": 0,
  //   "eventCountThresholdTo": 0,
  //   "feeAmount": 0,
  //   "vatPercentage": 0
  // }
  delete: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/userFee/delete`, form),

  // Returns all user fee configuration records
  // no parameters
  getAll: (options) => api(options).post(`${PORTAL_SERVICE_V1}/userFee/getAll`),

  // Updates a user fee record and returns the updated user fee.
  // form for update request
  // {
  //   "assigneeOspId": "string",
  //   "eventCountThresholdFrom": 0,
  //   "eventCountThresholdTo": 0,
  //   "feeAmount": 0,
  //   "vatPercentage": 0
  // }
  update: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/userFee/update`, form)
}
