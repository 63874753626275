import ElAlert from 'element-ui/packages/alert/src/main.vue'

const TYPE_CLASSES_MAP = {
  success: 'el-icon-success',
  warning: 'ri-alert-line',
  error: 'el-icon-error'
}

export default {
  name: 'BaseRadio',
  mixins: [ElAlert],
  computed: {
    iconClass () {
      return TYPE_CLASSES_MAP[this.type] || 'el-icon-info'
    }
  }
}
