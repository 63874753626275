import api from '@/api'
import Repository from './Repository'
import UserGroup from '../models/UserGroup'

class UserGroupRepository extends Repository {
  constructor () {
    super(UserGroup)
  }

  getAll (filter, options) {
    const optionsData = this.addWrapper(options)
    return api.userGroup.findAll(filter, optionsData)
  }

  findByOspId (ospId, options) {
    const optionsData = this.addWrapper(options)
    return api.userGroup.getByOspId(ospId, optionsData)
  }

  getByUser (ospId, options) {
    const optionsData = this.addWrapper(options)
    return api.userGroup.getByUser(ospId, optionsData)
  }

  create (form, options) {
    return api.userGroup.create(form, options)
  }

  update (form, options) {
    return api.userGroup.update(form, options)
  }

  addUsers (form, options) {
    return api.userGroup.addUsers(form, options)
  }

  removeUsers (form, options) {
    return api.userGroup.removeUsers(form, options)
  }
}

export default UserGroupRepository
