import { allowViewCompanyBudget } from '@/app/helpers'

export default function canViewCompanyBudget (to, from, next) {
  if (allowViewCompanyBudget()) {
    console.log('ViewCompanyBudget | allowed')
    return next()
  }
  console.log('ViewCompanyBudget | forbidden')
  return next('404')
}
