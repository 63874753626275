import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  findAll: (data, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/findAll`, data),
  getByOspId: (ospId, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/getByOspId`, { ospId }),
  getByUser: (ospId, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/getByUser`, { ospId }),
  create: (userGroup, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/create`, userGroup),
  update: (userGroup, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/update`, userGroup),
  delete: (ospId, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/delete`, { ospId }),
  activate: (ospId, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/activate`, { ospId }),
  addUsers: (form, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/addUsers`, form),
  removeUsers: (form, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/usergroup/removeUsers`, form),

  serviceResources: (form, options = {}) => api(options).post(`${PORTAL_SERVICE_V1}/permissions/usage/usergroup/serviceResources`, form)
}
