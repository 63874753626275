var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form-item',{staticClass:"base-currency-input",class:{
  'has-error': _vm.error || _vm.validationsError,
  'has-message': _vm.validationsError || _vm.error || _vm.success,
  'disabled': _vm.disabled
  },attrs:{"label":_vm.label && (_vm.label + (_vm.required ? '*' : '')),"prop":_vm.prop,"disabled":_vm.disabled,"label-position":"top"}},[_vm._t("default"),_c('span',{staticClass:"el-input__wrapper",class:{
    'el-input__wrapper--with-suffix': !!_vm.suffix,
    'el-input__wrapper--with-value': !!_vm.model,
    'el-input__wrapper--disabled': !!_vm.disabled
  },attrs:{"data-suffix":_vm.suffix}},[_c('currency-input',{ref:"input",staticClass:"el-input__inner",style:({'max-width': _vm.maxWidth ? `${_vm.maxWidth}px` : null}),attrs:{"allow-negative":_vm.allowNegative,"name":_vm.name,"disabled":_vm.disabled || _vm.readonly,"placeholder":_vm.placeholder,"currency":_vm.currency,"precision":_vm.precision,"readonly":_vm.readonly,"auto-complete":"off"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.$emit('keyupEnter')}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),(_vm.validationsError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t(`validations.${_vm.validationsError}.${_vm.label ? 'with_label' : 'without_label'}`, { label: _vm.label }))+" ")]):(_vm.error)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.success)?_c('p',{staticClass:"success"},[_vm._v(" "+_vm._s(_vm.success)+" ")]):_vm._e(),(_vm.info)?_c('p',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.info)+" "),(_vm.extraInfo)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.extraInfo)}}):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }