import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getSeasonTicketConfiguration: (corporateAccountOspId, options) => api(options).post(`${PORTAL_SERVICE_V1}/nmbs/seasonTicket/configuration/get`, { corporateAccountOspId }),
  updateSeasonTicketConfiguration: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/nmbs/seasonTicket/configuration/update`, data),

  getTicketSalesConfiguration: (assigneeOspId, options) => api(options).post(`${PORTAL_SERVICE_V1}/nmbs/ticketSales/configuration/get`, { assigneeOspId }),
  updateTicketSalesConfiguration: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/nmbs/ticketSales/configuration/update`, data),

  uploadInvoice: (form, options) => api({
    ...options,
    responseType: 'text'
  }).post(`${PORTAL_SERVICE_V1}/nmbs/upload/invoice/upload`, form),
  uploadInvoiceProcess: (uploadId, options) => api(options).post(`${PORTAL_SERVICE_V1}/nmbs/upload/invoice/process`, { uploadId }),
  uploadInvoiceValidate: (uploadId, options) => api(options).post(`${PORTAL_SERVICE_V1}/nmbs/upload/invoice/validate`, { uploadId })
}
