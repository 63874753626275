import Vue from 'vue'
import i18n from '../../i18n.js'
import { getUser } from '@/app/helpers/auth'
import { get, values } from 'lodash'
import api from '@/api'
import router from '@/router'
import apiService from '@/app/services/ApiService/Api'

const getDefaultState = () => {
  return {
    user: getUser()
  }
}

const getDefaultAppUserForm = () => {
  return {
    showExtra: false,
    personalForm: {
      firstname: null,
      lastname: null,
      email: null,
      msisdn: null,
      birthdate: null,
      language: 'nl'
    },
    accountForm: {
      login: null,
      desiredActivationDate: null,
      desiredDeactivationDate: null
    },
    budgetTypes: [],
    extraForm: {
      employeeNumber: null,
      contractId: null
    },
    workAddressForm: {
      workStreetName: null,
      workStreetNumber: null,
      workZipCode: null,
      workCity: null
    },
    homeAddressForm: {
      homeStreetName: null,
      homeStreetNumber: null,
      homeZipCode: null,
      homeCity: null
    },
    userBudgets: {},
    userGroups: []
  }
}

export default {
  namespaced: true,
  state: {
    ...getDefaultState(),
    appUserForm: getDefaultAppUserForm(),
    employeeNames: {},
    contactTypes: [],
    roles: []
  },
  getters: {
    user: state => state.user,
    ospId: state => get(state.user, ['osp.user', 'ospId']),
    email: state => get(state.user, ['osp.user', 'data', 'email']),
    corporateAccountOspId: state => get(state.user, ['osp.user', 'data', 'corporateAccount', 'ospId']),
    appUserForm: state => state.appUserForm,
    fullName: state =>
      get(state.user, ['osp.user', 'data', 'firstname'], '') +
      ' ' +
      get(state.user, ['osp.user', 'data', 'lastname'], ''),
    employeeName: state => ospId => get(state.employeeNames, ospId, ''),
    language: state => get(state.user, ['osp.user', 'data', 'language']),
    contactTypes: state => state.contactTypes,
    roles: state => state.roles,
    findContactTypes: state => contactTypes =>
      state.contactTypes.filter(item => contactTypes.includes(item.contactType)),
    findRoles: state => roles => state.roles.filter(item => roles.includes(item.role))
  },
  actions: {
    createAppUserAndSendCredentials ({ commit, state, rootGetters }, options = {}) {
      let extraUserData = {}
      const checkForm = () => {
        return (
          values(state.appUserForm.extraForm).some(field => field !== null) ||
          values(state.appUserForm.workAddressForm).some(field => field !== null) ||
          values(state.appUserForm.homeAddressForm).some(field => field !== null)
        )
      }
      if (checkForm()) {
        const extraFormData = state.appUserForm.extraForm.employeeNumber
          ? state.appUserForm.extraForm
          : { contractId: state.appUserForm.extraForm.contractId }

        extraUserData = {
          ...extraFormData,
          ...state.appUserForm.homeAddressForm,
          ...state.appUserForm.workAddressForm,
          extId: state.appUserForm.extraForm.extId
        }
      }
      const userGroups = state.appUserForm.userGroups.map(v => {
        return {
          ospId: v
        }
      })
      const corporateAccount = {
        ospId: rootGetters['corporateAccount/selectedCorporateAccountOspId']
      }
      const form = {
        corporateAccount,
        userBudgets: state.appUserForm.budgetTypes.map(type => state.appUserForm.userBudgets[type].getCreateForm()),
        userData: {
          corporateAccount,
          failedLoginCount: 0,
          roles: ['ospuser'],
          ...state.appUserForm.personalForm,
          ...extraUserData,
          ...state.appUserForm.accountForm
        },
        userGroups: {
          userGroups
        },
        mobibCardNumber: null,
        mobibChipId: null,
        mobibValidFrom: null,
        sendCredentials: true
      }
      return api.user.createMobileUser(form, {
        ...options,
        onSuccess: () => {
          options.onSuccess && options.onSuccess()
          apiService.pushMessage(
            i18n.t('notification.app_user_created', {
              user_name: `${form.userData.firstname} ${form.userData.lastname}`
            })
          )
        }
      })
    },
    changeLanguage ({ dispatch }, lang) {
      return api.user.changeLanguage(lang, {
        onSuccess: () => {
          dispatch('auth/refreshToken', null, { root: true })
        }
      })
    },
    refreshUser ({ commit }) {
      commit('SET_USER', getUser())
      return true
    },
    resetAppUserForm ({ commit }) {
      commit('SET_APP_USER_FORM', getDefaultAppUserForm())
    },
    setAppUserForm ({ commit }, form) {
      commit('SET_APP_USER_FORM', form)
    },
    getEmployeeName ({ commit }, ospId) {
      return api.user.getUser(ospId, {
        onSuccess: response => {
          commit('SET_EMPLOYEE_NAME', response)
        }
      })
    },
    setEmployeeName ({ commit }, data) {
      commit('SET_EMPLOYEE_NAME', data)
    },
    getRolesList ({ commit }) {
      return api.user.roleListAll({
        onSuccess: response => {
          commit('SET_ROLES_LIST', response)
        }
      })
    },
    getContactTypes ({ commit }) {
      return api.user.contactTypeList({
        onSuccess: response => {
          commit('SET_CONTACT_TYPES', response)
        }
      })
    },
    processUploading ({ commit }, data) {
      return api.user.processUploading(data, {
        successNotification: {
          title: i18n.t('user_management.import_users_page.success_notification.title'),
          message: i18n.t('user_management.import_users_page.success_notification.message')
        },
        onSuccess: () => {
          router.push({ name: 'ManageAppUsers' })
        }
      })
    }
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_APP_USER_FORM: (state, form) => {
      state.appUserForm = form
    },
    SET_EMPLOYEE_NAME: (state, { ospId, userData }) => {
      // console.log(ospId)
      // console.log(userData)
      const name = get(userData, 'firstname') + ' ' + get(userData, 'lastname')
      Vue.set(state.employeeNames, ospId, name.trim())
    },
    SET_CONTACT_TYPES: (state, list) => {
      state.contactTypes = list
    },
    SET_ROLES_LIST: (state, list) => {
      state.roles = list
    }
  }
}
