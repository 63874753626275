<template>
  <div class="base-transfer-panel">
    <p v-if="topTitle" class="base-transfer-panel__description">
      {{topTitle}}
    </p>
    <div class="el-transfer-panel">
      <p class="el-transfer-panel__header">
        <el-checkbox
          v-model="allChecked"
          @change="handleAllCheckedChange"
          :indeterminate="isIndeterminate">
          {{ title }}
          <span>{{ checkedSummary }}</span>
        </el-checkbox>
      </p>

      <div :class="['el-transfer-panel__body', hasFooter ? 'is-with-footer' : '']">
        <el-input
          class="el-transfer-panel__filter"
          v-model="query"
          size="small"
          :placeholder="placeholder"
          @mouseenter.native="inputHover = true"
          @mouseleave.native="inputHover = false"
          v-if="filterable">
          <i slot="prefix"
             :class="['el-input__icon', 'el-icon-' + inputIcon]"
             @click="clearQuery"
          ></i>
        </el-input>
        <el-checkbox-group
          ref="panelList"
          :key="query"
          v-loading="panelLoading"
          v-model="checked"
          v-show="!hasNoMatch && data.length > 0"
          :class="{ 'is-filterable': filterable }"
          class="el-transfer-panel__list">
          <el-checkbox
            class="el-transfer-panel__item"
            :label="item[keyProp]"
            :disabled="item[disabledProp]"
            :key="item[keyProp]"
            v-for="item in filteredData">
            <option-content :option="item"></option-content>
          </el-checkbox>
        </el-checkbox-group>
        <p
          class="el-transfer-panel__empty"
          v-show="hasNoMatch">{{ $t('general.empty_table') }}</p>
        <p
          class="el-transfer-panel__empty"
          v-show="data.length === 0 && !hasNoMatch">{{ $t('general.empty_table') }}</p>
      </div>
      <p class="el-transfer-panel__footer" v-if="hasFooter">
        <slot></slot>
      </p>
    </div>
    <div class="base-transfer-panel__description" v-if="pagination">
      <base-pagination
        :pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
import TransferPanel from 'element-ui/packages/transfer/src/transfer-panel.vue'
import { debounce } from 'lodash'

export default {
  mixins: [TransferPanel],
  name: 'BaseTransferPanel',
  props: {
    topTitle: {
      type: String
    },
    panelLoading: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Object,
      default: null
    }
  },
  watch: {
    query: debounce(function () {
      if (this.pagination) {
        this.pagination.fresh()
      }
    }, 700)
  }
}
</script>
