import Vue from 'vue'
import { get } from 'lodash'
import PartnerRepository from '@/app/repositories/PartnerRepository'

const partnerRepository = new PartnerRepository()

export default {
  namespaced: true,
  state: {
    partnerTypes: ['MSP', 'RESELLER', 'ARTEMIS', 'MRP', 'MRP_MSP', 'RESELLER_B2B'],
    partners: {},
    allPartners: [],
    allMrpTypes: [],
    userPartner: {}
  },
  getters: {
    partnerTypes: state => state.partnerTypes,
    allPartners: state => state.allPartners,
    getByOspId: state => ospId => get(state.partners, ospId, undefined),
    allMrpTypes: state => state.allMrpTypes,
    userPartner: state => state.userPartner
  },
  actions: {
    fetchAllActivePartners ({ commit }) {
      return partnerRepository.getAll({ active: true }, {
        onSuccess: response => {
          commit('SET_ALL_ACTIVE_PARTNER', response)
        }
      })
    },
    fetchByOspId ({ commit }, ospId) {
      return partnerRepository.get(ospId, {
        onSuccess: response => {
          commit('SET_PARTNER', response)
        }
      })
    },
    fetchAllMrpTypes ({ commit }) {
      return partnerRepository.getAllMrp({ active: true }, {
        onSuccess: response => {
          commit('SET_ALL_MRP', response)
        }
      })
    },
    fetchUserPartner ({ commit }, ospId) {
      return partnerRepository.get(ospId, {
        onSuccess: response => {
          commit('SET_USER_PARTNER', response)
        }
      })
    }
  },
  mutations: {
    SET_USER_PARTNER: (state, data) => {
      state.userPartner = data
    },
    SET_PARTNER: (state, data) => {
      Vue.set(state.partners, data.partner.ospId, data)
    },
    SET_ALL_ACTIVE_PARTNER: (state, data) => {
      state.allPartners = data
    },
    SET_ALL_MRP: (state, data) => {
      state.allMrpTypes = data
    }
  }
}
