<template>
  <div class="el-transfer-panel">
    <p class="el-transfer-panel__header">
      <el-checkbox
        v-model="allChecked"
        @change="handleAllCheckedChange"
        :indeterminate="isIndeterminate"
      >
        {{ $t('general.label.select_all') }}
        <span>{{ checkedSummary }}</span>
      </el-checkbox>
    </p>

    <div class="el-transfer-panel__body">
      <el-input
        v-if="filterable"
        class="el-transfer-panel__filter"
        v-model="query"
        size="small"
        :placeholder="$t('general.search')"
        @mouseenter.native="inputHover = true"
        @mouseleave.native="inputHover = false"
      >
        <i
          slot="prefix"
          :class="['el-input__icon', 'el-icon-' + inputIcon]"
          @click="clearQuery"
        ></i>
      </el-input>

      <el-checkbox-group
        v-model="checked"
        :class="{ 'is-filterable': filterable }"
        class="el-transfer-panel__list"
      >
        <el-checkbox
          v-for="item in filteredData"
          class="el-transfer-panel__item"
          :label="item.key"
          :key="item.key"
        >
          <span>{{ item.label }}</span>
        </el-checkbox>
      </el-checkbox-group>
      <p class="el-transfer-panel__empty" v-show="hasNoMatch">{{ $t('general.empty_table') }}</p>
      <p class="el-transfer-panel__empty" v-show="data.length === 0 && !hasNoMatch">
        {{ $t('general.empty_table') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckboxGroup',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    filterable: {
      type: Boolean,
      default: false
    },
    defaultChecked: {
      type: Array,
      default: () => []
    },
    form: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      allChecked: false,
      checked: [],
      query: '',
      inputHover: false,
      checkChangeByUser: true
    }
  },
  computed: {
    filteredData () {
      return this.data.filter(item => {
        return this.filterMethod(this.query, item)
      })
    },
    isIndeterminate () {
      const checkedLength = this.checked.length
      return checkedLength > 0 && checkedLength < this.filteredData.length
    },
    checkedSummary () {
      const checkedLength = this.checked.length
      const dataLength = this.data.length
      return `${checkedLength}/${dataLength}`
    },
    inputIcon () {
      return this.query.length > 0 && this.inputHover ? 'circle-close' : 'search'
    },
    hasNoMatch () {
      return this.query.length > 0 && this.filteredData.length === 0
    }
  },
  methods: {
    updateAllChecked () {
      const checkableDataKeys = this.filteredData.map(item => item.key)
      this.allChecked =
        checkableDataKeys.length > 0 &&
        checkableDataKeys.every(item => this.checked.indexOf(item) > -1)
    },
    handleAllCheckedChange (value) {
      this.checked = value ? this.filteredData.map(item => item.key) : []
    },
    clearQuery () {
      if (this.inputIcon === 'circle-close') {
        this.query = ''
      }
    },
    filterMethod (query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    }
  },
  watch: {
    checked (val, prevVal) {
      this.updateAllChecked()
      if (val.length !== prevVal.length) {
        this.$emit('update:form', [...val])
      }
    },
    data () {
      const checked = []
      const filteredDataKeys = this.filteredData.map(item => item.key)
      this.checked.forEach(item => {
        if (filteredDataKeys.indexOf(item) > -1) {
          checked.push(item)
        }
      })
      this.checkChangeByUser = false
      this.checked = checked
    },
    filteredData () {
      this.updateAllChecked()
    },
    defaultChecked: {
      immediate: true,
      handler (val, oldVal) {
        if (
          oldVal &&
          val.length === oldVal.length &&
          val.every(item => oldVal.indexOf(item) > -1)
        ) {
          return false
        }
        const checked = []
        const checkableDataKeys = this.filteredData.map(item => item.key)
        val.forEach(item => {
          if (checkableDataKeys.indexOf(item) > -1) {
            checked.push(item)
          }
        })
        this.checkChangeByUser = false
        this.checked = checked
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.el-transfer-panel {
  border-color: $gray-200;
  min-width: 300px;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .el-transfer-panel__header {
    border-bottom-color: $gray-200;
    background: none;
    :deep(.el-checkbox__label) {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .el-transfer-panel__body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: auto;
    .el-transfer-panel__list.is-filterable {
      height: 243px;
    }
    :deep(.el-transfer-panel__filter) {
      border-radius: 0;
      margin: 0 0 15px;
      .el-input__prefix {
        font-size: 16px;
      }
      .el-input__inner {
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        padding-left: 34px;
        &:focus {
          outline: none;
          border-color: $gray-200;
          & + .el-input__prefix {
            color: var(--brand-color);
          }
        }
      }
    }
  }
}
</style>
