<template>
  <base-section v-loading="loading" :withBorder="false" class="corporate-accounts" :key="key">
    <el-form
      ref="form"
      :model="form"
      autocomplete="off"
      id="timeline"
      @submit.prevent.native="null"
    >
      <el-row type="flex" justify="space-between" align="center" class="corporate-accounts--header">
        <p class="title">{{ $t('dashboard.corporate-accounts.title') }}</p>
        <base-datepicker
          v-model="date"
          type="month"
          valueFormat="yyyyMM"
          format="MMMM yyyy"
          :maxWidth="300"
          :clearable="false"
          :picker-options="dateOptions"
          style="margin-right: 0"
        >
        </base-datepicker>
      </el-row>
    </el-form>

    <base-section class="corporate-accounts--table-section">
      <el-table
        ref="table"
        :data="paginatedTableData"
        :default-sort="sort"
        :empty-text="$t('general.empty_table')"
        row-class-name="table-row"
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column
          class-name="text-bold"
          prop="name"
          :label="$t('general.label.name')"
          min-width="100"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="users_count"
          :label="$t('general.label.users')"
          min-width="30"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          class-name="text-bold"
          prop="expenses_amount"
          :label="$t('dashboard.timeline.history_select.options.amount_of_expenses')"
          min-width="50"
          sortable="custom"
        >
          <template slot-scope="{ row }">
            {{ $currency(row.expenses_amount) }}
          </template>
        </el-table-column>
      </el-table>
      <base-pagination v-model="page" :pages="pages" style="height: auto"></base-pagination>
    </base-section>
  </base-section>
</template>

<script>
import { orderBy, sortBy } from 'lodash'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

export default {
  name: 'CorporateAccounts',
  props: {
    partnerOspId: {
      type: String
    },
    period: {
      type: Array
    },
    companyType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tableData: [],
      date: this.$date.local().toFormat('yyyyMM'),
      page: 0,
      sort: { order: 'ascending', prop: 'name' },
      size: 5,
      key: '',
      loading: false,
      form: {},
      corporateAccounts: [],
      countUsers: [],
      sumEvents: []
    }
  },
  computed: {
    pages () {
      const pages = Math.ceil(this.tableDataFiltered.length / this.size)
      return pages === 0 ? 1 : pages
    },
    paginatedTableData () {
      const prop = this.sort.prop
      const order = this.sort.order === 'descending' ? 'desc' : 'asc'
      return orderBy(
        this.tableDataFiltered,
        v => {
          try {
            return v[prop].toLowerCase()
          } catch (e) {
            return v[prop]
          }
        },
        order
      ).slice(this.page * this.size, (this.page + 1) * this.size)
    },
    dateOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().endOf('day').toJSDate()
        }
      }
    },
    usersCountList () {
      const data = {}
      this.countUsers.forEach(({ mspExtId, corporateAccountExtId, count }) => {
        const ospId = `${mspExtId}.CORPORATE_ACCOUNT.${corporateAccountExtId}`
        data[ospId] = data[ospId] || 0
        data[ospId] += count
      })
      return data
    },
    eventsList () {
      const data = {}
      this.sumEvents.forEach(({ corporateAccountExtId, mspExtId, totalPriceVatIncl }) => {
        const ospId = `${mspExtId}.CORPORATE_ACCOUNT.${corporateAccountExtId}`
        data[ospId] = data[ospId] || 0
        data[ospId] += totalPriceVatIncl * 100
      })
      return data
    },
    tableDataFiltered () {
      if (this.companyType) {
        return this.tableData.filter(el => el.companyType === this.companyType)
      }
      return this.tableData
    }
  },
  watch: {
    date: {
      deep: true,
      handler () {
        this.key = this.$key()
        this.fetchData()
      }
    },
    partnerOspId: {
      handler () {
        this.fetchCorporateAccounts()
        this.key = this.$key()
      }
    },
    companyType: {
      handler () {
        this.page = 0
      }
    }
  },
  methods: {
    async fetchCorporateAccounts () {
      const filter = {
        active: true,
        pagination: {
          sort: {
            sortOrders: [
              {
                direction: 'ASCENDING',
                property: 'name'
              }
            ]
          }
        },
        partnerOspId: this.partnerOspId
      }

      const content = await corporateAccountRepository.getAllByFilter(filter, [
        'name',
        'ospId',
        'companyType'
      ])
      this.corporateAccounts = sortBy(content, o => o.name.toLowerCase())
      return this.fetchData()
    },
    async fetchData () {
      this.loading = true
      try {
        await Promise.all([this.fetchCountActiveUsers(), this.fetchSumEvents()])
      } catch (e) {
        console.error(e)
      }
      this.setTableData()
      this.loading = false
    },
    fetchCountActiveUsers () {
      const form = {
        monthFrom: this.date,
        monthTo: this.date,
        parentOspId: this.partnerOspId
      }
      return this.$api.reporting.countUsers(form, {
        onSuccess: response => {
          this.countUsers = response
        }
      })
    },
    fetchSumEvents () {
      const form = {
        monthFrom: this.date,
        monthTo: this.date,
        parentOspId: this.partnerOspId
      }
      return this.$api.reporting.sumEvents(form, {
        onSuccess: response => {
          this.sumEvents = response
        }
      })
    },
    setTableData () {
      this.tableData = this.corporateAccounts.map(ca => {
        return {
          ospId: ca.ospId,
          name: ca.name,
          users_count: this.usersCountList[ca.ospId] || 0,
          expenses_amount: (this.eventsList[ca.ospId] || 0) / 100,
          companyType: ca.companyType
        }
      })
    },
    sortChange (sort) {
      this.sort = sort
    }
  },
  mounted () {
    this.fetchCorporateAccounts()
  }
}
</script>
