import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getData: (ospId, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/get`, { ospId }),

  getByFilter: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/getByFilter`, filter),

  create: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/create`, form),

  update: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/update`, form),

  deactivate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/deactivate`, form),

  activate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/activate`, form),

  findByAny: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/findByAny`, form),

  getByPartner: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/corporateAccount/getByPartner`, filter),

  getCompanyTypeList: (options) => api(options).get(`${PORTAL_SERVICE_V1}/corporateAccount/companyType/list`)
}
