<template>
  <auth-layout>
    <template #left>
      <img class="" src="../assets/auth-image.svg" alt=""/>
      <h3>{{$t('auth.sign_in_page.left_section.title')}}</h3>
      <p v-html="$t('auth.sign_in_page.left_section.description')"/>
      <a class="el-button is-round button-outline-default"
         href="https://www.olympus-mobility.be/nl/meerrittenkaart-of-abonnement/" target="_blank">{{$t('auth.sign_in_page.left_section.button_read_more')}}
      </a>
    </template>
    <template #default>
      <h2 class="auth-title">{{$t('auth.sign_in_page.title')}}</h2>
      <p v-html="$t('auth.sign_in_page.explanation')"/>
      <el-form ref="form" :model="form" @submit.prevent.native="null">
        <base-input
          :label="$t('auth.sign_in_page.form.input_login.label')"
          ref="login"
          prop="login"
          v-model="form.login"
          name="login"
          @keyupEnter="$refs.password.$refs.input.focus()"
        />
        <base-input
          class="login-password"
          :label="$t('auth.sign_in_page.form.input_password.label')"
          ref="password"
          prop="password"
          v-model="form.password"
          :type="passwordType"
          :error="error"
          name="password"
          auto-complete="off"
          @keyupEnter="handleLogin"
        >
          <template #afterInput>
              <span class="show-pwd" @click="showPwd"><svg-icon
                :icon="passwordType === 'password' ? 'eye' : 'eye-open'"/></span>
          </template>
        </base-input>

        <el-form-item>
          <div class="button-group">
            <router-link :to="{name: 'ResetLogin'}" class="grayed-link">
              {{ $t('auth.sign_in_page.form.button_forgot_login.label') }}
            </router-link>
            <router-link :to="{name: 'ResetPassword'}" class="grayed-link">{{
              $t('auth.sign_in_page.form.button_forgot_password.label') }}
            </router-link>
            <el-button type="primary" round class="btn-with-chevron submit-btn" @click="handleLogin"
                       :loading="submitDisabled">
                <span class="label">{{$t('auth.sign_in_page.form.button_sign_in.label')}}
                  <i class="ri-arrow-right-s-line"></i>
                </span>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </template>
  </auth-layout>
</template>

<script>
import sha1 from 'js-sha1'
import AuthLayout from '../layouts/AuthLayout'
import SvgIcon from '../components/SvgIcon'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    AuthLayout,
    SvgIcon
  },
  data () {
    return {
      form: {
        login: '',
        password: ''
      },
      passwordType: 'password',
      error: '',
      submitDisabled: false
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.error = ''
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    showPwd () {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.$refs.input.focus()
      })
    },
    async handleLogin () {
      if (!this.form.login || !this.form.password) {
        this.error = this.$t('validations.auth')
        return true
      }
      this.submitDisabled = true
      try {
        await this.login({
          username: this.form.login,
          passdigest: sha1(this.form.password)
        })
      } catch (e) {
        console.error(e)
        this.error = (e.getErrorLabel && e.getErrorLabel()) || this.$t('validations.auth')
      }
      this.submitDisabled = false
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.$refs.login.$refs.input.focus()
    })
  }
}
</script>

<style lang="scss">

</style>
