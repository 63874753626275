import Vue from 'vue'
import Vuex from 'vuex'

const stores = require.context('./modules', true, /[A-Za-z0-9-_,\s]+\.js$/i)
const modules = {}
stores.keys().forEach(key => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i)
  if (matched && matched.length > 1) {
    const label = matched[1]
    modules[label] = stores(key).default
  }
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules
})
