<template>
  <base-section :withBorder="false" class="general-data">
    <el-form
      ref="form"
      :model="form"
      autocomplete="off"
      id="generalData"
      label-position="top"
      @submit.prevent.native="null"
    >
      <base-datepicker
        v-model="daterange"
        :key="key"
        :label="$t('dashboard.general_data.daterange.label')"
        type="monthrange"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        valueFormat="yyyyMM"
        format="MMMM yyyy"
        :picker-options="dateOptions"
        :maxWidth="300"
        :clearable="false"
      >
      </base-datepicker>

      <div class="information-plates-group">
        <general-data-item
          v-if="$auth.isMsp()"
          :value="activeCorporateAccounts"
          :label="$t('dashboard.general_data.cards.active_corporate_accounts.label')"
          :clickable="$auth.isMsp()"
          @click.native="goToCorporateAccounts"
        ></general-data-item>
        <general-data-item
          :value="activeUsers"
          :label="$t('dashboard.general_data.cards.active_users.label')"
          :clickable="canViewReports"
          @click.native="goToReports('USERCOUNT_detail_CorporateAccount')"
        ></general-data-item>
        <general-data-item
          :value="newActiveUsers"
          :label="$t('dashboard.general_data.cards.new_users.label')"
          :clickable="canViewReports"
          @click.native="goToReports('USERCOUNT_detail_CorporateAccount')"
        ></general-data-item>
        <general-data-item
          :value="countBooking"
          :label="$t('dashboard.general_data.cards.transactions.label')"
          :clickable="canViewReports"
          @click.native="goToReports('Billing_Mobility_overview_MSP_CA')"
        ></general-data-item>
        <general-data-item
          :value="$currency(countSumEvents)"
          :label="$t('dashboard.general_data.cards.total_expenses.label')"
          :clickable="canViewReports"
          @click.native="goToReports('Billing_Mobility_overview_MSP_CA')"
        ></general-data-item>
        <general-data-item
          v-if="(showCompanyBudget || $auth.isMsp()) && companyBudget && companyBudget.data"
          :value="$currency(companyBudget.data.currentAmount)"
          :label="$t('dashboard.general_data.company_budget.total_expenses.label')"
          :clickable="canViewReports"
          @click.native="$router.push({ name: 'CompanyBudget' })"
        ></general-data-item>
      </div>
    </el-form>
  </base-section>
</template>

<script>
import { sumBy } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import GeneralDataItem from '@/components/Dashboard/GeneralDataItem'
import { checkCompanyPaymentType } from '@/app/helpers'
import Role from '@/app/services/RolesService/Role'

export default {
  name: 'GeneralData',
  components: {
    GeneralDataItem
  },
  props: ['period', 'corporateAccountId', 'sumEvents', 'partnerOspId', 'companyType'],
  data () {
    return {
      form: {},
      key: '',
      loading: false,
      countUsers: [],
      countUsersBefore: [],
      countBookingData: []
    }
  },
  computed: {
    ...mapGetters({
      getCompanyBudget: 'budget/getCompanyBudget'
    }),
    daterange: {
      get () {
        return this.period
      },
      set (v) {
        this.$emit('update:period', v)
      }
    },
    countSumEvents () {
      return this.countCollection(this.sumEvents, 'totalPriceVatIncl') || 0
    },
    companyBudget () {
      return this.getCompanyBudget(this.corporateAccountId)
    },
    newActiveUsers () {
      return this.activeUsers - this.activeUsersBefore
    },
    showCompanyBudget () {
      return checkCompanyPaymentType('PREPAID')
    },
    canViewReports () {
      return !!this.$auth.hasRole(Role.REPORTING_MANAGER)
    },
    dateOptions () {
      return {
        disabledDate: value => {
          return value > this.$date.local().endOf('day').toJSDate()
        }
      }
    },
    countUsersFiltered () {
      if (this.companyType) {
        return this.countUsers.filter(el => el.companyType === this.companyType)
      }
      return this.countUsers
    },
    countUsersBeforeFiltered () {
      if (this.companyType) {
        return this.countUsersBefore.filter(el => el.companyType === this.companyType)
      }
      return this.countUsersBefore
    },
    activeUsers () {
      return this.countCollection(this.countUsersFiltered)
    },
    activeUsersBefore () {
      return this.countCollection(this.countUsersBeforeFiltered)
    },
    activeCorporateAccounts () {
      return this.countUsersFiltered.length
    },
    countBookingDataFiltered () {
      if (this.companyType) {
        return this.countBookingData.filter(el => el.companyType === this.companyType)
      }
      return this.countBookingData
    },
    countBooking () {
      return this.countCollection(this.countBookingDataFiltered)
    }
  },
  watch: {
    daterange: {
      deep: true,
      handler (v) {
        this.key = this.$key()
        this.fetchAll()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCompanyBudget: 'budget/getCompanyBudget'
    }),
    fetchAll () {
      if (this.daterange && this.daterange.length && this.daterange[0]) {
        this.fetchCountActiveUsers()
        this.fetchCountActiveUsersBefore()
        this.fetchCountBooking()
      }
    },
    fetchCountActiveUsers () {
      const form = {
        monthFrom: this.daterange[1],
        monthTo: this.daterange[1],
        parentOspId: this.corporateAccountId
      }
      this.$api.reporting.countUsers(form, {
        onSuccess: response => {
          this.countUsers = response
        }
      })
    },
    fetchCountActiveUsersBefore () {
      const date = this.$date
        .fromFormat(this.daterange[0], 'yyyyMM')
        .minus({ month: 1 })
        .toFormat('yyyyMM')
      const form = {
        monthFrom: date,
        monthTo: date,
        parentOspId: this.corporateAccountId
      }
      this.$api.reporting.countUsers(form, {
        onSuccess: response => {
          this.countUsersBefore = response
        }
      })
    },
    fetchCountBooking () {
      const form = {
        monthFrom: this.daterange[0],
        monthTo: this.daterange[1],
        parentOspId: this.corporateAccountId
      }
      this.$api.reporting.countBookings(form, {
        onSuccess: response => {
          this.countBookingData = response
        }
      })
    },
    countCollection (collection, property = 'count') {
      return (
        sumBy(collection, function (o) {
          return o[property]
        }) || 0
      )
    },
    goToReports (type) {
      if (this.canViewReports) {
        return this.$router.push({
          name: 'Reports',
          query: {
            reportType: type,
            reportMonthFrom: this.$date.fromFormat(this.daterange[0], 'yyyyMM').toISODate(),
            reportMonthTo: this.$date
              .fromFormat(this.daterange[1], 'yyyyMM')
              .endOf('month')
              .toISODate()
          }
        })
      }
    },
    goToCorporateAccounts () {
      if (this.$auth.isMsp()) {
        return this.$router.push({
          name: 'CorporateAccountsViewAll',
          query: {
            partnerOspId: this.partnerOspId
          }
        })
      }
    }
  },
  mounted () {
    this.fetchCompanyBudget({
      ospId: this.corporateAccountId
    })
    this.fetchAll()
  }
}
</script>

<style scoped></style>
