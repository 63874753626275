import Auth from '../services/AuthService/Auth'
import store from '@/store'

export default async function needSelectedCorporateAccount (to, from, next) {
  let selectedCA = store.getters['corporateAccount/selectedCorporateAccountOspId']
  if (Auth.user().isMsp && to.query.corporateAccountOspId && selectedCA !== to.query.corporateAccountOspId) {
    await store.dispatch('corporateAccount/selectCorporateAccountOspId', to.query.corporateAccountOspId)
    selectedCA = store.getters['corporateAccount/selectedCorporateAccountOspId']
  }
  if (!selectedCA) {
    return next({ name: 'Dashboard' })
  }
  if (Auth.user().isMsp && !to.query.corporateAccountOspId) {
    return next({
      ...to,
      query: {
        ...to.query,
        corporateAccountOspId: selectedCA
      }
    })
  }
  next()
}
