import api from '@/api'

export default {
  namespaced: true,
  state: {
    types: []
  },
  getters: {
    types: state => state.types
  },
  actions: {
    fetchTypes: ({ commit }) => {
      return api.recurringExternalPurchase.types({
        onSuccess: response => {
          commit('SET_TYPES', response)
        }
      })
    }
  },
  mutations: {
    SET_TYPES: (state, types) => {
      state.types = types
    }
  }
}
