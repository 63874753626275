import axios from 'axios'
import * as rax from 'retry-axios'
import Authorization from './Authorization'
import ErrorResponse from './ErrorResponse'
import { Notification } from 'element-ui'
import { NOTIFICATION_CONFIG } from '../../helpers'

class Api {
  constructor (
    {
      baseURL = process.env.VUE_APP_BASE_API,
      timeout = 120000,
      headers = {},
      reloginOnUnauthorized = true,
      onSuccess = null,
      successNotification = null,
      wrapResponse = null,
      onError = null,
      onFinally = null,
      loader = true,
      responseType = 'json',
      dataOnly = true,
      ignoreErrors = [],
      cancelToken = null,
      onRequest = null
    } = {}
  ) {
    this.authService = new Authorization()
    this.cancelToken = cancelToken
    this.reloginOnUnauthorized = reloginOnUnauthorized
    this.onSuccess = onSuccess && onSuccess.bind(this)
    this.successNotification = successNotification
    this.wrapResponse = wrapResponse && wrapResponse.bind(this)
    this.onError = onError && onError.bind(this)
    this.onFinally = onFinally && onFinally.bind(this)
    this.requestData = null
    this.baseURL = baseURL
    this.loader = loader
    this.responseType = responseType
    this.dataOnly = dataOnly
    this.ignoreErrors = ['TOKEN_EXPIRED', ...ignoreErrors]
    this.timeout = timeout
    this.onRequest = onRequest && onRequest.bind(this)

    this.setHeaders(headers)
      ._defineService()
  }

  cancel (message = '') {
    this.source.cancel(message)
  }

  get (url, params = null, config = {}) {
    return this.request({
      url,
      method: 'get',
      params,
      data: null,
      ...config
    })
  }

  post (url, data = {}, config = {}) {
    return this.request({
      url,
      method: 'post',
      data,
      ...config
    })
  }

  put (url, data = {}, config = {}) {
    return this.request({
      url,
      method: 'put',
      data,
      ...config
    })
  }

  delete (url, config = {}) {
    return this.request({
      url,
      method: 'delete',
      ...config
    })
  }

  request (config) {
    this.requestConfig = config
    this.defineRequestInterceptor()
      .defineRetryInterceptor()
      .defineResponseInterceptor()
    return this.service.request(config)
  }

  _defineService () {
    this.source = this.cancelToken || axios.CancelToken.source()
    this.service = axios.create({
      baseURL: this.baseURL, // url = base url + request url
      responseType: this.responseType,
      timeout: this.timeout, // request timeout
      cancelToken: this.source.token
    })

    return this
  }

  setHeaders (headers) {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...this.authService.getHeaders(),
      ...headers
    }
    return this
  }

  defineRequestInterceptor () {
    this.service.interceptors.request.use(
      config => {
        config.headers = this.headers
        this.onRequest && this.onRequest()
        return config
      },
      error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
      }
    )
    return this
  }

  defineRetryInterceptor () {
    this.service.defaults.raxConfig = {
      retry: 1,
      instance: this.service,
      shouldRetry: err => {
        const errorResponse = new ErrorResponse(err)
        const cfg = rax.getConfig(err)
        // console.log('shouldRetry')
        return [401].includes(errorResponse.getStatus()) && cfg.currentRetryAttempt < 1 && this.reloginOnUnauthorized
      },
      onRetryAttempt: async () => {
        if (this.authService.isRefreshing()) {
          await this.authService.awaitForRefresh()
        } else {
          await this.authService.refreshToken()
        }
        this.headers = {
          ...this.headers,
          ...this.authService.getHeaders()
        }
        return true
      }
    }
    rax.attach(this.service)
    return this
  }

  defineResponseInterceptor () {
    this.service.interceptors.response.use(
      /**
       * If you want to get http information such as headers or status
       * Please return  response => response
       */

      /**
       * Determine the request status by custom code
       * Here is just an example
       * You can also judge the status by HTTP Status Code
       */
      async response => {
        const responseData = response
        if (this.wrapResponse) {
          responseData.data = this.wrapResponse(responseData.data)
        }
        const result = this.dataOnly ? responseData.data : responseData

        if (this.onSuccess) {
          await this.onSuccess(result)
        }
        if (this.onFinally) {
          await this.onFinally(result)
        }
        // if (this.successNotification) {
        Api.pushMessage(this.successNotification, 'success')
        // }
        return Promise.resolve(result)
      },
      error => {
        const errorResponse = new ErrorResponse(error)

        if (errorResponse.isNotifiable(this.ignoreErrors)) {
          Api.pushMessage(errorResponse.getErrorLabel(), 'error')
        }

        if (this.onError) {
          this.onError(errorResponse)
        }
        if (this.onFinally) {
          this.onFinally(errorResponse)
        }
        return Promise.reject(errorResponse)
      }
    )
    return this
  }

  static pushMessage (message, type = 'success', duration = 10 * 1000) {
    if (message) {
      let params = {
        ...NOTIFICATION_CONFIG,
        duration,
        type
      }

      if (typeof message === 'object') {
        params = { ...params, ...message }
      } else {
        params.message = message
      }

      return Notification(params)
    }
  }

  static validationError (errorResponse) {
    let message = [errorResponse.getMessage()]
    // console.log({ ...error })
    const errors = errorResponse.getErrors()
    if (errors.length) {
      message = []
      for (const error of errors) {
        if (error.length) {
          message.push(error[0])
        }
      }
      Api.pushMessage(message.join('<br/>'), 'error')
    } else if (errorResponse.getMessage()) {
      Api.pushMessage(errorResponse.getMessage(), 'error')
    }
  }

  unauthorizedError () {
    if (this.reloginOnUnauthorized) {
      return this.authService.reset()
    }
  }
}

export default Api
