class Paginator {
  contentWrapper = 'content'
  paginationHandler = null

  constructor (arg) {
    Object.assign(this, arg)
    // content: [{ospId: "OlympusMobility.CORPORATE_ACCOUNT.uran.USER.ekotsiuk_mgr", extId: "ekotsiuk_mgr",…},…]
    // number: 0
    // pagination: {page: 0, size: 10, sort: null}
    // totalElements: 17
    // totalPages: 2
  }

  get currentPage () {
    return this.number + 1
  }

  get nextPage () {
    return this.currentPage < this.totalPages ? this.currentPage + 1 : false
  }

  get nextPagePagination () {
    if (this.nextPage) {
      return {
        ...this.pagination,
        page: this.nextPage
      }
    }
    return false
  }

  get previousPage () {
    return this.currentPage > 1 ? this.currentPage - 1 : false
  }

  get previousPagePagination () {
    if (this.previousPage) {
      return {
        ...this.pagination,
        page: this.previousPage
      }
    }
    return false
  }

  next () {
    // -1 because for back-end 0 is first page
    return this.paginationHandler && this.paginationHandler(this.nextPage - 1)
  }

  previous () {
    // -1 because for back-end 0 is first page
    return this.paginationHandler && this.paginationHandler(this.previousPage - 1)
  }

  goToPage (pageNumber) {
    return this.paginationHandler && this.paginationHandler(pageNumber - 1)
  }

  fresh () {
    return this.paginationHandler && this.paginationHandler(this.currentPage - 1)
  }
}

export default Paginator
