import PartnerRepository from '@/app/repositories/PartnerRepository'
import api from '@/api'

const partnerRepository = new PartnerRepository()

export default {
  namespaced: true,
  state: {
    mrpPartners: [],
    mobilityModes: []
  },
  getters: {
    getMrpPartners: (state) => state.mrpPartners,
    getMobilityModes: (state) => state.mobilityModes
  },
  actions: {
    fetchAllMrp ({ commit }) {
      partnerRepository.getAllMrp(
        { active: true },
        {
          onSuccess: (res) => {
            commit('SET_MRP', res)
          }
        }
      )
    },
    fetchMobilityModes ({ commit }) {
      api.resource.getMobilityModes({
        onSuccess: (res) => {
          commit('SET_MODES', res)
        }
      })
    },
    clearState ({ commit }) {
      commit('RESET_STATE')
    }
  },
  mutations: {
    SET_MRP: (state, data) => {
      state.mrpPartners = data
    },
    SET_MODES: (state, data) => {
      state.mobilityModes = data
    },
    RESET_STATE: (state) => {
      state.mrpPartners = []
      state.mobilityModes = []
    }
  }
}
