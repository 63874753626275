<template>
  <div id="app">
    <router-view v-if="isDesktop"/>
    <mobile-unavailable-layout v-else/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import applyBranding from './app/helpers/branding'
import ResizeHandler from './mixins/ResizeHandler'
import MobileUnavailableLayout from './layouts/MobileUnavailableLayout'

export default {
  name: 'app',
  components: { MobileUnavailableLayout },
  mixins: [
    ResizeHandler
  ],
  data () {
    return {
      key: ''
    }
  },
  computed: {
    ...mapGetters({
      branding: 'branding/branding',
      isDesktop: 'app/isDesktop',
      language: 'i18n/langDefault'
    })
  },
  watch: {
    branding (v) {
      applyBranding()
    },
    language () {
      this.key = this.$key()
    }
  },
  methods: {
    ...mapActions({
      changeLang: 'i18n/changeLang'
    })
  },
  mounted () {
    applyBranding()
    if (process.env.BUILD_TIME) {
      console.log(
        '--- Build time:',
        this.$date.fromMillis(process.env.BUILD_TIME).toFormat('FF'),
        '---'
      )
    }
  }
}
</script>
