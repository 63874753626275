import { api, PORTAL_SERVICE_V1, USER_SERVICE_V1 } from '../../app/services/ApiService'
import store from '@/store'

export default {
  login: (data, cb) => api({
    onSuccess: cb,
    reloginOnUnauthorized: false,
    ignoreErrors: ['TOKEN_NOT_FOUND', 'INCORRECT_TWO_FACTOR_AUTHENTICATION_CODE']
  }).post(`${USER_SERVICE_V1}/auth/login`, data),

  refreshToken: (refreshToken, options = {}) => api({
    headers: { Authorization: 'Bearer ' + refreshToken },
    reloginOnUnauthorized: false,
    onError: () => store.dispatch('auth/signOut'),
    ...options
  }).post(`${USER_SERVICE_V1}/auth/refresh`),

  resetLogin: (email, cb) => api({
    onSuccess: cb,
    reloginOnUnauthorized: false
  }).post(`${PORTAL_SERVICE_V1}/user/login/send`, { email }),

  resetPassword: (login, cb) => api({
    onSuccess: cb,
    reloginOnUnauthorized: false
  }).post(`${PORTAL_SERVICE_V1}/user/pincode/reset`, { login })
}
