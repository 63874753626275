<template>
  <div class="base-translations">
    <el-form-item
      :label="label"
    >
      <template v-for="(translation, index) in model">
        <base-tel-input
          :maxLen="maxLen"
          :key="'translation-' + index"
          class="translations-input"
          prop="translation"
          placeholder="Olympus Mobility"
          v-model="translation.text"
          name="translation"
          autoComplete="off"
          :autoFormat="false"
          type="text"
          :customValidate="true"
          :disabledDialCode="true"
          :showDialCode="false"
          :onlyCountries="[...availableCountryCodes, translation.languageCode]"
          :defaultCountry="translation.languageCode"
          @submit="$emit('submit',translation.languageCode)"
          @country-changed="languageChanged($event, index)"
        ></base-tel-input>
      </template>
    </el-form-item>
    <base-button
      v-if="addTranslationAllowed"
      class="add-circle-button"
      @click="addNameTranslations"
      prepend-icon="ri-add-line"
      type="primary"
      :round="false"
      circle
    ></base-button>
  </div>
</template>
<script>

import { cloneDeep } from 'lodash'

const languages = [
  {
    countryCode: 'FR',
    langCode: 'fr'
  },
  {
    countryCode: 'GB',
    langCode: 'en'
  },
  {
    countryCode: 'BE',
    langCode: 'nl'
  },
  {
    countryCode: 'DE',
    langCode: 'de'
  }
]

const countryToLanguageCode = (countryCode) => {
  const langCodes = {}
  languages.map(({ countryCode, langCode }) => {
    langCodes[countryCode] = langCode
  })
  return langCodes[countryCode]
}
// const languageToCountryCode = (languageCode) => {
//   const langCodes = {}
//   languages.map(({ countryCode, langCode }) => {
//     langCodes[langCode] = countryCode
//   })
//   return langCodes[languageCode]
// }

export default {
  name: 'BaseTranslations',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: function () { return this.$t('general.label.translations') }
    },
    getLanguageCode: {
      type: Function,
      default: () => {}
    },
    maxLen: {
      type: Number,
      default: 90000
    }
  },
  data () {
    return {
      model: cloneDeep(this.$fixTranslations(this.value)),
      languages
    }
  },
  computed: {
    availableLanguages () {
      return this.languages.filter(v => !this.selectedLanguages.includes(v.countryCode)
      )
    },
    availableCountryCodes () {
      return this.availableLanguages.map(v => v.countryCode)
    },
    selectedLanguages () {
      return this.model.map(v => v.languageCode)
    },
    addTranslationAllowed () {
      return !!this.availableLanguages.length
    }
  },
  watch: {
    model: {
      deep: true,
      handler (newVal) {
        this.$emit('input', cloneDeep(newVal).map((v) => {
          v.languageCode = countryToLanguageCode(v.languageCode)
          return v
        }))
      }
    }
  },
  methods: {
    addNameTranslations () {
      const model = [...this.model]
      model.push({
        text: '',
        languageCode: this.availableCountryCodes[0]
      })
      this.model = model
      this.$emit('addTranslations')
    },
    languageChanged (event, index) {
      this.model[index].languageCode = event.iso2
      this.getLanguageCode(event.iso2, index)
    }
  }
}
</script>
