<template>
  <el-form-item
    :label="label && label + (required ? '*' : '')"
    :prop="prop"
    :disabled="disabled"
    :class="{
      'has-error': error || validationsError,
      'has-message': validationsError || error || success,
      disabled: disabled
    }"
  >
    <el-date-picker
      v-model="model"
      :key="type"
      :type="type"
      :name="name"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder"
      :format="format"
      :value-format="valueFormat"
      :picker-options="pickerOptions"
      :style="{ 'max-width': maxWidth ? `${maxWidth}px` : null }"
      :default-time="defaultTime"
    >
    </el-date-picker>

    <p v-if="validationsError" class="error">
      {{
        $t(`validations.${validationsError}.${label ? 'with_label' : 'without_label'}`, { label })
      }}
    </p>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <p v-else-if="success" class="success">
      {{ success }}
    </p>
    <p v-if="info" class="info">{{ info }}</p>
  </el-form-item>
</template>

<script>
import { keys } from 'lodash'

export default {
  name: 'BaseDatepicker',
  props: {
    value: {
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'date'
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: null
    },
    format: {
      type: String,
      default: 'dd/MM/yyyy'
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    pickerOptions: {
      type: Object,
      default: () => {}
    },
    defaultTime: {
      type: [String, undefined],
      default: undefined
    },
    info: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    validationsError () {
      return (
        this.validations &&
        this.validations.$dirty &&
        this.validations.$params &&
        keys(this.validations.$params).find(v => !this.validations[v])
      )
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  }
}
</script>
