import Auth from '../../app/services/AuthService/Auth'
function checkRole (el, binding) {
  // console.log('checkRole')
  const { value } = binding

  if (value) {
    const hasRole = Auth.hasRole(value)
    // console.log(hasRole)
    if (!hasRole) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  } else {
    throw new Error('need roles! Like v-role="[\'admin\']"')
  }
}

export default {
  inserted (el, binding) {
    checkRole(el, binding)
  },
  update (el, binding) {
    checkRole(el, binding)
  }
}
