import { get, first } from 'lodash'
import Vue from 'vue'
import BudgetRepository from '../../app/repositories/BudgetRepository'
// import BudgetTypeRepository from '@/app/repositories/BudgetTypeRepository'
import CompanyBudget from '../../app/models/CompanyBudget'

const budgetRepository = new BudgetRepository()
// const budgetTypeRepository = new BudgetTypeRepository()

export default {
  namespaced: true,
  state: {
    userBudgets: {},
    companyBudgets: {},
    privateCompanyBudget: '',
    transactionDetails: {}
  },
  getters: {
    getPrivateCompanyBudget: state => state.privateCompanyBudget,
    userBudgets: state => ospId => get(state.userBudgets, ospId, []),
    companyBudgets: state => ospId => get(state.companyBudgets, ospId, []),
    getcompanyPaymentType: state => (ospId) => get(first(get(state.companyBudgets, ospId, [])), ['data', 'paymentType'], ''),
    getCompanyBudget: state => (ospId) => first(get(state.companyBudgets, ospId, [])),
    // getUserBudgetTypes: state => state.userBudgetTypes
    getTransactionDetails: state => state.transactionDetails
  },
  actions: {
    getUserBudgets ({ commit }, { ospId, options = {} }) {
      return budgetRepository.getByOwner({ ownerOspId: ospId }, {
        ...options,
        onSuccess: response => {
          commit('SET_USER_BUDGETS', { ospId, response })
        }
      })
    },
    getCompanyBudget ({ commit }, { ospId, options = {} }) {
      const repository = new BudgetRepository(CompanyBudget)
      return repository.getByOwner({
        budgetType: 'COMPANY_BUDGET',
        ownerOspId: ospId
      }, {
        ...options,
        onSuccess: response => {
          commit('SET_COMPANY_BUDGETS', { ownerOspId: ospId, response })
        }
      })
    },
    getPrivateBudget ({ commit }, { ospId, options = {} }) {
      return budgetRepository.getPrivateBudget(ospId, {
        ...options,
        onSuccess: ({ totalBalance }) => {
          commit('SET_PRIVATE_COMPANY_BUDGETS', totalBalance)
        }
      })
    },
    getTransactionDetails ({ commit }, transaction) {
      return commit('SET_TRANSACTION_DETAILS', transaction)
    }
    // getTransactions ({ commit }, { form, options = {} }) {
    //   return budgetRepository.getTransactions(form, options)
    // },
    // getUserBudgetTypes ({ commit }, id) {
    //   return budgetTypeRepository.getAll(id, {
    //     onSuccess: (res) => {
    //       commit('SET_USER_BUDGET_TYPES', res)
    //     }
    //   })
    // }
  },
  mutations: {
    SET_USER_BUDGETS: (state, { ospId, response }) => {
      Vue.set(state.userBudgets, ospId, response)
    },
    SET_COMPANY_BUDGETS: (state, { ownerOspId, response }) => {
      Vue.set(state.companyBudgets, ownerOspId, response)
    },
    SET_PRIVATE_COMPANY_BUDGETS: (state, totalBalance) => {
      state.privateCompanyBudget = totalBalance
    },
    SET_TRANSACTION_DETAILS: (state, transaction) => {
      state.transactionDetails = transaction
    }
  }
}
