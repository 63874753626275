<template>
  <section class="app-main" id="appMain">
    <old-platform-banner />
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </section>
</template>

<script>
import OldPlatformBanner from '@/components/OldPlatformBanner.vue'

export default {
  name: 'AppMain',
  components: { OldPlatformBanner }
}
</script>

<style scoped></style>
