import middleware from '@/app/services/MiddlewareService/middleware'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'partners',
    name: 'PartnersViewAll',
    component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnersViewAll.vue'),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'partners/add',
    name: 'PartnersAdd',
    component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnersAdd.vue'),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN)
  },
  {
    path: 'partners/:partnerOspId',
    name: 'PartnerViewLayout',
    component: () => import(/* webpackChunkName: "partners" */ '@/layouts/PartnerViewLayout.vue'),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true,
    redirect: { name: 'PartnerViewDetails' },
    children: [
      {
        path: 'details',
        name: 'PartnerViewDetails',
        component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnerViewDetails.vue'),
        props: true,
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        meta: {
          showSaveDetailsButton: true
        }
      },
      {
        path: 'products',
        name: 'PartnerViewProducts',
        component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnerViewProducts.vue'),
        props: true,
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        meta: {
          showSaveDetailsButton: true
        }
      },
      {
        path: 'resources',
        name: 'PartnerViewAppMenuConfig',
        component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnerViewAppMenuConfig.vue'),
        props: true,
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        meta: {
          showSaveDetailsButton: true
        }
      },
      {
        path: 'budgets',
        name: 'PartnerViewBudgets',
        component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnerViewBudgets.vue'),
        props: true,
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        meta: {
          showSaveDetailsButton: true
        }
      },
      {
        path: 'branding',
        name: 'PartnerViewBranding',
        component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnerViewBranding.vue'),
        props: true,
        beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
        meta: {
          showSaveDetailsButton: true
        }
      },
      {
        path: 'linked-partners',
        name: 'PartnerViewLinkedPartners',
        component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/PartnerViewLinkedPartners.vue'),
        props: true,
        meta: {
          showAddLinkedPartnerButton: true
        }
      }
    ]
  },
  {
    path: 'partners/:partnerOspId/add-linked',
    name: 'AddLinkedPartners',
    component: () => import(/* webpackChunkName: "partners" */ '@/views/Partners/AddLinkedPartners.vue'),
    beforeEnter: middleware('hasRole:' + Role.OSPADMIN),
    props: true
  }
]
