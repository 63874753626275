import { checkCompanyPaymentType } from '../helpers'

export default function companyPaymentType (...types) {
  return (to, from, next) => {
    if (checkCompanyPaymentType(...types)) {
      return next()
    }
    return next('404')
  }
}
