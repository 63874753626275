
import ReportRepository from '@/app/repositories/ReportRepository'

export default {
  namespaced: true,
  state: {
    types: []
  },
  getters: {
    types: state => state.types,
    getTypeByReportType: state => reportType => state.types.find(v => v.reportType === reportType)
  },
  actions: {
    getTypes ({ commit }) {
      return ReportRepository.getTypes({
        onSuccess: response => {
          commit('SET_TYPES', response)
        }
      })
    },
    clearTypes ({ commit }) {
      commit('CLEAR_TYPES')
    }
  },
  mutations: {
    SET_TYPES: (state, response) => {
      state.types = response
    },
    CLEAR_TYPES: (state) => {
      state.types = []
    }
  }
}
