var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-color-picker"},[_c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.hide),expression:"hide"}],class:[
    'el-color-picker',
    _vm.colorDisabled ? 'is-disabled' : '',
    _vm.colorSize ? `el-color-picker--${ _vm.colorSize }` : ''
  ]},[(_vm.colorDisabled)?_c('div',{staticClass:"el-color-picker__mask"}):_vm._e(),_c('div',{staticClass:"el-color-picker__trigger",on:{"click":_vm.handleTrigger}},[_c('span',{staticClass:"el-color-picker__color",class:{ 'is-alpha': _vm.showAlpha }},[_c('span',{staticClass:"el-color-picker__color-inner",style:({
          backgroundColor: _vm.displayedColor
        })}),(!_vm.value && !_vm.showPanelColor)?_c('span',{staticClass:"el-color-picker__empty el-icon-close"}):_vm._e()]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.value || _vm.showPanelColor),expression:"value || showPanelColor"}],staticClass:"el-color-picker__icon el-icon-arrow-down"})]),_c('base-color-picker-dropdown',{ref:"dropdown",class:['el-color-picker__panel', _vm.popperClass || ''],attrs:{"color":_vm.color,"show-alpha":_vm.showAlpha,"predefine":_vm.predefine,"show-reset":_vm.showReset,"show-clear":_vm.showClear},on:{"pick":_vm.confirmValue,"clear":_vm.clearValue,"reset":_vm.resetValue},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}})],1),_c('span',{staticClass:"base-color-picker--title"},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }