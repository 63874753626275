import Vue from 'vue'
import api from '@/api'
import { get } from 'lodash'
import Product from '@/app/models/Product'
import { trans } from '@/app/helpers'

export default {
  namespaced: true,
  state: {
    allProducts: [],
    composition: {},
    allResourcegroups: [],
    serviceResources: [],
    rentalCarResources: []
  },
  getters: {
    allProducts: state => state.allProducts,
    composition: state => productOspId => get(state.composition, productOspId, null),
    getByOspId: state => productOspId => state.allProducts.find(({ ospId }) => ospId === productOspId),
    allResourcegroups: state => state.allResourcegroups,
    rentalCarResources: state => state.rentalCarResources,
    serviceResources: state => state.serviceResources,
    flatServiceResources: state => state.serviceResources.map(v => ({
      ospId: v.ospId,
      ...v.data,
      name: trans(v.data.nameLabel)
    }))
  },
  actions: {
    getProductComposition ({ commit }, productOspId) {
      return api.product.getComposition(productOspId, {
        onSuccess: response => {
          commit('SET_PRODUCT', response)
        }
      })
    },
    findAllProducts ({ commit }, options = {}) {
      return api.product.findByFilter({ active: null }, {
        ...options,
        onSuccess: res => {
          const products = res.map(v => new Product(v))
          commit('SET_ALL_PRODUCTS', products)
          return options.onSuccess && options.onSuccess(products)
        }
      })
    },
    getAllResourcegroups ({ commit }, { filter, options }) {
      return api.product.findAllResourcegroups(filter, {
        ...options,
        onSuccess: res => {
          commit('SET_ALL_RESOURCEGROUPS', res)
          return options.onSuccess && options.onSuccess(res)
        }
      })
    },
    getAllServiceResources ({ commit }, options) {
      return api.product.findAllServiceResources({
        ...options,
        onSuccess: res => {
          commit('SET_ALL_SERVICE_RESOURCES', res)
          return options.onSuccess && options.onSuccess(res)
        }
      })
    },
    getAllRentalCarResources ({ commit }, options) {
      return api.product.findAllRentalCarResources({
        ...options,
        onSuccess: res => {
          commit('SET_ALL_RENTAL_CAR_RESOURCES', res)
          return options.onSuccess && options.onSuccess(res)
        }
      })
    }
  },
  mutations: {
    SET_ALL_PRODUCTS: (state, data) => {
      state.allProducts = data
    },
    SET_PRODUCT: (state, data) => {
      Vue.set(state.composition, data.productOspId.ospId, data)
    },
    SET_ALL_RESOURCEGROUPS: (state, data) => {
      state.allResourcegroups = data
    },
    SET_ALL_SERVICE_RESOURCES: (state, data) => {
      state.serviceResources = data
    },
    SET_ALL_RENTAL_CAR_RESOURCES: (state, data) => {
      state.rentalCarResources = data
    }
  }
}
