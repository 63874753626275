import api from '@/api'
import router from '@/router'
import { get } from 'lodash'

const MOBILE_DEVICE = 'mobile'
const DESKTOP_DEVICE = 'desktop'
const getDefaultState = () => {
  return {
    device: DESKTOP_DEVICE,
    intendedRoute: null,
    help: {}
  }
}
export default {
  namespaced: true,
  state: {
    ...getDefaultState()
  },
  getters: {
    isMobile: state => state.device === MOBILE_DEVICE,
    isDesktop: state => state.device === DESKTOP_DEVICE,
    intendedRoute: state => state.intendedRoute,
    help: state => state.help
  },
  actions: {
    toggleDevice ({ commit }, device) {
      commit('SET_DEVICE', device)
    },
    setIntendedRoute ({ commit }, route = null) {
      // console.log('setIntendedRoute')
      // console.log(route)
      if (!route) {
        let href = ''
        if (router.mode === 'hash') {
          href = location.hash.substring(1)
        } else {
          href = location.pathname
        }
        route = get(router.resolve(href), 'route', route)
      }
      commit('SET_INTENDED_ROUTE', route)
    },
    getHelp ({ commit }) {
      return api.app.getHelp(response => {
        commit('SET_HELP', response)
      })
    }
  },
  mutations: {
    SET_DEVICE: (state, device) => {
      state.device = device
    },
    SET_INTENDED_ROUTE: (state, intendedRoute) => {
      // console.log('SET_INTENDED_ROUTE', intendedRoute)
      state.intendedRoute = intendedRoute
    },
    SET_HELP: (state, help) => {
      state.help = help
    }
  }
}
