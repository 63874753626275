import middleware from '@/app/services/MiddlewareService/middleware'

export default [
  {
    path: 'company/products',
    name: 'Products',
    redirect: { name: 'Products' },
    component: () =>
      import(
        /* webpackChunkName: "company-products" */ '@/layouts/ProductsLayout.vue'
      ),
    props: true,
    children: [
      {
        path: 'list',
        name: 'CompanyProducts',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () =>
          import(
            /* webpackChunkName: "company-products" */ '@/views/CompanyProducts.vue'
          ),
        props: true
      },
      {
        path: 'homework-registration',
        name: 'HomeworkRegistration',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () =>
          import(
            /* webpackChunkName: "company-products" */ '@/views/HomeworkRegistration.vue'
          ),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'trip-registration',
        name: 'CompanyTripRegistration',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () =>
          import(
            /* webpackChunkName: "company-products" */ '@/views/CompanyTripRegistration.vue'
          ),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      },
      {
        path: 'nmbs-subscription',
        name: 'CompanyNmbsSubscription',
        beforeEnter: middleware('needSelectedCorporateAccount'),
        component: () =>
          import(
            /* webpackChunkName: "company-products" */ '@/views/CompanyNmbsSubscription.vue'
          ),
        props: true,
        meta: {
          showSaveChangesButton: true
        }
      }
    ]
  },

  {
    path: 'upload-nmbs-invoice',
    name: 'CompanyUploadNmbsInvoice',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () =>
      import(
        /* webpackChunkName: "company-products" */ '@/views/CompanyUploadNmbsInvoice.vue'
      ),
    props: true,
    meta: {
      showSaveChangesButton: true
    }
  }
]
