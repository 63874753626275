import store from '@/store'
import { kebabCase } from 'lodash'

function getColors () {
  const branding = store.getters['branding/branding']
  return Object.keys(branding).filter(key => key.includes('Color'))
    .reduce((obj, key) => {
      obj[key] = branding[key]
      return obj
    }, {})
}

export default function applyBranding () {
  const root = document.documentElement
  const brandingColors = getColors()
  Object.keys(brandingColors).forEach(k => {
    const label = `--${kebabCase(k)}`
    root.style.setProperty(label, brandingColors[k])
  })
}
