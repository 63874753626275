import Auth from '../services/AuthService/Auth'
import store from '@/store'

export default function auth (to, from, next) {
  // console.log('middleware auth')
  if (Auth.token()) {
    return next()
  } else {
    store.dispatch('app/setIntendedRoute', to)
    next({ name: 'Login' })
  }
}
