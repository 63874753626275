import middleware from '@/app/services/MiddlewareService/middleware'
import Role from '@/app/services/RolesService/Role'

export default [
  {
    path: 'communication',
    redirect: { name: 'CommunicationMessagesViewAll' },
    component: () => import(/* webpackChunkName: "communication" */ '@/layouts/CommunicationLayout.vue'),
    beforeEnter: middleware(`hasRole:${Role.OSPADMIN}`),
    children: [
      {
        path: 'message-channels',
        name: 'CommunicationMessageChannelsViewAll',
        beforeEnter: middleware(`hasRole:${Role.OSPADMIN}`),
        component: () => import(/* webpackChunkName: "communication" */ '@/views/Communication/CommunicationMessageChannelsViewAll.vue')
      }
    ]
  },
  {
    path: 'communication/message-channels/add',
    name: 'CommunicationMessageChannelsAdd',
    beforeEnter: middleware(`hasRole:${Role.OSPADMIN}`),
    component: () => import(/* webpackChunkName: "communication" */ '@/views/Communication/CommunicationMessageChannelsAdd.vue')
  },
  {
    path: 'communication/message-channels/details',
    name: 'CommunicationMessageChannelsDetails',
    beforeEnter: middleware(`hasRole:${Role.OSPADMIN}`),
    component: () => import(/* webpackChunkName: "communication" */ '@/views/Communication/CommunicationMessageChannelsDetails.vue')
  },
  {
    path: 'communication/message/add',
    name: 'CommunicationMessagesAdd',
    beforeEnter: middleware(`hasRole:${Role.OSPADMIN}`),
    component: () => import(/* webpackChunkName: "communication" */ '@/views/Communication/CommunicationMessageAdd.vue')
  }
]
