<template>
  <el-form-item
    class="base-tel-input"
    :label="label + (required ? '*' : '')"
    :prop="prop"
    :disabled="disabled"
    :class="{
      'has-error': error || validationsError,
      'has-message': validationsError || error || success,
      'disabled': disabled
      }"
  >
    <vue-tel-input
      ref="vueTelInput"
      v-model="model"
      :name="name"
      :maxLen='maxLen'
      :placeholder="placeholder"
      :only-countries="onlyCountries"
      :default-country="defaultCountry"
      :disabled-fetching-country="true"
      :inputOptions="{showDialCode, tabindex: 0, type}"
      :validCharactersOnly="validCharactersOnly"
      :preferredCountries="preferredCountries"
      :mode="mode"
      :autoFormat="autoFormat"
      :dropdown-options="{disabledDialCode}"
      @country-changed="countryChanged"
      @keyup.enter="$emit('submit')"
    ></vue-tel-input>
    <p v-if="validationsError" class="error">
      {{ $t(`validations.${validationsError}.${label ? 'with_label' : 'without_label'}`, { label }) }}
    </p>
    <p v-else-if="error" class="error">
      {{ error }}
    </p>
    <p v-else-if="success" class="success">
      {{ success }}
    </p>
  </el-form-item>
</template>

<script>
import { keys } from 'lodash'
// import TelInput from './TelInput'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'BaseTelInput',
  components: {
    VueTelInput
  },
  props: {
    value: {
      default: null
    },
    autoComplete: {
      type: String,
      default: 'on'
    },
    error: {
      type: String,
      default: ''
    },
    success: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    validations: {
      type: Object,
      default: null
    },
    validCharactersOnly: {
      type: Boolean,
      default: false
    },
    customValidate: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'auto'
    },
    type: {
      type: String,
      default: 'tel'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    autoFormat: {
      type: Boolean,
      default: true
    },
    disabledDialCode: {
      type: Boolean,
      default: true
    },
    showDialCode: {
      type: Boolean,
      default: true
    },
    defaultCountry: {
      type: String,
      default: 'BE'
    },
    onlyCountries: {
      type: Array,
      default: () => [
        'BE', 'FR', 'DE', 'NL', 'UA'
      ]
    },
    preferredCountries: {
      type: Array,
      default: () => []
    },
    maxLen: {
      type: Number,
      default: 25
    }
  },
  data () {
    return {
      model: this.value,
      phone: ''
    }
  },
  computed: {
    validationsError () {
      return this.validations && this.validations.$dirty && this.validations.$params && keys(this.validations.$params).find(v => !this.validations[v])
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  },
  methods: {
    countryChanged (data) {
      this.$emit('country-changed', data)
    }
  }
}
</script>
