import { assign, get } from 'lodash'
import Auth from './Auth'
import Role from '@/app/services/RolesService/Role'

export default class User {
  constructor () {
    assign(this, Auth.userData())
    this.id = Auth.id
  }

  get fullName () {
    return get(this, 'firstname', '') + ' ' + get(this, 'lastname', '')
  }

  get isMsp () {
    try {
      // console.log(this)
      const source = this.id().split('.')
      return source[0] === source[2] || this.isOspAdmin
    } catch (e) {
      console.error(e)
      return false
    }
  }

  get isOspAdmin () {
    return this.roles.includes(Role.OSPADMIN)
  }
}
