import Auth from '../services/AuthService/Auth'
import Role from '../services/RolesService/Role'
import store from '@/store'

export default async function needSelectedCorporateAccountOrAdmin (to, from, next) {
  let selectedCA = store.getters['corporateAccount/selectedCorporateAccountOspId']
  const userPartner = store.getters['partner/userPartner']
  if (
    Auth.user().isMsp &&
    to.query.corporateAccountOspId &&
    selectedCA !== to.query.corporateAccountOspId
  ) {
    await store.dispatch(
      'corporateAccount/selectCorporateAccountOspId',
      to.query.corporateAccountOspId
    )
    selectedCA = store.getters['corporateAccount/selectedCorporateAccountOspId']
  }
  if (!selectedCA && !Auth.isOspAdmin() && !userPartner.isArtemis) {
    if (to.name === 'Invoices' && Auth.user().roles?.includes(Role.INVOICE_MANAGER)) {
      return next()
    }
    return next({ name: 'Dashboard' })
  }
  if (Auth.user().isMsp && !to.query.corporateAccountOspId && selectedCA) {
    return next({
      ...to,
      query: {
        ...to.query,
        corporateAccountOspId: selectedCA
      }
    })
  }
  next()
}
