import { has, get, values } from 'lodash'
import { trans } from '../../helpers'

class ErrorResponse {
  constructor (error) {
    this.error = error
  }

  hasStatus () {
    return has(this.error, 'response.status')
  }

  getStatus () {
    return get(this.error, 'response.status', null)
  }

  getMessage () {
    return has(this.error, ['response', 'data', 'message']) ? get(this.error, ['response', 'data', 'message']) : get(this.error, ['message'], '')
  }

  getErrorLabel () {
    return trans(get(this.error, ['response', 'data', 'errorLabel'], ''))
  }

  getErrors () {
    return has(this.error, 'response.data.errors') ? values(this.error.response.data.errors) : []
  }

  getCode () {
    return get(this.error, 'response.data.code', '')
  }

  isNotifiable (ignoreList) {
    if (!ignoreList || !ignoreList.length) {
      return true
    }
    if (ignoreList.includes('*')) {
      return false
    }
    return !ignoreList.includes(this.getMessage()) && !ignoreList.includes(this.getCode())
  }
}

export default ErrorResponse
