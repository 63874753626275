import { api, PORTAL_SERVICE_V1, CAMBIO_CO2, CAMBIO_INVOICE } from '../../app/services/ApiService'

export default {
  upload: (form, options) => api({ ...options, responseType: 'text' }).post(`${PORTAL_SERVICE_V1}/upload/upload`, form),
  process: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/upload/process`, form),
  cambioInvoiceProcess: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/cambio/invoice/process`, form),
  validate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/upload/validate`, form),
  voucherUpload: (form, options) => api({ ...options, responseType: 'text' }).post(`${PORTAL_SERVICE_V1}/voucher/upload`, form),
  voucherProcess: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/voucher/process`, form),
  voucherValidate: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/voucher/validate`, form),
  cambioCo2Upload: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/${CAMBIO_CO2}/upload`, form),
  cambioInvoiceUpload: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/${CAMBIO_INVOICE}/upload`, form),
  cambioCo2Process: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/${CAMBIO_CO2}/process`, form)
}
