import BudgetRepository from '../repositories/BudgetRepository'
import { cloneDeep, isNumber } from 'lodash'

class CompanyBudget {
  #original = {}
  #repository = {}
  ospId = null
  data = {
    activationStatus: 'ACTIVE',
    active: true,
    budgetUpperLimit: null,
    createdOn: '',
    currency: 'EUR',
    currentAmount: 0,
    depositTransactionCost: 0,
    endActivationTime: null,
    endOfLifeStartedOn: null,
    extId: '',
    frequency: null,
    frequencyAmount: 0,
    frequencyMethod: null,
    limited: false,
    onlinePayment: false,
    ownerOspId: '',
    parentBudgetOspId: null,
    paymentType: 'COMPANY_BUDGET',
    securityDepositAmount: null,
    startActivationTime: '',
    threshold: null,
    topUpAmount: null,
    transferStrategy: null,
    transferredAmount: 0,
    tvaIncluded: true,
    type: 'COMPANY_BUDGET',
    userOspId: '',
    vatIncluded: true
  }

  constructor (object = { data: {} }) {
    this.#original = cloneDeep(object)
    Object.assign(this, object)
    this.#repository = new BudgetRepository(CompanyBudget)
  }

  get currentAmount () {
    return this.data.currentAmount.toLocaleString('ru-RU', { minimumFractionDigits: 2 })
  }

  get securityDepositAmount () {
    if (isNumber(this.data.securityDepositAmount)) {
      return this.data.securityDepositAmount.toLocaleString('ru-RU', { minimumFractionDigits: 2 })
    }
    return 0
  }

  get threshold () {
    return this.data.threshold
  }

  get vatIncludedInt () {
    return this.data.vatIncluded ? 1 : 0
  }

  save (options) {
    if (this.ospId) {
      return this.#repository.update(this, options)
    }
  }
}

export default CompanyBudget
