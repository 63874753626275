var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"el-transfer base-transfer",class:{
       'base-transfer--flex': _vm.isFlex,
       'base-transfer--full-width': _vm.fullWidth,
       'base-transfer--with-pagination': _vm.withPagination
       },style:({
         'height': _vm.height ? `${_vm.height}px` : '',
         'max-height': _vm.height ? `${_vm.height}px` : ''
       })},[_c('base-transfer-panel',_vm._b({ref:"leftPanel",attrs:{"data":_vm.sourceData,"top-title":_vm.topTitles[0],"title":_vm.titles[0] || _vm.t('el.transfer.titles.0'),"default-checked":_vm.leftDefaultChecked,"placeholder":_vm.filterPlaceholder || _vm.$t('general.search'),"pagination":_vm.leftPagination,"panel-loading":_vm.leftPanelLoading},on:{"checked-change":_vm.onSourceCheckedChange}},'base-transfer-panel',_vm.$props,false),[_vm._t("left-footer")],2),_c('div',{staticClass:"el-transfer__buttons"},[_c('el-button',{class:['el-transfer__button', _vm.hasButtonTexts ? 'is-with-texts' : ''],attrs:{"type":"primary","circle":"","disabled":_vm.rightChecked.length === 0},nativeOn:{"click":function($event){return _vm.transfer('left')}}},[_c('i',{staticClass:"el-icon-arrow-left"}),(_vm.buttonTexts[0] !== undefined)?_c('span',[_vm._v(_vm._s(_vm.buttonTexts[0]))]):_vm._e()]),_c('el-button',{class:['el-transfer__button', _vm.hasButtonTexts ? 'is-with-texts' : ''],attrs:{"type":"primary","circle":"","disabled":_vm.leftChecked.length === 0},nativeOn:{"click":function($event){return _vm.transfer('right')}}},[(_vm.buttonTexts[1] !== undefined)?_c('span',[_vm._v(_vm._s(_vm.buttonTexts[1]))]):_vm._e(),_c('i',{staticClass:"el-icon-arrow-right"})])],1),_c('base-transfer-panel',_vm._b({ref:"rightPanel",attrs:{"data":_vm.targetData,"top-title":_vm.topTitles[1],"title":_vm.titles[1] || _vm.t('el.transfer.titles.1'),"default-checked":_vm.rightDefaultChecked,"placeholder":_vm.filterPlaceholder || _vm.$t('general.search'),"pagination":_vm.rightPagination,"panel-loading":_vm.rightPanelLoading},on:{"checked-change":_vm.onTargetCheckedChange}},'base-transfer-panel',_vm.$props,false),[_vm._t("right-footer")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }