import Model from './Model'
import ReportRepository from '@/app/repositories/ReportRepository'
import ReportType from '@/app/models/ReportType'
import { DateTime } from 'luxon'
import { DATE_FORMAT } from '@/app/helpers'

class Report extends Model {
  #repository = null
  reportPeriod = null
  reportType = null
  reportMonth = ''

  constructor (object = {}) {
    super(object)
    this.initialize(object)
    this.#repository = new ReportRepository()
  }

  get year () {
    return this.parsedReportMonth && this.parsedReportMonth.toFormat('yyyy')
  }

  get month () {
    return this.parsedReportMonth && this.parsedReportMonth.toFormat('LLLL')
  }

  get createdOnDate () {
    return DateTime.fromISO(this.createdOn).toFormat(DATE_FORMAT)
  }

  get type () {
    return ReportType.getTypeByReportType(this.reportType)
  }

  get hasPeriod () {
    return !!this.reportPeriod
  }

  download (type = '', options = {}) {
    return this.#repository.download({
      ospId: this.ospId,
      outputFilename: this.getReportOutputFileName(),
      fileType: type
    }, options)
  }

  getReportOutputFileName () {
    return [
      this.reportType,
      this.reportMonth,
      this.reportPeriod,
      this.mspExtId,
      this.corporateAccountExtId
    ]
      .join('_')
      .slice(0, -1)
  }

  get parsedReportMonth () {
    return this.reportMonth && DateTime.fromFormat(this.reportMonth, 'yyyyMM')
  }
}

export default Report
