<template>
  <el-container class="auth-layout">
    <old-platform-banner fixed />
    <el-card class="auth-card" :class="{ 'simple-auth-card': !$slots.left }">
      <el-row class="auth-page">
        <el-col v-if="$slots.left" :span="12" class="auth-page--left">
          <slot name="left"></slot>
        </el-col>
        <el-col class="auth-page--right" :span="$slots.left ? 12 : 24">
          <el-row justify="space-between" type="flex">
            <img class="auth-page--right-logo" src="../assets/auth-logo.svg" alt="" />
            <div>
              <el-dropdown @command="changeLanguage" trigger="click">
                <span class="el-dropdown-link">
                  {{ $t('sidebar.menu_item.change_language') }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="{ value, label } in languages"
                    :key="value"
                    :command="value"
                  >
                    {{ label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-row>
          <slot />
        </el-col>
      </el-row>
    </el-card>
  </el-container>
</template>

<script>
import OldPlatformBanner from '../components/OldPlatformBanner.vue'
import LanguageSelector from '../mixins/LanguageSelector'

export default {
  components: { OldPlatformBanner },
  name: 'AuthLayout',
  mixins: [LanguageSelector],
  data () {
    return {}
  }
}
</script>
