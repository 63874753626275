<template>
  <div :class="['service-resource-panel', , minified ? 'minified' : '']">
    <div class="service-resource-panel__header">
      <span class="text-secondary-title" v-html="serviceResource.title"></span>
      <el-avatar
        v-if="serviceResource.image"
        shape="square"
        :size="minified ? 45 : 55"
        fit="contain"
        :src="serviceResource.image"
        style="min-width: 24px"
      ></el-avatar>
    </div>
    <div class="service-resource-panel__body">
      {{ serviceResource.info }}
    </div>
    <div class="service-resource-panel__footer" v-if="$scopedSlots.footer">
      <slot :serviceResource="serviceResource" name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseServiceResource',
  props: {
    serviceResource: {
      type: Object
    },
    minified: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.minified {
  border: none;
  .service-resource-panel__header {
    flex-direction: row-reverse;
    justify-content: start;
    .el-avatar {
      margin-left: 0;
      margin-right: 8px;
    }
  }
  .service-resource-panel__body {
    line-height: 18px;
  }
}
</style>
