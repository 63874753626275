<template>
  <el-form-item
    :prop="prop"
  >
    <el-radio
      :name="name"
      :size="size"
      v-model="model"
      :label="label"
      :disabled="disabled"
    >
      <slot></slot>
    </el-radio>
    <p class="info" v-html="explanation"/>
  </el-form-item>
</template>

<script>

export default {
  name: 'BaseRadio',
  props: {
    value: {
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    prop: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    explanation: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  }
}
</script>
