import middleware from '@/app/services/MiddlewareService/middleware'

export default [
  {
    path: 'manage/user-groups',
    name: 'UserGroupsViewAll',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "manage-user-groups" */ '@/views/UserGroupsViewAll.vue')
  },
  {
    path: 'manage/user-groups/add',
    name: 'UserGroupAdd',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "view-user-group" */ '@/views/UserGroupAdd.vue')
  },
  {
    path: 'manage/user-groups/:userGroupOspId',
    name: 'UserGroupsViewLayout',
    beforeEnter: middleware('needSelectedCorporateAccount'),
    component: () => import(/* webpackChunkName: "view-user-group" */ '@/layouts/UserGroupViewLayout.vue'),
    redirect: { name: 'UserGroupMembers' },
    props: true,
    children: [
      {
        path: 'members',
        name: 'UserGroupMembers',
        component: () => import(/* webpackChunkName: "view-user-group" */ '@/views/UserGroupMembers.vue'),
        props: true,
        meta: {
          showSaveMembersButton: true
        }
      },
      {
        path: 'products',
        name: 'UserGroupProducts',
        component: () => import(/* webpackChunkName: "view-user-group" */ '@/views/UserGroupProducts.vue'),
        props: true
      },
      {
        path: 'details',
        name: 'UserGroupDetails',
        component: () => import(/* webpackChunkName: "view-user-group" */ '@/views/UserGroupDetails.vue'),
        props: true,
        meta: {
          showSaveDetailsButton: true
        }
      }
    ]
  }
]
