import UserGroupRepository from '../repositories/UserGroupRepository'
import UserRepository from '../repositories/UserRepository'
import { get, replace, cloneDeep } from 'lodash'
import { defaultCompanyUserGroup } from '@/app/helpers'
import Role from '@/app/services/RolesService/Role'

class UserGroup {
  #original = {}
  #repository = {}
  #userRepository = {}
  ospId = ''
  data = {
    active: true,
    description: '',
    extId: '',
    corporateAccount: {
      ospId: ''
    }
  }

  constructor (object = {}) {
    this.#original = object
    Object.assign(this, { ...object })
    this.#repository = new UserGroupRepository()
    this.#userRepository = new UserRepository()
  }

  get description () {
    return get(this.data, 'description', '') || ''
  }

  set description (arg) {
    this.data.description = arg
  }

  get name () {
    return replace(get(this.data, 'extId', ''), /_/g, ' ') || ''
  }

  get extId () {
    return get(this.data, 'extId', '') || ''
  }

  set name (arg) {
    this.data.extId = arg
  }

  get members () {
    return get(this.data, 'userCount', null)
  }

  get repository () {
    return this.#repository
  }

  get updateForm () {
    return {
      ospId: this.ospId,
      data: {
        ...this.data,
        extId: replace(this.data.extId, ' ', '_')
      }
    }
  }

  get createForm () {
    return {
      ...this.data,
      extId: replace(this.data.extId, /\s/g, '_')
    }
  }

  get isActive () {
    return !!this.data.active
  }

  clone () {
    return new UserGroup(cloneDeep(this.#original))
  }

  isDefault (companyExtId) {
    return this.ospId.indexOf(defaultCompanyUserGroup(companyExtId)) > -1
  }

  save (options = {}) {
    if (this.ospId) {
      return this.#repository.update(this.updateForm, options)
    } else {
      return this.#repository.create(this.createForm, options)
    }
  }

  fetchMembers (filter, options) {
    const data = {
      ...filter,
      corporateAccountOspId: this.data.corporateAccount.ospId,
      userGroupOspId: this.ospId,
      roles: [Role.OSPUSER]
    }
    return this.#userRepository.findByAny(data, options)
  }

  fetchNotMembers (filter, options) {
    const data = {
      ...filter,
      corporateAccountOspId: this.data.corporateAccount.ospId,
      excludeMembersOf: [
        this.ospId
      ],
      roles: [Role.OSPUSER]
    }
    return this.#userRepository.findByAny(data, options)
  }

  addUsers (users, options) {
    const form = {
      usergroup: {
        ospId: this.ospId
      },
      users
    }
    this.#repository.addUsers(form, options)
  }

  removeUsers (users, options) {
    const form = {
      usergroup: {
        ospId: this.ospId
      },
      users
    }
    this.#repository.removeUsers(form, options)
  }
}

export default UserGroup
